import {
  CONTRACTS_HOME,
  DOCUMENTS_HOME,
} from "@/components/common/content-components/utils/consts"

export default function getArticleParents(article) {
  let super_parent_category
  let parent_category
  let category
  let subcategory

  if (
    article?.data?.parent_article?.document?.data?.parent_article?.document
      ?.data?.parent_article?.document?.data?.parent_article?.type ===
    CONTRACTS_HOME
  ) {
    super_parent_category = CONTRACTS_HOME
  } else if (
    article?.data?.parent_article?.document?.data?.parent_article?.document
      ?.data?.parent_article?.document?.data?.parent_article?.type ===
    DOCUMENTS_HOME
  ) {
    super_parent_category = DOCUMENTS_HOME
  } else {
    super_parent_category =
      article?.data?.parent_article?.document?.data?.parent_article?.document
        ?.data?.parent_article?.document?.data?.parent_article?.document
  }

  if (
    article?.data?.parent_article?.document?.data?.parent_article?.document
      ?.data?.parent_article?.type === CONTRACTS_HOME
  ) {
    parent_category = CONTRACTS_HOME
  } else if (
    article?.data?.parent_article?.document?.data?.parent_article?.document
      ?.data?.parent_article?.type === DOCUMENTS_HOME
  ) {
    parent_category = DOCUMENTS_HOME
  } else {
    parent_category =
      article?.data?.parent_article?.document?.data?.parent_article?.document
        ?.data?.parent_article?.document
  }

  if (
    article?.data?.parent_article?.document?.data?.parent_article?.type ===
    CONTRACTS_HOME
  ) {
    category = CONTRACTS_HOME
  } else if (
    article?.data?.parent_article?.document?.data?.parent_article?.type ===
    DOCUMENTS_HOME
  ) {
    category = DOCUMENTS_HOME
  } else {
    category =
      article?.data?.parent_article?.document?.data?.parent_article?.document
  }

  if (article?.data?.parent_article?.type === CONTRACTS_HOME) {
    subcategory = CONTRACTS_HOME
  } else if (article?.data?.parent_article?.type === DOCUMENTS_HOME) {
    subcategory = DOCUMENTS_HOME
  } else {
    subcategory = article?.data?.parent_article?.document
  }

  return {
    super_parent_category,
    parent_category,
    category,
    subcategory,
  }
}
