import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "semantic-ui-react"

import NavMenu from "@/components/layout/nav/NavMenu"
import NavSubMenu from "@/components/layout/nav/NavSubMenu"
import NavBlur from "@/components/layout/nav/NavBlur"
import MobileNavMenu from "@/components/layout/nav/MobileNavMenu"

import "@/assets/styles/nav.scss"

const Nav = ({
  hideMenu,
  isAuthenticated,
  isHome,
  isLocal,
  cityAssociated,
}) => {
  const categories = useStaticQuery(graphql`
    query NavGuideCategories {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
      allPrismicGuideArticle(limit: 4, sort: { first_publication_date: DESC }) {
        edges {
          node {
            id
            uid
            type
            first_publication_date(locale: "")
            data {
              title {
                html
                richText
                text
              }
              parent_article {
                id
                uid
                document {
                  ... on PrismicGuideArticle {
                    id
                    uid
                    data {
                      parent_article {
                        id
                        uid
                        document {
                          ... on PrismicGuideArticle {
                            id
                            uid
                            data {
                              parent_article {
                                id
                                uid
                                document {
                                  ... on PrismicGuideArticle {
                                    id
                                    uid
                                    data {
                                      parent_article {
                                        id
                                        uid
                                        document {
                                          ... on PrismicGuideArticle {
                                            id
                                            uid
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              main_image {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  height: 200
                )
                og: gatsbyImageData(layout: FIXED, width: 400)
                alt
              }
              meta_title
              meta_description
            }
          }
        }
      }
    }
  `)

  let data = categories.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })
  const lastFourArticles = categories.allPrismicGuideArticle.edges

  const [activeItem, setActiveItem] = useState(null)

  return (
    <Grid
      className={`nav ${activeItem ? "active" : "disabled"}`}
      centered
      as="header"
    >
      <Grid.Row centered className="mobile-nav-main-row">
        <MobileNavMenu
          data={data}
          hideMenu={hideMenu}
          isAuthenticated={isAuthenticated}
          isLocal={isLocal}
          cityAssociated={cityAssociated}
        />
      </Grid.Row>

      <Grid.Row
        className={`nav-main-row ${activeItem ? "active" : "disabled"}`}
      >
        <Grid.Column className="nav-main-column">
          <NavMenu
            data={data}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            lastFourArticles={lastFourArticles}
            isAuthenticated={isAuthenticated}
            hideMenu={hideMenu}
            isLocal={isLocal}
            cityAssociated={cityAssociated}
          />
        </Grid.Column>
      </Grid.Row>

      {activeItem && (
        <Grid.Row
          className={`nav-sub-row ${activeItem ? "active" : "disabled"}`}
        >
          <Grid.Column className="nav-sub-column">
            <NavSubMenu
              data={activeItem}
              isAuthenticated={isAuthenticated}
              isHome={isHome}
            />
            <NavBlur setActiveItem={setActiveItem} />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  )
}

export default Nav
