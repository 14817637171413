import React from "react"
import { Breadcrumb } from "semantic-ui-react"

import OrangeDot from "@/components/common/utils/OrangeDot"

const Divider = () => {
  return (
    <Breadcrumb.Divider style={{ display: "inline-block" }}>
      <OrangeDot style={{ margin: "0 5px" }} />
    </Breadcrumb.Divider>
  )
}

const BasicBreadcrumbs = ({ content }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section>
        <a href={`/`}>BailFacile</a>
      </Breadcrumb.Section>
      <Divider />
      <Breadcrumb.Section active>
        <p>{content}</p>
      </Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default BasicBreadcrumbs
