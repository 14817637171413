import { CONTRACTS_HOME } from "@/components/common/content-components/utils/consts"
import { WEB_APP_URL } from "@/lib/constants"

const getDocumentsCTALink = (landingpage, isAuthenticated) => {
  let link
  let linksList = []

  if (landingpage) {
    if (landingpage.type === CONTRACTS_HOME) {
      link = {
        href: `${WEB_APP_URL}/contrats/bail-location/redaction`,
        hidden: true,
      }
    } else {
      const { uid, data } = landingpage
      const { type } = data

      if (type === "contract") {
        link = {
          href: `${WEB_APP_URL}/contrats/${uid}/redaction`,
          hidden: true,
        }
      } else if (type === "document") {
        const { sub_products, action_category } = data
        const hasSubProducts = sub_products?.length > 0

        if (isAuthenticated) {
          if (hasSubProducts) {
            sub_products.forEach((subproduct) => {
              const { subproduct_action_category, subproduct_slug } = subproduct
              linksList.push({
                href: `${WEB_APP_URL}/gestion/actions?category=${subproduct_action_category}&action=${subproduct_slug}`,
                hidden: true,
                uid: subproduct_slug,
              })
            })
          } else {
            if (action_category === "avenant") {
              link = {
                href: `${WEB_APP_URL}/gestion/actions?category=${action_category}`,
                hidden: true,
              }
            } else if (uid === "convention-occupation-precaire") {
              link = {
                href: `${WEB_APP_URL}/gestion/actions?category=${action_category}&action=convention-occupation-temporaire`,
                hidden: true,
              }
            } else if (uid === "inventaire-meubles") {
              link = {
                href: `${WEB_APP_URL}/gestion/actions?category=${action_category}&action=inventaire-meubles-entree`,
                hidden: true,
              }
            } else if (uid === "etat-lieux") {
              link = {
                href: `${WEB_APP_URL}/gestion/actions?category=${action_category}&action=etat-lieux-entree`,
                hidden: true,
              }
            } else {
              link = {
                href: `${WEB_APP_URL}/gestion/actions?category=${action_category}&action=${uid}`,
                hidden: true,
              }
            }
          }
        } else {
          if (hasSubProducts) {
            sub_products.forEach((subproduct) => {
              const { subproduct_slug } = subproduct
              linksList.push({
                href: `${WEB_APP_URL}/documents/${subproduct_slug}/redaction`,
                hidden: true,
                uid: subproduct_slug,
              })
            })
          } else {
            link = {
              href: `${WEB_APP_URL}/documents/${uid}/redaction`,
              hidden: true,
            }
          }
        }
      }
    }
  }

  return { link, linksList }
}

export default getDocumentsCTALink
