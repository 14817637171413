import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Sticky } from "semantic-ui-react"

import Button from "@/components/common/main-components/Button"
import SmartLink from "@/components/common/utils/SmartLink"

import { WEB_APP_URL } from "@/lib/constants"
import { sendDataLayerEvent } from "@/lib/trackers/events"

import CustomImageData from "@/utils/CustomImageData"

const StickyOnRight = ({
  stickyCTARef,
  mobile = false,
  isLocalGuide = false,
  city = null,
}) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
                webpOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <Sticky context={stickyCTARef} offset={100} active={!mobile}>
      <div
        className={mobile ? "article-sticky mobile" : "article-sticky desktop"}
      >
        <div
          className="article-sticky__content"
          onClick={() =>
            sendDataLayerEvent("click", "cta-landingpages", "cta-button")
          }
        >
          <div className="article-sticky__content--image">
            <CustomImageData
              data={data}
              path={"guides/bailfacile-gestion-locative-ligne-offre-essai.webp"}
              alt="BailFacile - Logiciel de gestion locative"
            />
          </div>
          <p className="article-sticky__content--text">
            Découvrez l’application de gestion locative N°1
          </p>
          {isLocalGuide ? (
            <SmartLink link={{ to: `/gestion-locative/${city}` }}>
              <Button
                content="Essayer BailFacile"
                icon="arrow right"
                onClick={() =>
                  sendDataLayerEvent(
                    "click",
                    "cta-pagesmarketing",
                    "essayer-gratuitement"
                  )
                }
              />
            </SmartLink>
          ) : (
            <Button
              content="Essayer BailFacile"
              icon="arrow right"
              onClick={() =>
                sendDataLayerEvent(
                  "click",
                  "cta-pagesmarketing",
                  "essayer-gratuitement"
                )
              }
              link={`${WEB_APP_URL}/inscription`}
            />
          )}
          <p className="article-sticky__content--subtext">
            Inscription en 2 minutes
          </p>
        </div>
      </div>
    </Sticky>
  )
}

export default StickyOnRight
