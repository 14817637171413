export default function addInsecableSpace(str) {
  if (str) {
    let fixedStr
    fixedStr = str.replace(" ?", `\xa0?`)
    fixedStr = fixedStr.replace(" :", `\xa0:`)
    fixedStr = fixedStr.replace(" ;", `\xa0;`)
    fixedStr = fixedStr.replace(" !", `\xa0!`)
    return fixedStr
  }
  return
}
