import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from "semantic-ui-react"

import Stars from "./Stars"
import Content from "./Content"

const TrustpilotInline = ({
  white,
  centered = false,
  noClick,
  homeHero = false,
  navSubmenu = false,
  footer = false,
  nativeCTA = false,
}) => {
  const trustpilot = useStaticQuery(graphql`
    query TruspilotSmall {
      trustpilotGeneral {
        data {
          trustScore
          stars
          string
          numberOfReviews
          url
          image
          logo
        }
      }
    }
  `)

  return (
    <Grid centered={centered}>
      <Grid.Column width={16}>
        <div
          className={`trustpilotInline ${navSubmenu ? "tp-nav-submenu" : ""} ${
            footer ? "tp-footer" : ""
          } ${nativeCTA ? "tp-nativeCTA" : ""}`}
        >
          <Stars
            data={trustpilot.trustpilotGeneral.data}
            noClick={noClick}
            homeHero={homeHero}
          />
          <Content
            data={trustpilot.trustpilotGeneral.data}
            homeHero={homeHero}
            white={white}
          />
        </div>
      </Grid.Column>
    </Grid>
  )
}

export default TrustpilotInline
