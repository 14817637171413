import React, { useState, useEffect } from "react"

import getScrollHeight from "@/utils/getScrollHeight"

const ReadingProgressBar = () => {
  const [width, setWidth] = useState(0)

  const scrollHeight = () => {
    const percent = getScrollHeight()
    setWidth(percent)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHeight)
    return () => window.removeEventListener("scroll", scrollHeight)
  })

  return (
    <>
      <style global="true" jsx="true">
        {`
          .progressBar {
            position: fixed;
            width: 100vw;
            top: 0;
            left: 0;
            height: 8px;
            border-radius: 0px 2px 0px 0px;
            background: #f8f9fb;
            padding: 0 !important;
            margin: 0 !important;
            z-index: 99999;
          }

          .progressBar__completion {
            background: #ffad4a;
            height: 8px;
            border-radius: 0px 2px 0px 0px;
          }
        `}
      </style>
      <div className="progressBar">
        <div
          className="progressBar__completion"
          style={{ width: width + "%" }}
        ></div>
      </div>
    </>
  )
}

export default ReadingProgressBar
