import React from "react"
import { Grid, Visibility } from "semantic-ui-react"
import { PrismicRichText } from "@prismicio/react"

import HeadingThree from "@/components/guides/utils/HeadingThree"

import htmlSerializer from "@/utils/htmlSerializer"

const FAQSections = ({ faqSections, articles, handleUpdate }) => {
  return (
    <Visibility
      id="faq"
      onUpdate={(e, data) => handleUpdate(data, null, "faq")}
      offset={77}
      className={`article-slice-visibility`}
    >
      <Grid.Row className="FAQ-Section">
        <Grid.Column>
          <h2>FAQ</h2>
          {faqSections.map((section) => {
            return (
              <React.Fragment key={section.question.text}>
                <HeadingThree content={section.question.text} />
                <PrismicRichText
                  field={section.answer.richText}
                  components={(type, element, content, children, index) =>
                    htmlSerializer(
                      type,
                      element,
                      content,
                      children,
                      index,
                      articles
                    )
                  }
                />
              </React.Fragment>
            )
          })}
        </Grid.Column>
      </Grid.Row>
    </Visibility>
  )
}

export default FAQSections
