import React, { useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import ReCAPTCHA from "react-google-recaptcha"

import Input from "@/components/common/content-components/newsletter/Input"
import NewsletterButton from "@/components/common/content-components/newsletter/NewsletterButton"
import NewsletterMessage from "@/components/common/content-components/newsletter/NewsletterMessage"

import CustomImageData from "@/utils/CustomImageData"

import { RECAPTCHA_SITE_KEY_V2 } from "@/lib/constants"

const NewsletterSmall = ({
  asyncScriptOnLoad,
  handleChange,
  handleKeyDown,
  handleSubmitNewsletter,
  captchaLoader,
  validatorError,
  loading,
  isSubscribed,
}) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
                webpOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const captchaRef = useRef(null)

  return (
    <div className="article-newsletter-small">
      <div className="article-newsletter-small__content">
        <div className="article-newsletter-small__header">
          <div className="article-newsletter-small__header--image">
            <CustomImageData
              data={data}
              path={"marketing/love-letter.png"}
              alt="S'abonner à la newsletter"
            />
          </div>
          <p className="article-newsletter-small__header--title">
            Je m'abonne à l'
            <span className="italic">Actualité du bailleur</span>
          </p>
        </div>
        {isSubscribed === false && (
          <form
            className="article-newsletter-small__sub"
            onSubmit={(e) => handleSubmitNewsletter(e, captchaRef)}
          >
            <div className="article-newsletter-small__sub--input">
              <Input
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                captchaRef={captchaRef}
              />
            </div>
            <div className="article-newsletter-small__sub--button">
              <NewsletterButton
                loading={loading}
                captchaLoader={captchaLoader}
              />
            </div>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={RECAPTCHA_SITE_KEY_V2}
              size="invisible"
              asyncScriptOnLoad={() => asyncScriptOnLoad()}
            />
          </form>
        )}
      </div>
      {validatorError !== null && (
        <NewsletterMessage
          validatorError={validatorError}
          isSubscribed={isSubscribed}
        />
      )}
    </div>
  )
}

export default NewsletterSmall
