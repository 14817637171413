import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
} from "react-share"

import CustomImageData from "@/utils/CustomImageData"

import { WEBSITE_URL } from "@/lib/constants"

const copyLink = "Copier le lien"
const linkCopied = "Lien copié !"

const SharingIcons = ({ link, emailTitle, isComplete = false }) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const [tooltipText, setTooltipText] = useState(copyLink)

  const handleCopyLink = () => {
    let copyText =
      typeof window !== undefined && `${WEBSITE_URL}${window.location.pathname}`

    // Doesn't work on http, only https
    navigator.clipboard.writeText(copyText)

    setTooltipText(linkCopied)
    setTimeout(() => {
      setTooltipText(copyLink)
    }, 2000)
  }

  return (
    <div
      className={
        isComplete
          ? "article-sharing__icons complete"
          : "article-sharing__icons incomplete"
      }
    >
      <LinkedinShareButton
        url={WEBSITE_URL + link}
        title={emailTitle}
        summary={emailTitle}
        source="BailFacile - Logiciel de gestion locative"
        className="tooltip networks"
      >
        <span className="tooltipText" id="tooltipCopyLink">
          Partager sur LinkedIn
        </span>
        <CustomImageData
          data={data}
          path="socials/linkedin.svg"
          alt="Linkedin"
          imgStyle={{ width: "24px", height: "24px" }}
        />
      </LinkedinShareButton>
      <FacebookShareButton
        url={WEBSITE_URL + link}
        className="tooltip networks"
      >
        <span className="tooltipText" id="tooltipCopyLink">
          Partager sur Facebook
        </span>
        <CustomImageData
          data={data}
          path="socials/facebook.svg"
          alt="Facebook"
          imgStyle={{ width: "24px", height: "24px" }}
        />
      </FacebookShareButton>
      <TwitterShareButton url={WEBSITE_URL + link} className="tooltip networks">
        <span className="tooltipText" id="tooltipCopyLink">
          Partager sur Twitter
        </span>
        <CustomImageData
          data={data}
          path="socials/twitter.svg"
          alt="Twitter"
          imgStyle={{ width: "24px", height: "24px" }}
        />
      </TwitterShareButton>
      {isComplete && (
        <RedditShareButton
          url={WEBSITE_URL + link}
          className="tooltip networks"
        >
          <span className="tooltipText" id="tooltipCopyLink">
            Partager sur Reddit
          </span>
          <CustomImageData
            data={data}
            path="socials/reddit.svg"
            alt="Reddit"
            imgStyle={{ width: "24px", height: "24px" }}
          />
        </RedditShareButton>
      )}
      <WhatsappShareButton
        url={WEBSITE_URL + link}
        className="tooltip networks"
      >
        <span className="tooltipText" id="tooltipCopyLink">
          Partager sur WhatsApp
        </span>
        <CustomImageData
          data={data}
          path="socials/whatsapp.svg"
          alt="Whatsapp"
          imgStyle={{ width: "24px", height: "24px" }}
        />
      </WhatsappShareButton>
      {isComplete && (
        <TelegramShareButton
          url={WEBSITE_URL + link}
          className="tooltip networks"
        >
          <span className="tooltipText" id="tooltipCopyLink">
            Partager sur Telegram
          </span>
          <CustomImageData
            data={data}
            path="socials/telegram.svg"
            alt="Telegram"
            imgStyle={{ width: "24px", height: "24px" }}
          />
        </TelegramShareButton>
      )}
      <EmailShareButton
        url={WEBSITE_URL + link}
        subject={`Cet article peut t'intéresser : "${emailTitle}"`}
        body={`J'ai lu ce contenu, je pense que tu peux y trouver un intérêt. Voici le lien :`}
        className="tooltip networks"
      >
        <span className="tooltipText" id="tooltipCopyLink">
          Partager par e-mail
        </span>
        <CustomImageData
          data={data}
          path="socials/email.svg"
          alt="Email"
          imgStyle={{ width: "24px", height: "24px" }}
        />
      </EmailShareButton>
      <button
        className="copyLink tooltip"
        onClick={handleCopyLink}
        name={WEBSITE_URL + link}
        role="button"
        aria-label="Copier le lien de l'article"
      >
        <span className="tooltipText" id="tooltipCopyLink">
          {tooltipText}
        </span>
        <CustomImageData
          data={data}
          path="socials/copy-link.svg"
          alt="Copier le lien"
          imgStyle={{ width: "24px", height: "24px" }}
          name={WEBSITE_URL + link}
        />
      </button>
    </div>
  )
}

export default SharingIcons
