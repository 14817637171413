import React from "react"

import TrustpilotInline from "@/components/trustpilot/TrustpilotInline"

const FooterTrustPilot = () => {
  return (
    <div className="footer__trustpilot">
      <TrustpilotInline footer />
    </div>
  )
}

export default FooterTrustPilot
