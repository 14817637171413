import smoothScrollIntoView from "@/utils/smoothScrollIntoView"

export default function scrollToElementByHash() {
  if (window && window.location && window.location.hash) {
    setTimeout(() => {
      if (document.getElementById(window.location.hash.substring(1))) {
        smoothScrollIntoView(window.location.hash.substring(1))
      }
    }, 1000)
  }
}
