import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { Grid } from "semantic-ui-react"

import SiteWrapper from "@/components/layout/SiteWrapper"
import { HomeSlices } from "@/components/guides/home"
import Meta from "@/components/layout/Meta"
import BasicBreadcrumbs from "@/components/common/content-components/breadcrumbs/BasicBreadcrumbs"

import useLocationHash from "@/utils/useLocationHash"

import usePageViewTracker from "@/lib/hooks/usePageViewTracker"

import "@/assets/styles/documents_homepage.scss"

export const query = graphql`
  {
    allFile {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              jpgOptions: { quality: 100 }
              pngOptions: { quality: 100 }
            )
          }
          relativePath
          extension
          publicURL
        }
      }
    }
    prismicAccueilDocuments {
      _previewable
      data {
        og_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
          alt
        }
        body {
          ... on PrismicAccueilDocumentsDataBodyEncadre {
            items {
              internal_link {
                link_type
                isBroken
                document {
                  ... on PrismicLandingPage {
                    url
                    uid
                    type
                  }
                  ... on PrismicGuideArticle {
                    url
                    uid
                    type
                  }
                }
              }
              link_title {
                text
              }
            }
            slice_type
            primary {
              section_title {
                text
              }
              anchor
            }
          }
          ... on PrismicAccueilDocumentsDataBodyTitreH2 {
            primary {
              block_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicAccueilDocumentsDataBodyCtaNatif {
            id
            slice_type
            primary {
              native_cta_block {
                isBroken
                document {
                  ... on PrismicNativeCta {
                    id
                    data {
                      cta_bullet_1 {
                        richText
                        text
                      }
                      cta_bullet_2 {
                        richText
                        text
                      }
                      cta_bullet_3 {
                        richText
                        text
                      }
                      cta_text {
                        text
                        richText
                      }
                      cta_title {
                        text
                        richText
                      }
                      cta_visual {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        og: gatsbyImageData(layout: FIXED, width: 1200)
                        alt
                      }
                      cta_internal_link {
                        url
                        uid
                        type
                        document {
                          ... on PrismicGuideArticle {
                            url
                            uid
                            type
                          }
                          ... on PrismicLandingPage {
                            url
                            uid
                            type
                          }
                        }
                      }
                      has_seo_value
                    }
                  }
                }
              }
            }
          }
        }
        meta_description
        meta_title
        canonical_url {
          url
        }
      }
    }
  }
`

const DocumentsHomePageTemplate = (props) => {
  const slices = props.data.prismicAccueilDocuments.data.body

  const { section } = useLocationHash()

  usePageViewTracker("landingpage", "home_documents")

  return (
    <SiteWrapper noPadding={true} archivePage>
      <Grid className="homepage">
        <Grid.Row style={{ padding: "0" }}>
          <BasicBreadcrumbs
            content="Documents à fournir par le bailleur au locataire"
            leftAligned
          />
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column style={{ padding: 0 }}>
            <Grid style={{ justifyContent: "center" }}>
              <Grid.Row>
                <Grid.Column className="header-main-column">
                  <h1
                    style={{ margin: "0px " }}
                    className={`ui header custom-documents-main-title`}
                  >
                    <span className="mainheader">
                      50<sup className="plus">+</sup> documents locatifs{" "}
                      <span className="free-with-bg">gratuits</span>{" "}
                    </span>
                    <span className="subheader">
                      personnalisables et conçus par des avocats spécialistes du
                      droit immobilier
                    </span>
                  </h1>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <div className="slices">
            {slices && <HomeSlices slices={slices} currentSlug={section} />}
          </div>
        </Grid.Row>
      </Grid>
    </SiteWrapper>
  )
}

export default withPrismicPreview(DocumentsHomePageTemplate)

export const Head = ({ data }) => {
  const documentsHomePage = data.prismicAccueilDocuments

  return (
    <Meta
      title={documentsHomePage.data.meta_title}
      description={documentsHomePage.data.meta_description}
      canonicalUrl={documentsHomePage?.data?.canonical_url?.url}
      structuredData={[
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Accueil",
              item: "https://www.bailfacile.fr",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Document pour location",
              item: "https://www.bailfacile.fr/documents",
            },
          ],
        },
      ]}
      currentLocation="/"
      contentType="documents"
      ogImage={documentsHomePage?.data?.og_image?.og?.images?.fallback?.src}
    />
  )
}
