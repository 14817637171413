import React from "react"
import { PrismicRichText } from "@prismicio/react"
import { Grid } from "semantic-ui-react"

import htmlSerializer from "@/utils/htmlSerializer"

import TripleSectionTitle from "./TripleSectionTitle"

import "@/assets/styles/landingpage.scss"

const DesktopTripleSection = ({ tripleSectionData }) => {
  return (
    <Grid className="landingpage-triple-section" columns={3}>
      <Grid.Row className="triple-section-row">
        {tripleSectionData.map((block, i) => {
          return (
            <Grid.Column key={`${i}-${block.title}`} className="block-column">
              <TripleSectionTitle block={block} index={`section${i + 1}`} />
              <div className="block-text">
                <PrismicRichText
                  field={block.text}
                  components={(type, element, content, children, index) =>
                    htmlSerializer(
                      type,
                      element,
                      content,
                      children,
                      index,
                      null,
                      false,
                      false,
                      true // tripleSection
                    )
                  }
                />
              </div>
            </Grid.Column>
          )
        })}
      </Grid.Row>
    </Grid>
  )
}

export default DesktopTripleSection
