const slugify = require("slugify")

export default function convertTitleToSlug(title, index) {
  const CHAR_LIMIT = 40
  index = parseInt(index) + 1

  if (title !== undefined && "normalize" in String.prototype) {
    return (
      index +
      "-" +
      slugify(title, {
        replacement: "-",
        lower: true,
      }).substring(0, CHAR_LIMIT)
    )
  }
  return null
}
