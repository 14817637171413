import React from "react"
import { Grid } from "semantic-ui-react"

import SmartLink from "@/components/common/utils/SmartLink"
import HeroHeader from "@/components/landingpages/hero/HeroHeader"
import HeroMainImage from "@/components/landingpages/hero/HeroMainImage"
import HeroPromise from "@/components/landingpages/hero/HeroPromise"
import TrustpilotInline from "@/components/trustpilot/TrustpilotInline"
import Button from "@/components/common/main-components/Button"

import { SubProductsDropdown } from "./SubProductsDropdown"

import { sendDataLayerEvent } from "@/lib/trackers/events"

const DesktopHero = ({ heroData, isAuthenticated }) => {
  const CTAcontent = isAuthenticated
    ? heroData.hero_cta_content
    : `${heroData.hero_cta_content} | Gratuit`

  const hasSubProducts = heroData?.sub_products?.length > 0
  const { sub_products } = hasSubProducts ? heroData : {}
  const CTAWithSubsContent = isAuthenticated
    ? "Choisir un modèle"
    : "Choisir un modèle | Gratuit"

  return (
    <Grid className="landingpage-hero">
      <Grid.Row>
        <Grid.Column className="column-without-padding">
          <div className="parts">
            <div className="left-part">
              {hasSubProducts ? (
                <>
                  <HeroHeader content={heroData?.title} />
                  <HeroPromise content={heroData?.marketing_promise} />
                  <SubProductsDropdown
                    buttonContent={CTAWithSubsContent}
                    subProducts={sub_products}
                    linksList={heroData?.CTAlink?.linksList}
                  />
                </>
              ) : (
                <SmartLink link={heroData.CTAlink.link}>
                  <HeroHeader content={heroData?.title} />
                  <HeroPromise content={heroData?.marketing_promise} />
                  <Button
                    content={CTAcontent}
                    icon="arrow right"
                    onClick={() => {
                      sendDataLayerEvent(
                        "click",
                        "cta-landingpages",
                        "cta-button"
                      )
                    }}
                  />
                </SmartLink>
              )}
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    style={{
                      marginTop: "0.5em",
                      width: "75%",
                      textAlign: "center",
                      zIndex: "9999",
                    }}
                  >
                    <TrustpilotInline />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            {hasSubProducts ? (
              <div className="right-part">
                <HeroMainImage
                  image={heroData?.main_image?.gatsbyImageData}
                  alt={heroData?.main_image?.alt}
                  mobile
                />
              </div>
            ) : (
              <SmartLink link={heroData.CTAlink.link}>
                <div className="right-part">
                  <HeroMainImage
                    image={heroData?.main_image?.gatsbyImageData}
                    alt={heroData?.main_image?.alt}
                    mobile
                  />
                </div>
              </SmartLink>
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DesktopHero
