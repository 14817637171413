import React from "react"

import CustomImage from "@/utils/customImage"

// Default Image
const DefaultImage = ({ slice }) => {
  return (
    <div className="article-image">
      <CustomImage
        image={slice.primary.image.gatsbyImageData}
        alt={slice.primary.image.alt}
      />
    </div>
  )
}

let Image = ({ slice }) => {
  return <DefaultImage slice={slice} />
}

export default Image
