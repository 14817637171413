import React from "react"
import { PrismicRichText } from "@prismicio/react"

import htmlSerializer from "@/utils/htmlSerializer"

import { sendDataLayerEvent } from "@/lib/trackers/events"

const HeroPromise = ({ content, mobile = false }) => {
  return (
    <div
      className="marketing-promise"
      onClick={() => {
        if (!mobile) {
          sendDataLayerEvent("click", "cta-landingpages", "cta-button")
        } else {
          return null
        }
      }}
    >
      <PrismicRichText
        field={content}
        components={(type, element, content, children, index) =>
          htmlSerializer(type, element, content, children, index)
        }
      />
    </div>
  )
}

export default HeroPromise
