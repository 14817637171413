/*eslint import/no-unresolved: [2, { ignore: ['\.css$'] }]*/
import React from "react"
import { navigate } from "gatsby"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"

import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "./.semantic/dist/semantic.min.css"

import ContextProvider from "./src/lib/ContextProvider"
import { MediaContextProvider } from "./src/lib/Media"

import { repositoryConfigs } from "./src/utils/prismicPreviews"

export const onClientEntry = () => {
  const newCommitSHA = process.env.GATSBY_VERCEL_GIT_COMMIT_SHA || "N/A"
  const storedCommitSHA = localStorage.getItem("lastCommitSHA")

  // If there is no stored commit SHA, this is the first time the user is visiting the site
  // so we store the current commit SHA
  if (!storedCommitSHA) {
    localStorage.setItem("lastCommitSHA", newCommitSHA)
    return
  }

  // Check if the stored commit SHA is different from the current one
  if (storedCommitSHA !== newCommitSHA) {
    // If different, update the stored value and trigger a hard reload
    localStorage.setItem("lastCommitSHA", newCommitSHA)
    window.location.reload(true) // Force a hard reload
  }
}

export const onRouteUpdate = () => {
  if (window && window.Beacon && typeof window.Beacon === "function") {
    window.Beacon("event", {
      type: "page-viewed",
      url: window.location.href,
      title: window.document.title,
    })
  }
  return null
}

export const onPreRouteUpdate = ({ location }) => {
  const nextRoute = location.pathname
  if (
    process.env.MAINTENANCE_MODE === "true" &&
    window.location.pathname !== "/maintenance"
  ) {
    navigate(`/maintenance`)
  }
  window.addEventListener("unhandledrejection", (event) => {
    if (/loading chunk \d* failed./i.test(event.reason)) {
      if (nextRoute) {
        window.location.pathname = nextRoute
      }
    }
  })
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <ContextProvider>
      <MediaContextProvider>{element}</MediaContextProvider>
    </ContextProvider>
  </PrismicPreviewProvider>
)

export const wrapPageElement = ({ element }) => {
  return element
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (!location.hash) {
    window.scrollTo(0, 0)
  }
  return false
}
