import React from "react"
import { Breadcrumb } from "semantic-ui-react"

const ActiveBreadcrumbSection = ({
  article = null,
  type = "article",
  pageTitle = null,
}) => {
  return (
    <Breadcrumb.Section active>
      <p>
        {type === "article" && article && article?.data?.breadcrumb_title
          ? article?.data?.breadcrumb_title
          : article?.data?.title.text}
        {type === "legalpage" && pageTitle}
      </p>
    </Breadcrumb.Section>
  )
}

export default ActiveBreadcrumbSection
