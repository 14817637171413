import React from "react"

import SiblingArticles from "@/components/common/content-components/SiblingArticles"
import {
  CONTRACTS_HOME,
  GUIDE,
  LANDING,
  LOCAL_GUIDE,
  NEWS,
} from "@/components/common/content-components/utils/consts"

const AnnexesBlock = ({
  type,
  customData,
  currentArticle = null,
  isLocal = false,
  siblingLocalGuideArticles,
}) => {
  // join sibling_articles and children_articles
  const siblings = customData?.data?.sibling_articles || []
  const children = customData?.data?.children_articles || []

  let allArticles

  if (Array.isArray(siblings) && Array.isArray(children)) {
    allArticles = [siblings, children].flat().map((sibling) => {
      return sibling?.sibling_article || sibling?.child_article
    })
  } else {
    allArticles = []
  }

  // check if a child article has the same document.id than a sibling article and remove it from allArticles
  const filteredArticles = allArticles.filter((article, index) => {
    const articleIndex = allArticles.findIndex(
      (a) => a?.document?.id === article?.document?.id
    )
    return articleIndex === index
  })

  const dataToDisplay =
    type === GUIDE || type === LANDING || type === CONTRACTS_HOME
      ? filteredArticles
      : type === LOCAL_GUIDE
      ? siblingLocalGuideArticles
      : customData

  const hasCurrentArticle = type === NEWS

  return (
    <section className="article-annexes">
      {isLocal ? (
        <SiblingArticles
          siblings={siblingLocalGuideArticles}
          type={LOCAL_GUIDE}
          currentArticle={currentArticle}
        />
      ) : (
        <SiblingArticles
          siblings={dataToDisplay}
          type={type}
          currentArticle={hasCurrentArticle ? currentArticle : null}
        />
      )}
    </section>
  )
}
export default AnnexesBlock
