export default function getInfosFromType(type) {
  let slug = null
  let name = null
  let dataType = null

  if (type === "guides") {
    slug = "guides"
    name = "Guide du propriétaire"
    dataType = "Article"
  } else if (type === "news") {
    slug = "actualites"
    name = "L'actu de l'immo locative"
    dataType = "NewsArticle"
  } else if (type === "contracts-homepage" || type === "landingpage-contract") {
    slug = "contrats"
    name = "Contrat de location"
    dataType = "Guide"
  } else {
    slug = "documents"
    name = "Document"
    dataType = "Guide"
  }

  return { slug, name, dataType }
}
