import React from "react"

import { cpcItems } from "@/components/layout/footer/utils/arrays"
import { FooterSection } from "@/components/layout/footer/FooterSections"

const FooterCPCSection = () => {
  return (
    <div className="footer__cpc">
      <FooterSection items={cpcItems} title={null} className="cpc" withDot />
    </div>
  )
}

export default FooterCPCSection
