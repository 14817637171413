import React from "react"

import addInsecableSpace from "@/components/common/utils/addInsecableSpace"

import "@/assets/styles/articlepage.scss"

const Source = ({ source }) => {
  return (
    <li className="article-sources__list--item">
      {source.media_name.text} -&nbsp;
      <a
        href={source.media_external_link.text}
        target="_blank"
        rel="noreferrer nofollow"
        className="article-sources__list--link"
      >
        {addInsecableSpace(source.media_content_title.text)}
      </a>
    </li>
  )
}

const Sources = ({ sources }) => {
  return (
    <div className="article-sources">
      <p className="article-sources__title">Sources</p>
      <ul className="article-sources__list">
        {sources.map((source, index) => (
          <Source key={`source-${index}`} source={source} />
        ))}
      </ul>
    </div>
  )
}

export default Sources
