export default function getScrollHeight() {
  const el = document.documentElement

  const slicesBlock = document.querySelector(".article-content")
  const heroBlock = document.querySelector(".landingpage-hero")
  const bannerBlock = document.querySelector(".mainBanner__column")

  if (el && slicesBlock) {
    const slicesBlockHeight = slicesBlock.clientHeight

    let contentHeight = slicesBlockHeight

    if (heroBlock) {
      const heroBlockHeight = heroBlock.clientHeight
      contentHeight = heroBlockHeight + slicesBlockHeight
    } else if (bannerBlock) {
      const bannerBlockHeight = bannerBlock.clientHeight
      contentHeight = bannerBlockHeight + slicesBlockHeight
    }

    const scrollTop = el.scrollTop || document.body.scrollTop

    const percent = (scrollTop / (contentHeight - el.clientHeight)) * 100

    return percent
  }
}
