import React from "react"
import { Divider } from "semantic-ui-react"

import MobileTripleSection from "./MobileTripleSection"
import DesktopTripleSection from "./DesktopTripleSection"

import { Media } from "@/lib/Media"

const TripleSection = ({ tripleSectionData }) => {
  return (
    <>
      <Media lessThan="tablet">
        <MobileTripleSection tripleSectionData={tripleSectionData} />
        <Divider className="triple-section-divider" />
      </Media>
      <Media greaterThanOrEqual="tablet">
        <DesktopTripleSection tripleSectionData={tripleSectionData} />
        <Divider className="triple-section-divider" />
      </Media>
    </>
  )
}

export default TripleSection
