import React from "react"
import { Grid } from "semantic-ui-react"

import { Media } from "@/lib/Media"

import MobileHero from "./MobileHero"
import DesktopHero from "./DesktopHero"

import TrustpilotInline from "@/components/trustpilot/TrustpilotInline"

import "@/assets/styles/subproducts-dropdown.scss"

const Hero = ({ heroData, isAuthenticated }) => {
  return (
    <>
      <Media lessThan="tablet">
        <MobileHero heroData={heroData} isAuthenticated={isAuthenticated} />
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="center" style={{ marginBottom: "1em" }}>
              <TrustpilotInline />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Media>
      <Media greaterThanOrEqual="tablet">
        <DesktopHero heroData={heroData} isAuthenticated={isAuthenticated} />
      </Media>
    </>
  )
}

export default Hero
