import {
  extractBreadcrumbs,
  extractCategoriesBreadcrumbs,
  extractMainData,
  extractVideo,
  extractFaq,
} from "./functions/extracters"
import getInfosFromType from "./functions/getInfosFromType"

import getArticleParents from "@/components/common/content-components/utils/getArticleParents"

export default function getStructuredData(dataReceived, type) {
  const data = dataReceived?.data

  let structuredData = []

  if (!data) {
    return { structuredData }
  }

  const { slug, name, dataType } = getInfosFromType(type)

  // MAIN DATA
  const { mainData } = extractMainData(dataReceived, type, dataType)

  // VIDEO
  const { structuredVideo } = extractVideo(data)

  const { super_parent_category, parent_category, category, subcategory } =
    getArticleParents(dataReceived)

  const articleParents = [
    super_parent_category,
    parent_category,
    category,
    subcategory,
  ]

  //  BREADCRUMBS
  const { breadcrumbs, itemListElement } = extractBreadcrumbs(
    name,
    slug,
    articleParents
  )

  let parents = []
  let count = null

  const { bcCount, list } = extractCategoriesBreadcrumbs(
    dataReceived,
    articleParents
  )

  parents = articleParents
  count = bcCount
  itemListElement.push(...list)

  // FAQ
  const { structuredFaq } = extractFaq(data)

  structuredData = [{ ...mainData }, { ...breadcrumbs }]

  structuredVideo.embedUrl &&
    structuredData.push({
      ...structuredVideo,
    })

  structuredFaq.mainEntity.length > 0 &&
    structuredData.push({
      ...structuredFaq,
    })

  return { structuredData, parents, count }
}
