import React from "react"

const CustomHeader = ({
  size,
  subHeaderStyle,
  headerStyle,
  header,
  HeaderClass,
  subheader,
}) => {
  const CustomTag = `${size}`
  let headerClass = HeaderClass
  if (headerClass === undefined || headerClass === null) {
    headerClass = ""
  }
  return (
    <React.Fragment>
      <CustomTag
        style={{ margin: "0px ", ...headerStyle }}
        className={`ui header ${headerClass}`}
      >
        {header}
      </CustomTag>

      {subheader && subheader !== "" && (
        <div style={subHeaderStyle} className="sub header old-header">
          {subheader}
        </div>
      )}
    </React.Fragment>
  )
}

export default CustomHeader
