import React from "react"

import SmartLink from "@/components/common/utils/SmartLink"

import { sections } from "@/components/layout/footer/utils/arrays"
import ItemsMapping from "@/components/layout/footer/utils/itemsMapping"

export const FooterSection = ({
  section,
  className,
  withDot = false,
  isHome,
  isDouble = false,
  isContracts = false,
}) => {
  return (
    <div className={`${isDouble ? "double" : "section"} ${className}`}>
      {section?.title &&
        className !== "about" &&
        className !== "features" &&
        className !== "ressources" && (
          <SmartLink
            link={{
              to: `/${className}`,
              external: false,
              hidden: isContracts === true ? false : !isHome,
            }}
            className={isHome || isContracts ? "not-hidden" : "hidden"}
          >
            <p className="section-title">{section?.title}</p>
          </SmartLink>
        )}
      {section?.title &&
        (className === "about" ||
          className === "features" ||
          className === "ressources") && (
          <p className="section-title no-animation">{section?.title}</p>
        )}
      {section &&
        ItemsMapping(section?.items, className, withDot, isHome, isContracts)}
    </div>
  )
}

const FooterSections = ({ isHome }) => {
  return (
    <div className="footer__sections">
      {sections.map((section) => {
        const isContracts = section?.title === "Contrats de location"

        if (!section.items) {
          return (
            <div
              key={section["part-one"].title}
              className="section double-section"
            >
              <FooterSection
                key={section["part-one"].className}
                section={section["part-one"]}
                className={section["part-one"].className}
                isHome={isHome}
                isDouble
                isContracts={isContracts}
              />
              <FooterSection
                key={section["part-two"].className}
                section={section["part-two"]}
                className={section["part-two"].className}
                isHome={isHome}
                isDouble
                isContracts={isContracts}
              />
            </div>
          )
        } else {
          return (
            <FooterSection
              key={section.className}
              section={section}
              className={section.className}
              isHome={isHome}
              isContracts={isContracts}
            />
          )
        }
      })}
    </div>
  )
}

export default FooterSections
