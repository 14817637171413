import React, { useState } from "react"
import { createPortal } from "react-dom"
import { Grid, Visibility } from "semantic-ui-react"

import SmartLink from "@/components/common/utils/SmartLink"
import HeroHeader from "@/components/landingpages/hero/HeroHeader"
import HeroMainImage from "@/components/landingpages/hero/HeroMainImage"
import HeroPromise from "@/components/landingpages/hero/HeroPromise"
import Button from "@/components/common/main-components/Button"

import { SubProductsModal, useSubproductsDropdown } from "./SubProductsDropdown"

import { sendDataLayerEvent } from "@/lib/trackers/events"

const MobileHero = ({ heroData, isAuthenticated }) => {
  const [topPassed, setTopPassed] = useState(false)

  const handleViewportUpdate = (e, { calculations }) => {
    if (calculations.percentagePassed > 0.95 || calculations.bottomPassed) {
      setTopPassed(true)
    } else {
      setTopPassed(false)
    }
  }

  const buttonClassName = () => {
    let className = "block-cta-button"

    if (topPassed) {
      className = `${className} bottom-fixed`
    }

    return className
  }

  const CTAcontent = isAuthenticated
    ? heroData.hero_cta_content
    : `${heroData.hero_cta_content} | Gratuit`

  const hasSubProducts = heroData?.sub_products?.length > 0
  const { sub_products } = hasSubProducts ? heroData : {}
  const CTAWithSubsContent = isAuthenticated
    ? "Choisir un modèle"
    : "Choisir un modèle | Gratuit"

  const { showDropdown, setShowDropdown, dropdownRef } =
    useSubproductsDropdown()

  return (
    <Visibility onUpdate={handleViewportUpdate} offset={77}>
      <Grid className="landingpage-hero">
        <Grid.Row>
          <Grid.Column className="column-without-padding">
            <HeroHeader content={heroData?.title} mobile />
            <HeroPromise content={heroData?.mobile_marketing_promise} mobile />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {hasSubProducts ? (
              <HeroMainImage
                image={heroData?.main_image?.gatsbyImageData}
                alt={heroData?.main_image?.alt}
                mobile
              />
            ) : (
              <SmartLink link={heroData.CTAlink.link}>
                <HeroMainImage
                  image={heroData?.main_image?.gatsbyImageData}
                  alt={heroData?.main_image?.alt}
                  mobile
                />
              </SmartLink>
            )}
          </Grid.Column>
        </Grid.Row>
        {hasSubProducts ? (
          <div className={buttonClassName()}>
            <Button
              content={CTAWithSubsContent}
              onClick={() => {
                setShowDropdown(true)
              }}
            />
            {showDropdown &&
              createPortal(
                <SubProductsModal
                  subProducts={sub_products}
                  linksList={heroData?.CTAlink?.linksList}
                  dropdownRef={dropdownRef}
                  closeModal={() => setShowDropdown(false)}
                />,
                document.body
              )}
          </div>
        ) : (
          <div className={buttonClassName()}>
            <SmartLink link={heroData.CTAlink.link}>
              <Button
                content={CTAcontent}
                onClick={() => {
                  sendDataLayerEvent("click", "cta-landingpages", "cta-button")
                }}
              />
            </SmartLink>
          </div>
        )}
      </Grid>
    </Visibility>
  )
}

export default MobileHero
