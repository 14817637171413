import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SmartLink from "@/components/common/utils/SmartLink"

const IntroductionToBF = () => {
  const trustpilot = useStaticQuery(graphql`
    query TruspilotSmall {
      trustpilotGeneral {
        data {
          trustScore
          stars
          string
          numberOfReviews
          url
          image
          logo
        }
      }
    }
  `)

  return (
    <section className="introduction-to-bf-section">
      <div className="introduction-to-bf">
        <h3 className="introduction-to-bf__title">Vous êtes au bon endroit</h3>
        <p className="introduction-to-bf__text">
          Lisez les lignes qui suivent en toute confiance&nbsp;! BailFacile aide
          déjà plus de 100 000 propriétaires-bailleurs à gérer leurs locations
          en totale autonomie. Conformité des documents et démarches,
          automatisations, signatures électroniques, finances, support...
        </p>
        <p className="introduction-to-bf__text">
          BailFacile est noté {trustpilot.trustpilotGeneral.data.trustScore}/5
          selon {trustpilot.trustpilotGeneral.data.numberOfReviews} avis sur
          Trustpilot.{" "}
          <SmartLink
            link={{
              to: "/",
              hidden: true,
              external: false,
            }}
            className="introduction-to-bf__text--link"
          >
            En savoir plus
          </SmartLink>
        </p>
      </div>
    </section>
  )
}

export default IntroductionToBF
