import React, { createContext, useContext, useEffect, useState } from "react"
import Validator from "validatorjs"

import { AuthContext } from "@/lib/context/Auth"
import { axios } from "@/lib/Http"
import { API_URL } from "@/lib/constants"
import { sendDataLayerEvent } from "@/lib/trackers/events"

const NewsletterContext = createContext()

// eslint-disable-next-line react-hooks/rules-of-hooks
Validator.useLang("fr")

const rules = {
  email: "required|email",
}

const postNewsletter = (email, token) => {
  return new Promise((resolve) => {
    axios
      .post(API_URL + "/newsletter", {
        email,
        ["g-recaptcha-response"]: token,
      })
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return resolve(err)
      })
  })
}

const postDownload = (email, isChecked, currentUrl, token) => {
  return new Promise((resolve) => {
    axios
      .post(API_URL + "/guide/article", {
        email,
        newsletter: isChecked,
        slug: currentUrl,
        ["g-recaptcha-response"]: token,
      })
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return resolve(err)
      })
  })
}

export const NewsletterProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext)

  const [credentials, setCredentials] = useState({
    email: "",
  })
  const [validatorError, setValidatorError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [displayNewsletter, setDisplayNewsletter] = useState(true)
  const [captchaLoader, setCaptchaLoader] = useState({
    callback: "not fired",
    recaptchaLoaded: false,
  })
  const [justSentEmail, setJustSentEmail] = useState(false)
  const [slugNotFound, setSlugNotFound] = useState(false)

  useEffect(() => {
    const isSub = localStorage.getItem("subscribed_to_newsletter")
    if (isAuthenticated || (isSub && isSub === "true")) {
      setDisplayNewsletter(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncScriptOnLoad = () => {
    setCaptchaLoader({ callback: "called!", recaptchaLoaded: true })
  }

  const registerValidator = new Validator(credentials, rules)

  registerValidator.setAttributeNames({
    email: "adresse e-mail",
  })

  function handleChange(e, captchaRef) {
    captchaRef.current.reset()

    const name = e.target.name
    let value = e.target.value

    if (value === "") {
      setValidatorError(false)
    }

    if (name === "email") {
      value = value.trim()
    }

    setCredentials({ email: value })
  }

  function subscribeToNewsletter(email, token, captchaRef) {
    postNewsletter(email, token).then((res) => {
      if (res && res.status && res.status === 200) {
        setIsSubscribed(true)
        localStorage.setItem("subscribed_to_newsletter", true)
        sendDataLayerEvent("subscribe", "newsletter", "abonnement-newsletter")
        setLoading(false)
      } else if (res && res.response && res.response.status === 401) {
        const { code } = res.response.data

        if (code === "invalid_email") {
          setValidatorError(true)
          setIsSubscribed(false)
          setLoading(false)
        } else if (code === "recaptcha_invalid") {
          captchaRef.current.reset()
          setIsSubscribed(false)
          setLoading(false)
        }
      }
    })
  }

  function handleSubmitNewsletter(e, captchaRef) {
    e.preventDefault()

    const email = credentials.email

    if (email === "" || registerValidator.passes() === false) {
      setValidatorError(true)
    } else if (email !== "" && registerValidator.passes() === true) {
      setValidatorError(false)
      setLoading(true)

      if (captchaRef.current !== null) {
        captchaRef.current.executeAsync().then((token) => {
          subscribeToNewsletter(email, token, captchaRef)
        })
      }
    }
  }

  function downloadPdfFile(email, isChecked, currentUrl, token, captchaRef) {
    postDownload(email, isChecked, currentUrl, token).then((res) => {
      if (res && res.status && res.status === 200) {
        setLoading(false)
        sendDataLayerEvent(
          "download_pdf",
          "guide_article",
          "telechargement-fiche-pdf"
        )
        setJustSentEmail(true)

        if (isChecked) {
          setIsSubscribed(true)
          localStorage.setItem("subscribed_to_newsletter", true)
          sendDataLayerEvent("subscribe", "newsletter", "abonnement-newsletter")
        }
      } else if (res && res.response && res.response.status === 401) {
        const { code } = res.response.data

        if (code === "invalid_email") {
          setValidatorError(true)
          setLoading(false)
        } else if (code === "recaptcha_invalid") {
          captchaRef.current.reset()
          setLoading(false)
        }
      } else if (res && res.response && res.response.status === 404) {
        const { code } = res.response.data
        if (code === "slug_not_found") {
          setLoading(false)
          setSlugNotFound(true)
        }
      }
    })
  }

  function handleDownloadFile(e, isChecked, currentUrl, captchaRef) {
    e.preventDefault()
    const email = credentials.email
    setLoading(true)

    if (captchaRef.current !== null) {
      captchaRef.current.executeAsync().then((token) => {
        downloadPdfFile(email, isChecked, currentUrl, token, captchaRef)
      })
    }
  }

  function handleKeyDown(e, captchaRef, source) {
    if (e.keyCode === 13) {
      if (source === "newsletter") {
        handleSubmitNewsletter(e, captchaRef)
      } else if (source === "download") {
        handleDownloadFile(e, captchaRef)
      }
    }
  }

  return (
    <NewsletterContext.Provider
      value={{
        asyncScriptOnLoad,
        handleChange,
        handleKeyDown,
        handleSubmitNewsletter,
        handleDownloadFile,
        captchaLoader,
        validatorError,
        loading,
        isSubscribed,
        displayNewsletter,
        justSentEmail,
        slugNotFound,
        credentials,
      }}
    >
      {children}
    </NewsletterContext.Provider>
  )
}

export const useNewsletterContext = () => {
  return useContext(NewsletterContext)
}
