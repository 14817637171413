import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import SiteWrapper from "@/components/layout/SiteWrapper"
import Meta from "@/components/layout/Meta"
import GuideArticleBody from "@/components/guides/GuideArticleBody"

import getStructuredData from "@/utils/getStructuredData"

import usePageViewTracker from "@/lib/hooks/usePageViewTracker"

import "@/assets/styles/articlepage.scss"
import "@/assets/styles/guide.scss"
import "@/assets/styles/printarticle.scss"
import usePopup from "@/utils/usePopup"

// Query for the Article Article content in Prismic
export const query = graphql`
  query ArticleQuery($uid: String) {
    prismicGuideArticle(uid: { eq: $uid }) {
      _previewable
      data {
        cta_summary
        title {
          text
        }
        text_cta_summary
        link_cta_summary {
          url
          uid
          type
          isBroken
          document {
            ... on PrismicGuideArticle {
              url
              uid
              type
            }
            ... on PrismicLandingPage {
              url
              uid
              type
            }
          }
        }
        body {
          ... on PrismicGuideArticleDataBodyBody {
            id
            slice_label
            slice_type
            primary {
              body_text {
                html
                richText
                text
              }
            }
          }
          ... on PrismicGuideArticleDataBodyTitre {
            id
            slice_label
            slice_type
          }
          ... on PrismicGuideArticleDataBodyCitation {
            slice_type
            primary {
              quote {
                richText
              }
              quote_author {
                text
              }
              quote_author_status {
                text
              }
            }
          }
          ... on PrismicGuideArticleDataBodyBox {
            slice_type
            primary {
              box_type
              box_title
              box_text {
                html
                richText
                text
              }
            }
          }
          ... on PrismicGuideArticleDataBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          ... on PrismicGuideArticleDataBodyCtaNatif {
            slice_type
            primary {
              native_cta_block {
                isBroken
                document {
                  ... on PrismicNativeCta {
                    id
                    data {
                      cta_bullet_1 {
                        richText
                        text
                      }
                      cta_bullet_2 {
                        richText
                        text
                      }
                      cta_bullet_3 {
                        richText
                        text
                      }
                      cta_text {
                        text
                        richText
                      }
                      cta_title {
                        text
                        richText
                      }
                      cta_visual {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        og: gatsbyImageData(layout: FIXED, width: 1200)
                        alt
                      }
                      cta_internal_link {
                        url
                        uid
                        type
                        document {
                          ... on PrismicGuideArticle {
                            url
                            uid
                            type
                          }
                          ... on PrismicLandingPage {
                            url
                            uid
                            type
                          }
                        }
                      }
                      has_seo_value
                    }
                  }
                }
              }
            }
          }
          ... on PrismicGuideArticleDataBodyTitre {
            slice_type
            primary {
              display_title
              title_summary
              lvl2_header {
                richText
                html
                text
              }
            }
          }
          ... on PrismicGuideArticleDataBodyContenuHtml {
            id
            slice_type
            primary {
              html_content {
                html
                richText
                text
              }
            }
          }
          ... on PrismicGuideArticleDataBodyExempleDUnDocument {
            id
            primary {
              document_example {
                html
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicGuideArticleDataBodySources {
            id
            items {
              media_content_title {
                text
              }
              media_name {
                text
              }
              media_external_link {
                text
                html
              }
            }
            slice_type
          }
          ... on PrismicGuideArticleDataBodyVideo {
            slice_type
            primary {
              video_link {
                author_name
                author_url
                html
                thumbnail_url
                thumbnail_width
                title
                embed_url
              }
              description
              upload_date
            }
          }
          ... on PrismicGuideArticleDataBodyCtaSimple {
            id
            slice_type
            primary {
              simple_cta_button {
                document {
                  ... on PrismicSimpleCta {
                    id
                    data {
                      simple_cta_link {
                        url
                      }
                      simple_cta_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicGuideArticleDataBodyNewsletter {
            id
            slice_type
          }
        }
        body1 {
          ... on PrismicGuideArticleDataBody1FaqSection {
            slice_type
            items {
              answer {
                text
                richText
              }
              question {
                text
                richText
              }
            }
          }
        }
        breadcrumb_title
        meta_title
        meta_description
        canonical_url {
          url
        }
        author {
          isBroken
          document {
            ... on PrismicAuteurs {
              id
              data {
                author_description {
                  text
                }
                author_first_name {
                  text
                }
                author_linkedin {
                  url
                }
                author_name {
                  text
                }
                author_picture {
                  url
                }
              }
            }
          }
        }
        title {
          html
          richText
          text
        }
        main_image {
          alt
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
        }
        introduction {
          html
          richText
          text
        }
        parent_article {
          uid
          type
          document {
            ... on PrismicGuideArticle {
              uid
              type
              data {
                title {
                  html
                  richText
                  text
                }
                breadcrumb_title
                parent_article {
                  uid
                  type
                  document {
                    ... on PrismicGuideArticle {
                      uid
                      type
                      data {
                        title {
                          html
                          richText
                          text
                        }
                        breadcrumb_title
                        parent_article {
                          uid
                          type
                          document {
                            ... on PrismicGuideArticle {
                              uid
                              type
                              data {
                                title {
                                  html
                                  richText
                                  text
                                }
                                breadcrumb_title
                                parent_article {
                                  uid
                                  type
                                  document {
                                    ... on PrismicGuideArticle {
                                      uid
                                      type
                                      data {
                                        title {
                                          html
                                          richText
                                          text
                                        }
                                        breadcrumb_title
                                      }
                                    }
                                    ... on PrismicLandingPage {
                                      url
                                      uid
                                      type
                                      data {
                                        type
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicLandingPage {
                              url
                              uid
                              type
                              data {
                                type
                                breadcrumb_title
                                title {
                                  text
                                  richText
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on PrismicLandingPage {
                      url
                      uid
                      type
                      data {
                        type
                        breadcrumb_title
                        title {
                          text
                          richText
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicLandingPage {
              url
              uid
              type
              data {
                type
                breadcrumb_title
                parent_article {
                  uid
                  type
                  document {
                    ... on PrismicGuideArticle {
                      uid
                      type
                      data {
                        breadcrumb_title
                        parent_article {
                          uid
                          type
                          document {
                            ... on PrismicGuideArticle {
                              uid
                              type
                              data {
                                breadcrumb_title
                                parent_article {
                                  uid
                                  type
                                  document {
                                    ... on PrismicGuideArticle {
                                      uid
                                      type
                                      data {
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                    ... on PrismicLandingPage {
                                      uid
                                      url
                                      type
                                      data {
                                        type
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                  }
                                }
                                title {
                                  text
                                  richText
                                }
                              }
                            }
                            ... on PrismicLandingPage {
                              uid
                              url
                              type
                              data {
                                type
                                breadcrumb_title
                                parent_article {
                                  uid
                                  type
                                  document {
                                    ... on PrismicGuideArticle {
                                      uid
                                      type
                                      data {
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                    ... on PrismicLandingPage {
                                      uid
                                      url
                                      type
                                      data {
                                        type
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                  }
                                }
                                title {
                                  text
                                  richText
                                }
                              }
                            }
                          }
                        }
                        title {
                          text
                          richText
                        }
                      }
                    }
                    ... on PrismicLandingPage {
                      uid
                      url
                      type
                      data {
                        type
                        breadcrumb_title
                        parent_article {
                          uid
                          type
                          document {
                            ... on PrismicGuideArticle {
                              uid
                              type
                              data {
                                breadcrumb_title
                                parent_article {
                                  uid
                                  type
                                  document {
                                    ... on PrismicGuideArticle {
                                      uid
                                      type
                                      data {
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                    ... on PrismicLandingPage {
                                      uid
                                      url
                                      type
                                      data {
                                        type
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                  }
                                }
                                title {
                                  text
                                  richText
                                }
                              }
                            }
                            ... on PrismicLandingPage {
                              uid
                              url
                              type
                              data {
                                type
                                breadcrumb_title
                                parent_article {
                                  uid
                                  type
                                  document {
                                    ... on PrismicGuideArticle {
                                      uid
                                      type
                                      data {
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                    ... on PrismicLandingPage {
                                      uid
                                      url
                                      type
                                      data {
                                        type
                                        breadcrumb_title
                                        title {
                                          text
                                          richText
                                        }
                                      }
                                    }
                                  }
                                }
                                title {
                                  text
                                  richText
                                }
                              }
                            }
                          }
                        }
                        title {
                          text
                          richText
                        }
                      }
                    }
                  }
                }
                title {
                  text
                  richText
                }
              }
            }
          }
        }
        sibling_articles {
          sibling_article {
            isBroken
            document {
              ... on PrismicGuideArticle {
                id
                data {
                  title_as_sibling_article {
                    richText
                    text
                    html
                  }
                  title {
                    text
                  }
                  breadcrumb_title
                }
                url
                type
                uid
              }
              ... on PrismicLandingPage {
                id
                url
                type
                uid
                data {
                  title {
                    text
                  }
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
              ... on PrismicAccueilContrats {
                url
                data {
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
            }
          }
        }
        children_articles {
          child_article {
            isBroken
            document {
              ... on PrismicGuideArticle {
                id
                data {
                  title_as_sibling_article {
                    richText
                    text
                    html
                  }
                  title {
                    text
                  }
                  breadcrumb_title
                }
                url
                type
                uid
              }
              ... on PrismicLandingPage {
                id
                url
                type
                uid
                data {
                  title {
                    text
                  }
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
              ... on PrismicAccueilContrats {
                url
                data {
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
            }
          }
        }
        abstract {
          abstract_item {
            html
            richText
          }
        }
      }
      first_publication_date(locale: "")
      last_publication_date(locale: "")
      uid
      id
      type
      prismicId
    }
    trustpilotGeneral {
      data {
        trustScore
        stars
        string
        numberOfReviews
        url
        image
        logo
      }
    }
  }
`

const ArticlePageTemplate = (props) => {
  // Define the Article content returned from Prismic
  const article = props.data.prismicGuideArticle

  usePageViewTracker("guide_article", article.uid)
  const { getWithExpiry } = usePopup()

  useEffect(() => {
    getWithExpiry("reviewPopup")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!article) {
    return null
  }

  const { parents, count } = getStructuredData(article, "guides")

  const [super_parent_category, parent_category, category, subcategory] =
    parents

  return (
    <SiteWrapper noPadding={true} articlePage helpscout={false}>
      <GuideArticleBody
        super_parent_category={super_parent_category}
        parent_category={parent_category}
        category={category}
        subcategory={subcategory}
        article={article}
        count={count}
      />
    </SiteWrapper>
  )
}

export default withPrismicPreview(ArticlePageTemplate)

export const Head = ({ data }) => {
  const article = data?.prismicGuideArticle
  const type = "guides"
  const { structuredData } = getStructuredData(article, type)

  const canonicalUrl = article.data.canonical_url?.url

  return (
    <Meta
      title={
        article.data.meta_title
          ? article.data.meta_title
          : article.data.title.text
      }
      description={
        article.data.meta_description ? article.data.meta_description : ""
      }
      canonicalUrl={canonicalUrl}
      structuredData={structuredData}
      ogImage={article.data.main_image.og?.images.fallback.src}
      ogArticle={true}
      currentLocation={article.uid}
      contentType={type}
    />
  )
}
