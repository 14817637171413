module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BailFacile - Logiciel de gestion locative","short_name":"BailFacile","start_url":"/","background_color":"#ffffff","theme_color":"#FFAD4A","display":"fullscreen","icon":"src/images/logo/logo_app.svg","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://3c29dedc4cfa4d039fe8cade8afc298f@o239824.ingest.sentry.io/5544974","environment":"production","tunnel":"https://api.bailfacile.fr/sentry/tunnel","enabled":true,"ignoreErrors":["ChunkLoadError","SecurityError: The operation is insecure.","SecurityError: Attempt to use history.replaceState() more than 100 times per 30 seconds","SecurityError: Attempt to use history.replaceState() more than 100 times per 30.000000 seconds"],"allowUrls":["bailfacile.fr","bailfacile.tech"],"maxBreadcrumbs":15},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PSX97DV","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bailfacile","accessToken":"MC5YODhtQnhJQUFDUUFoZmZH.PwJlIHzvv71p77-9AGXvv70g77-977-977-977-9Je-_vQrvv70J77-9c--_ve-_vVvvv73vv73vv714Bu-_vQ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
