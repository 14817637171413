import React from "react"

import "@/assets/styles/main-components.scss"

const HeadTitle = ({ tag, children }) => {
  const CustomTag = `${tag}`

  return <CustomTag className="head-title">{children}</CustomTag>
}

export default HeadTitle
