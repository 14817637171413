import React, { useState } from "react"

import SummaryTitle from "@/components/common/utils/SummaryTitle"
import { generateAndScroll } from "@/components/common/utils/generateAndScroll"

const ArticleSummary = ({
  article,
  articleSlices,
  currentSection,
  setCurrentSection,
  setClicked = null,
  hasAnFAQ,
  mobile = false,
}) => {
  const [summaryOpen, setSummaryOpen] = useState(false)

  const handleOpenSummary = () => {
    setSummaryOpen(!summaryOpen)
  }

  return (
    <div
      className={mobile ? "article-summary mobile" : "article-summary desktop"}
    >
      <p className="article-summary__title" onClick={handleOpenSummary}>
        SOMMAIRE
        {mobile && (
          <div className="article-summary__title--icon">
            <div className={summaryOpen ? "square" : "square closed"} />
          </div>
        )}
      </p>
      <ul
        className={
          summaryOpen || !mobile
            ? "article-summary__list"
            : "article-summary__list closed"
        }
      >
        {articleSlices.map((slice, i) => {
          return (
            <SummaryTitle
              key={`${i}-${slice.slug}`}
              slice={slice}
              article={article}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
              setClicked={setClicked}
              handleOnClick={generateAndScroll}
            />
          )
        })}
        {hasAnFAQ && (
          <li
            key={"faq-title-on-summary"}
            className="article-summary__list--item"
            name={"FAQ"}
            onClick={() =>
              generateAndScroll(
                article,
                "faq",
                setCurrentSection,
                true,
                setClicked
              )
            }
          >
            FAQ
          </li>
        )}
      </ul>
    </div>
  )
}

export default ArticleSummary
