import React, { useEffect, useState, useRef } from "react"
import { Icon, Modal } from "semantic-ui-react"

import SmartLink from "@/components/common/utils/SmartLink"
import Button from "@/components/common/main-components/Button"

import getReachedInfo from "@/utils/getReachedInfo"

import { sendDataLayerEvent } from "@/lib/trackers/events"

export function useSubproductsDropdown() {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return { showDropdown, setShowDropdown, dropdownRef }
}

export const DropdownContent = ({
  subProducts,
  linksList,
  dropdownRef = null,
}) => {
  return (
    <div className="subproducts__content" ref={dropdownRef}>
      {subProducts.map((subProduct, index) => {
        const productIcon = getReachedInfo(subProduct.subproduct_icon, "icon")

        const subproductLink = linksList.find(
          (link) => link.uid === subProduct.subproduct_slug
        )

        return (
          <SmartLink
            key={index}
            className={`subproducts__content--smart-link`}
            link={{ ...subproductLink, target: "_blank" }}
          >
            <div
              className="subproducts__content--item"
              onClick={() =>
                sendDataLayerEvent("click", "cta-landingpages", "cta-button")
              }
            >
              <Icon name={productIcon} size="large" />
              <div className="subproducts__content--item--text">
                <p className="title">{subProduct.subproduct_title.text}</p>
                <p className="subtitle">
                  {subProduct.subproduct_subtitle.text}
                </p>
              </div>
              <Icon name="chevron right" size="normal" />
            </div>
          </SmartLink>
        )
      })}
    </div>
  )
}

// For Mobile
export const SubProductsModal = ({
  subProducts,
  linksList,
  dropdownRef,
  closeModal,
}) => {
  return (
    <div className="subproducts-modal">
      <div className="subproducts__close" onClick={() => closeModal()}>
        <Icon name="close" size="large" />
      </div>
      <p className="subproducts__header">Choisir un modèle</p>
      <DropdownContent
        subProducts={subProducts}
        linksList={linksList}
        dropdownRef={dropdownRef}
      />
    </div>
  )
}

// For Desktop Main Button
export const SubProductsDropdown = ({
  buttonContent,
  subProducts,
  linksList,
}) => {
  const { showDropdown, setShowDropdown, dropdownRef } =
    useSubproductsDropdown()

  return (
    <div
      className={`subproducts-dropdown ${showDropdown ? "shown" : ""}`}
      ref={dropdownRef}
    >
      <Button
        content={buttonContent}
        icon="arrow right"
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DropdownContent subProducts={subProducts} linksList={linksList} />
      )}
    </div>
  )
}

// For Desktop Sticky CTA
export const SubProductsStickyDropdown = ({
  subProducts,
  linksList,
  showDropdown,
  setShowDropdown,
}) => {
  return (
    <Modal
      open={showDropdown}
      closeIcon={<Icon name="close" />}
      onClose={() => setShowDropdown(false)}
      className="subproducts-desktop-modal"
    >
      <Modal.Header className="subproducts-desktop-modal__header">
        Choisir un modèle
      </Modal.Header>
      <Modal.Content className="subproducts-desktop-modal__content">
        <DropdownContent subProducts={subProducts} linksList={linksList} />
      </Modal.Content>
    </Modal>
  )
}
