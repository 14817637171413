function smoothScrollIntoView(element, offset = 120) {
  const elementRect = document?.getElementById(element)?.getBoundingClientRect()
  const bodyRect = document?.body?.getBoundingClientRect()
  const goToY = elementRect?.top - bodyRect?.top - offset

  setTimeout(() => {
    window.scrollTo({
      top: goToY,
      behavior: "smooth",
      block: "start",
      inline: "center",
    })
  }, 100)
}

export default smoothScrollIntoView
