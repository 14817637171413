import React from "react"
import { Grid, Segment } from "semantic-ui-react"

const DocumentExample = ({ slice, index }) => {
  return (
    <Grid.Row
      key={`slice-${index}`}
      className="landingPage-slice-wrapper document-example"
      style={{ margin: "2.1em 0" }}
    >
      <Grid.Column width={16}>
        <Segment piled>
          <div
            style={{ padding: 10 }}
            dangerouslySetInnerHTML={{
              __html: slice?.primary?.document_example?.text
                ? slice.primary.document_example.text
                : slice.primary.html_content.text,
            }}
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  )
}

export default DocumentExample
