import React from "react"
import { Breadcrumb } from "semantic-ui-react"

import OrangeDot from "@/components/common/utils/OrangeDot"

const Divider = () => (
  <Breadcrumb.Divider style={{ display: "inline-block" }}>
    <OrangeDot style={{ margin: "0 5px" }} />
  </Breadcrumb.Divider>
)

export default Divider
