import React from "react"
import { Breadcrumb } from "semantic-ui-react"

import {
  GUIDE,
  LANDING,
  DOCUMENT,
  CONTRACT,
  CONTRACTS_HOME,
  DOCUMENTS_HOME,
  LOCAL_GUIDE,
} from "@/components/common/content-components/utils/consts"

const HigherParentBreadcrumbSection = ({
  type,
  content,
  firstParent = null,
}) => {
  let config

  function getLPConfig(content) {
    if (content?.data?.type === CONTRACT) {
      return {
        href: "/contrats",
        title: "Contrat de location",
      }
    } else if (content?.data?.type === DOCUMENT) {
      return {
        href: "/documents",
        title: "Document",
      }
    }
  }

  if (firstParent) {
    if (firstParent.type === GUIDE) {
      config = {
        href: "/guides",
        title: "Guide du bailleur",
      }
    } else if (firstParent.type === LANDING) {
      const lpConfig = getLPConfig(firstParent)
      config = {
        href: lpConfig.href,
        title: lpConfig.title,
      }
    } else if (firstParent === CONTRACTS_HOME) {
      config = {
        href: "/contrats",
        title: "Contrat de location",
      }
    } else if (firstParent === DOCUMENTS_HOME) {
      config = {
        href: "/documents",
        title: "Document",
      }
    }
  } else {
    if (type === GUIDE) {
      config = {
        href: "/guides",
        title: "Guide du bailleur",
      }
    } else if (type === LANDING) {
      const lpConfig = getLPConfig(content)
      config = {
        href: lpConfig.href,
        title: lpConfig.title,
      }
    } else if (type === LOCAL_GUIDE) {
      config = {
        href: "/gestion-locative",
        title: "Guide local",
      }
    }
  }

  return (
    <Breadcrumb.Section>
      <a href={config.href}>{config.title}</a>
    </Breadcrumb.Section>
  )
}

export default HigherParentBreadcrumbSection
