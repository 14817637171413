import React from "react"
import { Grid, Visibility } from "semantic-ui-react"

import ArticleSlices from "@/components/common/slices/ArticleSlices"
import addInsecableSpace from "@/components/common/utils/addInsecableSpace"

const SliceDisplay = ({
  slice,
  index,
  eleID,
  handleUpdate,
  articles,
  article,
}) => {
  return (
    <Visibility
      id={eleID}
      onUpdate={(e, data) => handleUpdate(data, index, eleID)}
      offset={77}
      className={`article-slice-visibility`}
    >
      {slice.slice_type === "titre" && (
        <Grid.Row key={`slice-${index}`}>
          <Grid.Column width={16}>
            <h2 style={{ width: "100%", height: "100%" }}>
              {addInsecableSpace(slice.primary.lvl2_header.text)}
            </h2>
          </Grid.Column>
        </Grid.Row>
      )}
      <ArticleSlices
        slices={slice.slices}
        articles={articles}
        article={article}
      />
    </Visibility>
  )
}

export default SliceDisplay
