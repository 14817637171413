import React from "react"
import { Icon } from "semantic-ui-react"

const blueBoxesData = [
  "Conforme à la loi",
  "PDF prêt à imprimer",
  "Aide à la rédaction",
]

const BlueBox = ({ content }) => {
  return (
    <div className="blue-box">
      <p>
        <Icon name="check circle outline" />
        {` ${content}`}
      </p>
    </div>
  )
}

const BlueBoxes = () => {
  return (
    <div className="blue-boxes">
      {blueBoxesData.map((box, i) => (
        <BlueBox key={`${i}-${box}`} content={box} />
      ))}
    </div>
  )
}

export default BlueBoxes
