import React from "react"

import SiblingsCards from "@/components/common/content-components/SiblingsCards"
import {
  CONTRACTS_HOME,
  GUIDE,
  LANDING,
  LOCAL_GUIDE,
  NEWS,
} from "@/components/common/content-components/utils/consts"

const SiblingArticles = ({ siblings, type, currentArticle }) => {
  let articlesToDisplay

  const siblingsWithoutBrokenLinks = siblings.filter(
    (sibling) => sibling?.isBroken === false || sibling?.document !== null
  )

  if (
    type === GUIDE ||
    type === LANDING ||
    type === CONTRACTS_HOME ||
    type === LOCAL_GUIDE
  ) {
    articlesToDisplay = siblingsWithoutBrokenLinks
  } else if (type === NEWS) {
    articlesToDisplay = siblingsWithoutBrokenLinks.filter(
      (sib) => sib.node.uid !== currentArticle.uid
    )
    articlesToDisplay = articlesToDisplay.sort(
      (a, b) =>
        new Date(b.node.first_publication_date) -
        new Date(a.node.first_publication_date)
    )
  }

  return (
    <div className="article-annexes__content">
      <SiblingsCards content={articlesToDisplay} type={type} />
    </div>
  )
}

export default SiblingArticles
