import { useEffect, useState } from "react"

export default function useIsCPC() {
  const [isCPC, setIsCPC] = useState(false)

  useEffect(() => {
    if (window?.location?.href.includes("#cpc")) {
      setIsCPC(true)
    }
  }, [])

  return isCPC
}
