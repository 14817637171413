import React, { useContext } from "react"

import ArticleHero from "@/components/common/content-components/blocks/ArticleHero"
import AnnexesBlock from "@/components/common/content-components/blocks/AnnexesBlock"
import SlicesBlock from "@/components/common/content-components/blocks/SlicesBlock"
import { NEWS } from "@/components/common/content-components/utils/consts"
import useSummaryStates from "@/components/common/utils/useSummaryStates"

import { AuthContext } from "@/lib/context/Auth"

import "@/assets/styles/articlepage.scss"
import "@/assets/styles/guide.scss"

const NewsArticleBody = ({ article, allContent, allNewsArticles }) => {
  const { setCurrentSection } = useSummaryStates()

  const { isAuthenticated } = useContext(AuthContext)

  return (
    <>
      <ArticleHero
        article={article}
        setCurrentSection={setCurrentSection}
        type={NEWS}
      />
      <SlicesBlock
        article={article}
        articles={allContent}
        isAuthenticated={isAuthenticated}
        type={NEWS}
      />
      {allNewsArticles?.edges?.length > 0 && (
        <AnnexesBlock
          type={NEWS}
          customData={allNewsArticles.edges}
          currentArticle={article}
        />
      )}
    </>
  )
}

export default NewsArticleBody
