import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import SiteWrapper from "@/components/layout/SiteWrapper"
import Meta from "@/components/layout/Meta"
import NewsArticleBody from "@/components/news/NewsArticleBody"

import getStructuredData from "@/utils/getStructuredData"

import "@/assets/styles/articlepage.scss"
import "@/assets/styles/guide.scss"

// Query for the news Article content in Prismic
export const query = graphql`
  query NewsArticleQuery($uid: String) {
    allPrismicNewsArticles {
      edges {
        node {
          type
          uid
          first_publication_date
          data {
            title {
              richText
              text
            }
            title_as_sibling_article {
              richText
              text
            }
          }
        }
      }
    }
    prismicNewsArticles(uid: { eq: $uid }) {
      _previewable
      data {
        breadcrumb_title
        canonical_url {
          url
        }
        introduction {
          html
          richText
          text
        }
        main_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
        }
        meta_description
        meta_title
        title {
          text
        }
        cta_summary
        text_cta_summary
        link_cta_summary {
          url
          uid
          type
          document {
            ... on PrismicGuideArticle {
              url
              uid
              type
            }
            ... on PrismicLandingPage {
              url
              uid
              type
            }
          }
        }
        author {
          isBroken
          document {
            ... on PrismicAuteurs {
              id
              data {
                author_description {
                  text
                }
                author_first_name {
                  text
                }
                author_linkedin {
                  url
                }
                author_name {
                  text
                }
                author_picture {
                  url
                }
              }
            }
          }
        }
        abstract {
          abstract_item {
            html
            richText
          }
        }
        body {
          ... on PrismicNewsArticlesDataBodyBody {
            id
            slice_type
            primary {
              body_text {
                html
                richText
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyBox {
            id
            slice_type
            primary {
              box_type
              box_title
              box_text {
                html
                richText
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyCitation {
            id
            slice_type
            primary {
              quote {
                richText
              }
              quote_author {
                text
              }
              quote_author_status {
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyContenuHtml {
            id
            slice_type
            primary {
              html_content {
                html
                richText
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyCtaNatif {
            id
            slice_type
            primary {
              native_cta_block {
                isBroken
                document {
                  ... on PrismicNativeCta {
                    id
                    data {
                      cta_bullet_1 {
                        richText
                        text
                      }
                      cta_bullet_2 {
                        richText
                        text
                      }
                      cta_bullet_3 {
                        richText
                        text
                      }
                      cta_text {
                        text
                        richText
                      }
                      cta_title {
                        text
                        richText
                      }
                      cta_visual {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        og: gatsbyImageData(layout: FIXED, width: 1200)
                        alt
                      }
                      cta_internal_link {
                        url
                        uid
                        type
                        document {
                          ... on PrismicGuideArticle {
                            url
                            uid
                            type
                          }
                          ... on PrismicLandingPage {
                            url
                            uid
                            type
                          }
                        }
                      }
                      has_seo_value
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyEncadreFaq {
            id
            slice_type
            primary {
              faq_question
              faq_reponse {
                richText
                html
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyExempleDUnDocument {
            id
            slice_type
            primary {
              document_example {
                html
                richText
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          ... on PrismicNewsArticlesDataBodySources {
            id
            slice_type
            items {
              media_content_title {
                text
              }
              media_name {
                text
              }
              media_external_link {
                text
                html
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyTitre {
            id
            slice_type
            primary {
              display_title
              title_summary
              lvl2_header {
                richText
                html
                text
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyVideo {
            slice_type
            primary {
              video_link {
                author_name
                author_url
                html
                thumbnail_url
                thumbnail_width
                title
                embed_url
              }
              description
              upload_date
            }
          }
          ... on PrismicNewsArticlesDataBodyCtaSimple {
            id
            slice_type
            primary {
              simple_cta_button {
                document {
                  ... on PrismicSimpleCta {
                    id
                    data {
                      simple_cta_link {
                        url
                      }
                      simple_cta_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewsArticlesDataBodyNewsletter {
            id
            slice_type
          }
        }
        body1 {
          ... on PrismicNewsArticlesDataBody1FaqSection {
            slice_type
            items {
              answer {
                text
              }
              question {
                text
              }
            }
          }
        }
      }
      first_publication_date(locale: "")
      last_publication_date(locale: "")
      uid
      id
      type
      prismicId
    }
    trustpilotGeneral {
      data {
        trustScore
        stars
        string
        numberOfReviews
        url
        image
        logo
      }
    }
  }
`

const NewsArticlePageTemplate = (props) => {
  // Define the Article content returned from Prismic
  const allNewsArticles = props.data.allPrismicNewsArticles
  const article = props.data.prismicNewsArticles
  if (!article) {
    return null
  }

  return (
    <SiteWrapper noPadding={true} articlePage helpscout={false}>
      <NewsArticleBody article={article} allNewsArticles={allNewsArticles} />
    </SiteWrapper>
  )
}

export default withPrismicPreview(NewsArticlePageTemplate)

export const Head = ({ data }) => {
  const article = data?.prismicNewsArticles
  const contentType = "news"
  const { structuredData } = getStructuredData(article, contentType)

  const canonicalUrl = article.data.canonical_url?.url

  return (
    <Meta
      title={
        article.data.meta_title
          ? article.data.meta_title
          : article.data.title.text
      }
      description={
        article.data.meta_description ? article.data.meta_description : ""
      }
      canonicalUrl={canonicalUrl}
      noindex={false}
      structuredData={structuredData}
      ogImage={article?.data?.main_image?.og?.images?.fallback?.src}
      ogArticle={true}
      currentLocation={article.uid}
      contentType={contentType}
    />
  )
}
