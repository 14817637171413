import React from "react"

import addInsecableSpace from "@/components/common/utils/addInsecableSpace"
import OrangeDot from "@/components/common/utils/OrangeDot"
import SmartLink from "@/components/common/utils/SmartLink"

const ItemsMapping = (items, className, withDot, isHome, isContracts) => {
  const handleMouseHover = (e) => {
    e.target.classList.toggle(`hovered`)
    e.target.parentElement.parentElement.parentElement.classList.toggle(
      `hovered`
    )
  }

  return items.map((item, i) => (
    <span
      key={`${i}-${item.slug}`}
      className={`section-item ${className}`}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
    >
      {className === "about" &&
      (item.slug.includes("aide") || item.slug.includes("mailto")) ? (
        <a href={item.slug}>
          <p className="item-text">{addInsecableSpace(item.title)}</p>
        </a>
      ) : (
        <SmartLink
          link={{
            to: item.slug,
            external: false,
            hidden: isContracts === true ? false : !isHome,
          }}
          className={isHome || isContracts ? "not-hidden" : "hidden"}
        >
          <p className="item-text">{addInsecableSpace(item.title)}</p>
        </SmartLink>
      )}
      {withDot ? <OrangeDot /> : null}
    </span>
  ))
}

export default ItemsMapping
