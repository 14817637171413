import React, { useState } from "react"
import { createPortal } from "react-dom"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Icon } from "semantic-ui-react"

import CustomImageData from "@/utils/CustomImageData"

import DownloadFileModal from "@/components/common/content-components/download/DownloadFileModal"

import { useNewsletterContext } from "@/lib/context/NewsletterContext"

const DownloadButton = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const [open, setOpen] = useState(false)

  const { justSentEmail } = useNewsletterContext()

  const handleModal = (value) => {
    setOpen(value)
  }
  return (
    <>
      {justSentEmail ? (
        <div className="abstract__download just-sent">
          <Icon name="check" />
          <p className="abstract__download--text">Fiche téléchargée</p>
        </div>
      ) : (
        <div className="abstract__download" onClick={() => handleModal(true)}>
          <CustomImageData
            data={data}
            path="marketing/download.svg"
            alt="Télécharger la fiche en PDF"
          />
          <p className="abstract__download--text">
            Télécharger la fiche en PDF
          </p>
        </div>
      )}
      {open &&
        createPortal(
          <DownloadFileModal
            justSentEmail={justSentEmail}
            handleModal={handleModal}
          />,
          document.body
        )}
    </>
  )
}

export default DownloadButton
