import React, { useContext } from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import SiteWrapper from "@/components/layout/SiteWrapper"
import Meta from "@/components/layout/Meta"
import LandingPageBody from "@/components/landingpages/LandingPageBody"
import getDocumentsCTALink from "@/components/landingpages/utils/getDocumentsCTALink"
import { CONTRACTS_HOME } from "@/components/common/content-components/utils/consts"

import useIsCPC from "@/utils/useIsCPC"
import getStructuredData from "@/utils/getStructuredData"

import { AuthContext } from "@/lib/context/Auth"
import usePageViewTracker from "@/lib/hooks/usePageViewTracker"

export const query = graphql`
  {
    prismicAccueilContrats {
      _previewable
      type
      data {
        title {
          text
          richText
        }
        marketing_promise {
          text
          richText
        }
        mobile_marketing_promise {
          text
          richText
        }
        hero_cta_content
        visual_cta_content
        main_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
          alt
        }
        og_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
          alt
        }
        block_1_title {
          richText
          text
        }
        block_1_text {
          richText
          text
        }
        block_1_icon
        block_1_color
        block_2_title {
          richText
          text
        }
        block_2_text {
          richText
          text
        }
        block_2_icon
        block_2_color
        block_3_title {
          richText
          text
        }
        block_3_text {
          richText
          text
        }
        block_3_icon
        block_3_color
        body {
          ... on PrismicAccueilContratsDataBodyTitre {
            slice_type
            primary {
              lvl2_header {
                richText
                text
              }
              display_title
              title_summary
            }
          }
          ... on PrismicAccueilContratsDataBodyTexteGeneral {
            slice_type
            primary {
              body_text {
                richText
                text
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyBox {
            slice_type
            primary {
              box_type
              box_title
              box_text {
                richText
                text
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyCitation {
            slice_type
            primary {
              quote {
                richText
                text
              }
              quote_author {
                richText
                text
              }
              quote_author_status {
                richText
                text
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyImage {
            slice_type
            primary {
              image {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                og: gatsbyImageData(layout: FIXED, width: 1200)
                alt
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyCtaNatif {
            slice_type
            primary {
              native_cta_block {
                isBroken
                document {
                  ... on PrismicNativeCta {
                    data {
                      cta_title {
                        richText
                        text
                      }
                      cta_text {
                        richText
                        text
                      }
                      cta_bullet_1 {
                        richText
                        text
                      }
                      cta_bullet_2 {
                        richText
                        text
                      }
                      cta_bullet_3 {
                        richText
                        text
                      }
                      cta_internal_link {
                        url
                        uid
                        type
                        document {
                          ... on PrismicGuideArticle {
                            url
                            uid
                            type
                          }
                          ... on PrismicLandingPage {
                            url
                            uid
                            type
                          }
                        }
                      }
                      has_seo_value
                      cta_visual {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        og: gatsbyImageData(layout: FIXED, width: 1200)
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyContenuHtml {
            slice_type
            primary {
              html_content {
                richText
                text
                html
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyExempleDUnDocument {
            slice_type
            primary {
              document_example {
                richText
                text
                html
              }
            }
          }
          ... on PrismicAccueilContratsDataBodyVideo {
            slice_type
            primary {
              video_link {
                author_name
                author_url
                html
                thumbnail_url
                thumbnail_width
                title
                embed_url
              }
              description
              upload_date
            }
          }
        }
        body1 {
          ... on PrismicAccueilContratsDataBody1FaqSection {
            slice_type
            items {
              question {
                text
                richText
              }
              answer {
                richText
                text
              }
            }
          }
        }
        title_as_sibling_article {
          text
          richText
        }
        sibling_articles {
          sibling_article {
            isBroken
            document {
              ... on PrismicGuideArticle {
                id
                data {
                  title_as_sibling_article {
                    richText
                    text
                  }
                  title {
                    text
                  }
                  breadcrumb_title
                }
                url
                type
                uid
              }
              ... on PrismicLandingPage {
                id
                url
                type
                uid
                data {
                  title_as_sibling_article {
                    richText
                    text
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        canonical_url {
          url
        }
        meta_description
        meta_title
      }
      first_publication_date(locale: "")
      last_publication_date(locale: "")
    }
    trustpilotGeneral {
      data {
        trustScore
        stars
        string
        numberOfReviews
        url
        image
        logo
      }
    }
  }
`

const ContractsHomePageTemplate = (props) => {
  const landingpage = props.data.prismicAccueilContrats

  const { isAuthenticated } = useContext(AuthContext)
  const isCPC = useIsCPC()

  const CTAlink = getDocumentsCTALink(landingpage, isAuthenticated)

  usePageViewTracker("landingpage", "bail-location")

  return (
    <SiteWrapper noPadding={true} cpc={isCPC} articlePage>
      <LandingPageBody
        article={landingpage}
        isAuthenticated={isAuthenticated}
        CTAlink={CTAlink}
        type={CONTRACTS_HOME}
      />
    </SiteWrapper>
  )
}

export default withPrismicPreview(ContractsHomePageTemplate)

export const Head = ({ data }) => {
  const contractHomepage = data?.prismicAccueilContrats
  const type = `contracts-homepage`
  const { structuredData } = getStructuredData(contractHomepage, type)

  const canonicalUrl = contractHomepage.data.canonical_url?.url

  return (
    <Meta
      canonicalUrl={canonicalUrl}
      title={
        contractHomepage.data.meta_title
          ? contractHomepage.data.meta_title
          : contractHomepage.data.title.text
      }
      description={
        contractHomepage.data.meta_description
          ? contractHomepage.data.meta_description
          : contractHomepage.data.title.text
      }
      structuredData={structuredData}
      ogArticle={true}
      ogImage={contractHomepage?.data?.og_image?.og?.images?.fallback?.src}
      contentType={type}
    />
  )
}
