// -- The HTML Serializer
// This function will be used to modify the way that a Rich Text or Title field is rendered.
import React from "react"

import HeadingThree from "@/components/guides/utils/HeadingThree"
import addInsecableSpace from "@/components/common/utils/addInsecableSpace"
import SmartLink from "@/components/common/utils/SmartLink"
import ContentLink from "@/components/common/utils/ContentLink"

import hiddenLinksManager from "@/utils/hiddenLinksManager"
import getLinkedArticle from "@/utils/getLinkedArticle"
import dateVariableInTitle from "./dateVariableInTitle"

let htmlSerializer = (
  type,
  element,
  content,
  children,
  index,
  articles = null,
  isLegalPage = false,
  marketingHeader = false,
  tripleSection = false
) => {
  let linkHiddenized = null

  if (tripleSection) {
    return <p>{dateVariableInTitle(content)}</p>
  }

  if (type === "list-item" && content.charAt(0) === "_") {
    return (
      <li key={index} className="list-subitem">
        {content.slice(1)}
      </li>
    )
  }

  // special links for local landing pages (and broken ones)
  if (type === "hyperlink" && element?.data?.link_type === "Document") {
    if (element?.data?.type === "landing_page_local") {
      return (
        <SmartLink link={{ to: `/gestion-locative/${element?.data?.uid}` }}>
          {content}
        </SmartLink>
      )
    } else if (element?.data?.type === "guide_articles_local") {
      const { type, uid, slug } = element ? element.data : {}
      const linkedArticle = getLinkedArticle(articles, type, uid, slug)
      const linkTo = linkedArticle
        ? `/gestion-locative/${linkedArticle.node.data.associated_city}/${linkedArticle.node.uid}`
        : null

      return <SmartLink link={{ to: linkTo }}>{content}</SmartLink>
    } else if (element?.data?.type === "broken_type") {
      return <span>{content}</span>
    }
  }

  if (
    (element?.data?.link_type === "Web" || element?.data?.isBroken === false) &&
    type === "hyperlink" &&
    content &&
    content.includes("**hidden**")
  ) {
    linkHiddenized = hiddenLinksManager(content, element, articles)
    if (linkHiddenized && element?.data?.uid === linkHiddenized?.uid) {
      const { to, href, target, external, hidden, content } = linkHiddenized
      return (
        <SmartLink
          className={"hidden-link"}
          link={{ to, href, target, external, hidden }}
        >
          {content}
        </SmartLink>
      )
    }
  }

  if (
    (element?.data?.link_type === "Web" || element?.data?.isBroken === false) &&
    type === "hyperlink" &&
    content &&
    !content.includes("**hidden**")
  ) {
    if (element) {
      const { type, uid, slug } = element?.data
      let linkedArticle
      if (!isLegalPage) {
        if (type === "contrats" || type === "documents") {
          linkedArticle = type
        } else if (type === "accueil_contrats") {
          linkedArticle = "contrats"
        } else {
          linkedArticle = getLinkedArticle(articles, type, uid, slug)
        }
      } else {
        linkedArticle = null
      }

      return (
        <ContentLink
          element={element}
          content={content}
          index={index}
          key={index}
          article={linkedArticle}
          target="_blank"
          className="not-hidden-link"
        />
      )
    }
  }

  if (type === "heading3" && !marketingHeader) {
    return (
      <HeadingThree
        key={element.text}
        content={addInsecableSpace(element.text)}
      />
    )
  }

  // Return null to stick with the default behavior for everything else
  return null
}

export default htmlSerializer
