import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import dateVariableInTitle from "@/utils/dateVariableInTitle"
import getPageUrl from "@/utils/getPageUrl"
import { useSiteMetadata } from "@/utils/useSiteMetadata"
import { WEBSITE_URL, RECAPTCHA_SITE_KEY_V2 } from "@/lib/constants"

const Meta = ({
  title = null,
  description = null,
  structuredData = [],
  noindex = null,
  canonicalUrl = null,
  ogImage = null,
  ogArticle = null,
  currentLocation = null,
  contentType = null,
  city = null,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata()

  const trustpilot = useStaticQuery(graphql`
    query TrustpilotMeta {
      trustpilotGeneral {
        data {
          trustScore
          stars
          string
          numberOfReviews
          url
          image
          logo
        }
      }
    }
  `)

  let metaTitle = dateVariableInTitle(title) || defaultTitle
  let pageUrl = getPageUrl(currentLocation, contentType, city) || siteUrl

  let trustpilotData = {
    "@context": "http://schema.org",
    "@type": "AggregateRating",
    ratingCount: trustpilot.trustpilotGeneral.data.numberOfReviews.toString(),
    ratingValue: trustpilot.trustpilotGeneral.data.trustScore.toString(),
    bestRating: 5,
    worstRating: 1,
    name: "BailFacile",
    itemReviewed: {
      "@type": "SoftwareApplication",
      url: pageUrl,
      name: "BailFacile",
      applicationCategory: "Gestion locative en ligne",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingCount:
          trustpilot.trustpilotGeneral.data.numberOfReviews.toString(),
        ratingValue: trustpilot.trustpilotGeneral.data.trustScore.toString(),
        bestRating: 5,
        worstRating: 1,
      },
      offers: {
        "@type": "Offer",
        price: "0",
        priceCurrency: "EUR",
        availability: "https://schema.org/InStock",
      },
    },
  }

  let baseData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "BailFacile",
    url: "https://www.bailfacile.fr",
    logo: "https://www.bailfacile.fr/img/logo_small.png",
    alternateName: "Bail Facile",
    sameAs: [
      "https://www.linkedin.com/company/bailfacile",
      "https://www.facebook.com/BailFacile",
      "https://twitter.com/bailfacile",
      "https://www.pinterest.fr/BailFacileImmobilier/",
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "",
        contactType: "customer service",
        email: "contact@bailfacile.fr",
        contactOption: "TollFree",
        areaServed: "FR",
        availableLanguage: "fr",
      },
    ],
  }

  let structuredDataToDisplay = []
  structuredDataToDisplay.push(trustpilotData, baseData, ...structuredData)

  let canonicalUrlHref

  if (contentType === "news-homepage") {
    // even if it's another page that the first one
    canonicalUrlHref = `https://www.bailfacile.fr/actualites`
  } else {
    canonicalUrlHref = canonicalUrl ? canonicalUrl : pageUrl
  }

  const ogTypeContent = ogArticle ? "article" : "website"
  const ogImageContent = ogImage
    ? ogImage
    : "https://www.bailfacile.fr/img/logo_og.jpg"

  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="Content-Language" content="fr" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />

      <title>{metaTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      {noindex && <meta name="robots" content="noindex" />}

      {/* FACEBOOK META TAGS */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content={ogTypeContent} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:site_name" content="BailFacile" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageContent} />
      <meta property="og:locale" content="fr_FR" />

      {/* TWITTER META TAGS */}
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:domain" content={WEBSITE_URL} />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageContent} />

      <meta name="p:domain_verify" content="793329d5fc2b2fc91d44f469d30aad77" />

      <link rel="canonical" href={canonicalUrlHref} />
      <script type="application/ld+json">
        {JSON.stringify(structuredDataToDisplay)}
      </script>
      <script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      ></script>
      <script
        async
        defer
        src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY_V2}`}
      ></script>
    </>
  )
}
export default Meta
