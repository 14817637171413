import { useContext, useEffect } from "react"
import { AuthContext } from "@/lib/context/Auth"
import { sendPageViewDataLayerEvent } from "@/lib/trackers/events"

function usePageViewTracker(pageType, product = null) {
  const { loggedinUser, loading } = useContext(AuthContext)

  useEffect(() => {
    if (!loading) {
      if (loggedinUser?.user?.id) {
        sendPageViewDataLayerEvent(pageType, loggedinUser.user.id, product)
      } else {
        sendPageViewDataLayerEvent(pageType, null, product)
      }
    }
  }, [loading, loggedinUser, pageType, product])
}

export default usePageViewTracker
