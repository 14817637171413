export default function getPageUrl(
  currentLocation = null,
  contentType,
  city = null
) {
  let url = "https://www.bailfacile.fr"

  if (!currentLocation && contentType !== "contracts-homepage") {
    url
  } else if (!currentLocation && contentType === "contracts-homepage") {
    url += "/contrats"
  } else if (currentLocation && contentType === "accueil_seo_local") {
    url += "/gestion-locative/france"
  } else if (currentLocation && contentType === "comparison-page") {
    url += currentLocation
  } else if (currentLocation && contentType !== "comparison-page") {
    if (contentType === "static") {
      if (currentLocation === "/") {
        url
      } else {
        url += currentLocation
      }
    } else if (contentType === "guides") {
      if (currentLocation === "/") {
        url += "/guides"
      } else {
        url += `/guides/${currentLocation}`
      }
    } else if (contentType === "local-guide") {
      url += `/gestion-locative/${city}/${currentLocation}`
    } else if (contentType === "news") {
      if (currentLocation === "/") {
        url += "/actualites"
      } else {
        url += `/actualites/${currentLocation}`
      }
    } else if (contentType === "typos") {
      url += `/location/${currentLocation}`
    } else if (contentType === "personas") {
      url += `/bailleur/${currentLocation}`
    } else if (contentType === "features") {
      url += `/fonctionnalites/${currentLocation}`
    } else if (
      contentType === "contrats" ||
      contentType === "documents" ||
      contentType === "external"
    ) {
      url += currentLocation
    } else if (contentType === "landingpage-contract") {
      url += `/contrats/${currentLocation}`
    } else if (contentType === "landingpage-document") {
      url += `/documents/${currentLocation}`
    } else if (contentType === "landingpage-local") {
      url += `/gestion-locative/${currentLocation}`
    } else if (contentType === "legalpage") {
      url += `/${currentLocation}`
    }
  }

  return url
}
