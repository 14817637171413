import React from "react"
import { Button, Icon } from "semantic-ui-react"

import CustomImage from "@/utils/customImage"
import dateVariableInTitle from "@/utils/dateVariableInTitle"

import SmartLink from "../common/utils/SmartLink"
import {
  CONTRACTS_HOME,
  DOCUMENTS_HOME,
} from "@/components/common/content-components/utils/consts"
import TrustpilotInline from "@/components/trustpilot/TrustpilotInline"

const NativeCTA = ({ slice }) => {
  const nativeCTA =
    slice?.primary?.native_cta_block?.isBroken === false &&
    slice?.primary?.native_cta_block?.document?.data

  const isHidden = !!nativeCTA.has_seo_value

  let CTAlink

  if (nativeCTA?.cta_internal_link?.document?.type) {
    CTAlink = nativeCTA.cta_internal_link.document.url
  } else {
    if (nativeCTA.cta_internal_link?.type === CONTRACTS_HOME) {
      CTAlink = "/contrats"
    } else if (nativeCTA.cta_internal_link?.type === DOCUMENTS_HOME) {
      CTAlink = "/documents"
    } else if (!nativeCTA.cta_internal_link?.type) {
      CTAlink = nativeCTA.cta_internal_link?.url
    }
  }

  return (
    <div className="article-native-cta">
      <div className="article-native-cta__content">
        <p className="article-native-cta__content--title">
          {nativeCTA.cta_title.text}
        </p>
        <div className="article-native-cta__content--bullets">
          <span className="item">
            <Icon name="check" size="small" />
            {dateVariableInTitle(nativeCTA.cta_bullet_1.text)}
          </span>
          <span className="item">
            <Icon name="check" size="small" />
            {dateVariableInTitle(nativeCTA.cta_bullet_2.text)}
          </span>
          <span className="item">
            <Icon name="check" size="small" />
            {dateVariableInTitle(nativeCTA.cta_bullet_3.text)}
          </span>
        </div>
        <Button color="yellow" size="big">
          {isHidden ? (
            <SmartLink
              link={{
                hidden: true,
                external: true,
                href: CTAlink,
              }}
              className={"hidden"}
            >
              {nativeCTA.cta_text.text}
            </SmartLink>
          ) : (
            <a href={`${CTAlink}`}>{nativeCTA.cta_text.text}</a>
          )}
        </Button>
        <div className="article-native-cta__image">
          {isHidden ? (
            <SmartLink
              link={{
                hidden: true,
                external: true,
                href: CTAlink,
              }}
              className={"hidden"}
            >
              <CustomImage
                image={nativeCTA.cta_visual.gatsbyImageData}
                alt={nativeCTA.cta_visual.alt}
              />
            </SmartLink>
          ) : (
            <a href={`${CTAlink}`}>
              <CustomImage
                image={nativeCTA.cta_visual.gatsbyImageData}
                alt={nativeCTA.cta_visual.alt}
              />
            </a>
          )}
        </div>
      </div>
      <TrustpilotInline />
    </div>
  )
}

export default NativeCTA
