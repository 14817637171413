import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import CustomImageData from "@/utils/CustomImageData"

import SmartLink from "@/components/common/utils/SmartLink"

const StarsNote = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <div className="starsNote">
      <CustomImageData
        data={data}
        path="trustpilot/trustpilot-full-star.svg"
        alt="Étoile Trustpilot complète"
      />
      <CustomImageData
        data={data}
        path="trustpilot/trustpilot-full-star.svg"
        alt="Étoile Trustpilot complète"
      />
      <CustomImageData
        data={data}
        path="trustpilot/trustpilot-full-star.svg"
        alt="Étoile Trustpilot complète"
      />
      <CustomImageData
        data={data}
        path="trustpilot/trustpilot-full-star.svg"
        alt="Étoile Trustpilot complète"
      />
      <CustomImageData
        data={data}
        path="trustpilot/trustpilot-partial-star.svg"
        alt="Étoile Trustpilot incomplète"
      />
    </div>
  )
}

const Content = () => {
  return (
    <div className="trustpilotInline__stars--note">
      <StarsNote />
    </div>
  )
}

const HasClick = ({ url, string, homeHero }) => {
  return (
    <SmartLink
      link={{
        href: url,
        external: true,
        hidden: !homeHero,
        target: "_blank",
      }}
      ariaLabel="Lien vers Trustpilot"
    >
      <Content string={string} />
    </SmartLink>
  )
}

const NoClick = ({ string }) => {
  return (
    <span rel="noopener noreferrer">
      <Content string={string} />
    </span>
  )
}

const Stars = ({ data, noClick, homeHero }) => (
  <div className="trustpilotInline__stars">
    {!noClick && (
      <HasClick url={data?.url} string={data?.string} homeHero={homeHero} />
    )}
    {noClick && <NoClick string={data?.string} />}
  </div>
)

export default Stars
