import React from "react"

import SmartLink from "@/components/common/utils/SmartLink"

import CustomImage from "@/utils/customImage"

const Content = ({ data, homeHero, white }) => {
  return (
    <SmartLink
      link={{
        href: data?.url ? data?.url : "",
        external: true,
        hidden: !homeHero,
        target: "_blank",
      }}
    >
      <span className="string">
        {data?.string}
        <span className="vertical-bar">&#x7c;</span>
      </span>
      <span className="trustpilotSmallContentStrong">{data?.trustScore}</span>{" "}
      sur {data?.numberOfReviews?.toLocaleString("fr-FR")} avis
      <CustomImage
        image={white ? data?.logo.replace("light", "dark") : data?.logo}
        alt="Logo trustpilot"
        type="trustpilot"
        imgStyle={{
          width: "80px",
          height: "20px",
          marginLeft: "10px",
          marginRight: "10px",
          marginBottom: "-3.58px",
          display: "inline-block ",
        }}
      />
    </SmartLink>
  )
}

export default Content
