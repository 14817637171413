import React, { useState, useRef, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CustomImageStyle = () => {
  return (
    <style global="true" jsx="true">
      {`
        div.customImageContainer {
          width: auto;
          height: auto;
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        div.customImageContainer.fromFeatures {
          width: 100px;
          height: auto;
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        div.customImageBlur {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          transition: opacity 1s;
          backdrop-filter: blur(10px);
          will-change: opacity;
        }

        img.customImage {
          width: 100%;
        }

        @media (min-width: 992px) {
          div.customImageContainer.fromFeatures {
            width: auto;
          }
        }
      `}
    </style>
  )
}

const CustomImage = ({
  image,
  alt = "",
  type,
  imgStyle,
  lazy,
  origin,
  customWidth = null,
  customHeight = null,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const inputRef = useRef()
  const Image = getImage(image)

  if (alt === undefined || alt === null) {
    alt = ""
  }

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setImageLoaded(true)
    }
  }, [])

  if (image && image?.extension === "svg") {
    let width =
      customWidth === null ? imgStyle?.width?.replace("px", "") : customWidth
    let height =
      customHeight === null ? imgStyle?.height?.replace("px", "") : customHeight

    return (
      <React.Fragment>
        <CustomImageStyle />
        {lazy === false ? (
          <img src={image.publicURL} alt={alt} width={width} height={height} />
        ) : (
          <div
            aria-hidden="true"
            className={
              origin === "features"
                ? "customImageContainer fromFeatures"
                : "customImageContainer"
            }
          >
            <div
              aria-hidden="true"
              className="customImageBlur"
              style={{ opacity: imageLoaded ? 0 : 1 }}
            ></div>
            <img
              loading="lazy"
              ref={inputRef}
              src={image.publicURL}
              alt={alt}
              width={width}
              height={height}
              style={{
                marginRight: imgStyle?.marginRight,
              }}
              className="customImage"
            />
          </div>
        )}
      </React.Fragment>
    )
  }
  if (image && type === "trustpilot") {
    return (
      <React.Fragment>
        <img src={image} alt={alt} style={{ ...imgStyle }} loading="lazy" />
      </React.Fragment>
    )
  }
  if (image && type === "maintenance") {
    return (
      <React.Fragment>
        <img src={image} alt={alt} style={{ ...imgStyle }} loading="lazy" />
      </React.Fragment>
    )
  }
  if (image && type === "logo") {
    return (
      <React.Fragment>
        <img src={image} alt={alt} style={{ ...imgStyle }} loading="lazy" />
      </React.Fragment>
    )
  }

  if (Image && customWidth !== null && customHeight !== null) {
    Image.width = customWidth
    Image.height = customHeight

    return (
      <React.Fragment>
        {(Image && type === "DocumentCard") || type === "contract" ? (
          <GatsbyImage image={Image} alt={alt} className="preview-img" />
        ) : (
          Image && <GatsbyImage image={Image} alt={alt} />
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {(Image && type === "DocumentCard") || type === "contract" ? (
        <GatsbyImage image={Image} alt={alt} className="preview-img" />
      ) : (
        Image && <GatsbyImage image={Image} alt={alt} />
      )}
    </React.Fragment>
  )
}

export default CustomImage
