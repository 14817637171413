import React, { createRef, useContext, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { PrismicRichText } from "@prismicio/react"

import FinalSlicesMap from "@/components/common/slices/FinalSlicesMap"
import ArticleSummary from "@/components/common/content-components/ArticleSummary"
import ArticleSources from "@/components/common/content-components/ArticleSources"
import StaticCTA from "@/components/common/content-components/StaticCTA"
import ArticleAuthor from "@/components/common/content-components/ArticleAuthor"
import FAQSections from "@/components/common/content-components/FAQSections"
import CompleteSharing from "@/components/common/content-components/CompleteSharing"
import Abstract from "@/components/common/content-components/Abstract"
import IntroductionToBF from "@/components/common/content-components/IntroductionToBF"
import StickyOnRight from "@/components/common/sticky-components/StickyOnRight"
import LandingStickyCTA from "@/components/common/sticky-components/LandingStickyCTA"
import ReviewPopup from "@/components/common/sticky-components/ReviewPopup"
import ArticleBreadcrumb from "@/components/common/content-components/ArticleBreadcrumb"
import NewsletterBig from "@/components/common/content-components/NewsletterBig"
import DownloadButton from "@/components/common/content-components/download/DownloadButton"
import getArticleSlices from "@/components/common/utils/getArticleSlices"
import {
  CONTRACTS_HOME,
  GUIDE,
  LANDING,
  LOCAL_GUIDE,
} from "@/components/common/content-components/utils/consts"
import useSummaryStates from "@/components/common/utils/useSummaryStates"

import linkGenerator from "@/utils/linkGenerator"
import htmlSerializer from "@/utils/htmlSerializer"
import usePopup from "@/utils/usePopup"

import { useNewsletterContext } from "@/lib/context/NewsletterContext"
import getDocumentsCTALink from "@/components/landingpages/utils/getDocumentsCTALink"
import { AuthContext } from "@/lib/context/Auth"

const SlicesBlock = ({
  article,
  articles,
  type = null,
  link = null,
  isLegalPage = false,
  super_parent_category = null,
  parent_category = null,
  category = null,
  subcategory = null,
  stickyVisual = null,
}) => {
  const { currentSection, setCurrentSection, clicked, setClicked } =
    useSummaryStates()

  const { isAuthenticated } = useContext(AuthContext)

  const { data } = article
  const hasSubProducts = data?.sub_products?.length > 0
  const links = hasSubProducts && getDocumentsCTALink(article, isAuthenticated)

  const {
    asyncScriptOnLoad,
    handleChange,
    handleKeyDown,
    handleSubmitNewsletter,
    captchaLoader,
    validatorError,
    loading,
    isSubscribed,
    displayNewsletter,
  } = useNewsletterContext()
  const { progress, getWithExpiry } = usePopup()
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (progress >= 50 && getWithExpiry("reviewPopup", true) !== "closed") {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const stickyCTARef = createRef()

  const { finalSlices, articleSlices, faqSections, sourcesSection } =
    getArticleSlices(article)

  const isLanding = type === LANDING || type === CONTRACTS_HOME
  const isGuide = type === GUIDE
  const isLocalGuide = type === LOCAL_GUIDE

  const hasAnFAQ = faqSections.length > 0
  const hasSources = sourcesSection.length > 0
  const hasAnAuthor = !!article.data.author

  const handleUpdate = (data, index, eleID) => {
    if (clicked) {
      return
    }
    if (data.calculations.passing) {
      const url = linkGenerator(article)
      if (eleID && history) {
        history.replaceState(null, "", `${url.replace(/\/$/, "")}#${eleID}`)
        setCurrentSection && setCurrentSection(eleID)
      }
    }
  }

  const hasAbstract =
    !isLegalPage &&
    !isLanding &&
    article?.data?.abstract?.length > 0 &&
    article?.data?.abstract[0]?.abstract_item?.html !== null

  return (
    <section
      ref={stickyCTARef}
      className={isLegalPage ? "article-content legal-page" : "article-content"}
    >
      {isGuide &&
        showPopup &&
        createPortal(<ReviewPopup mobile />, document.body)}
      <div className="article-content__left">
        <div className="article-content__left--slices">
          {isLanding && (
            <div className="hero__content--breadcrumbs">
              {/* Reuse hero class to get same style as guide */}
              <ArticleBreadcrumb
                super_parent_category={super_parent_category}
                parent_category={parent_category}
                category={category}
                subcategory={subcategory}
                article={article}
              />
            </div>
          )}
          <ArticleSummary
            article={article}
            articleSlices={articleSlices}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            clicked={clicked}
            setClicked={setClicked}
            hasAnFAQ={hasAnFAQ}
            mobile
          />
          {hasAbstract && (
            <div className="article-abstract">
              <Abstract
                abstract={article?.data?.abstract}
                articles={articles}
                enableArticleDownload={isGuide}
              />
            </div>
          )}
          {!hasAbstract && isGuide && (
            <div className="abstract no-abstract">
              <div className="article-abstract">
                <DownloadButton />
              </div>
            </div>
          )}
          {!isLegalPage && <IntroductionToBF />}
          {!isLanding && article?.data?.introduction && (
            <div className="article-introduction">
              <PrismicRichText
                field={article.data.introduction.richText}
                components={(type, element, content, children, index) =>
                  htmlSerializer(
                    type,
                    element,
                    content,
                    children,
                    index,
                    articles
                  )
                }
              />
            </div>
          )}
          <FinalSlicesMap
            articles={articles}
            article={article}
            finalSlices={finalSlices}
            handleUpdate={handleUpdate}
          />
          {hasAnFAQ && (
            <FAQSections
              faqSections={faqSections}
              articles={articles}
              handleUpdate={handleUpdate}
            />
          )}
          {!isLegalPage && !isLanding && (
            <StickyOnRight
              stickyCTARef={stickyCTARef}
              mobile
              isLocalGuide={isLocalGuide}
              city={article?.data?.associated_city}
            />
          )}
          {!isLegalPage && !isLanding && (
            <StaticCTA type={type} city={article?.data?.associated_city} />
          )}
          {hasSources && <ArticleSources sources={sourcesSection} />}
        </div>
        {!isLegalPage && !isLanding && (
          <CompleteSharing
            link={linkGenerator(article)}
            emailTitle={article.data.title.text}
          />
        )}
        {hasAnAuthor && (
          <ArticleAuthor author={article?.data?.author?.document?.data} />
        )}
        {!isLegalPage && displayNewsletter && (
          <NewsletterBig
            asyncScriptOnLoad={asyncScriptOnLoad}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            handleSubmitNewsletter={handleSubmitNewsletter}
            captchaLoader={captchaLoader}
            validatorError={validatorError}
            loading={loading}
            isSubscribed={isSubscribed}
          />
        )}
      </div>

      <div className="article-content__right">
        <ArticleSummary
          article={article}
          articleSlices={articleSlices}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          clicked={clicked}
          setClicked={setClicked}
          hasAnFAQ={hasAnFAQ}
        />
        {!isLegalPage && !isLanding && (
          <StickyOnRight
            stickyCTARef={stickyCTARef}
            isLocalGuide={isLocalGuide}
            city={article?.data?.associated_city}
          />
        )}
        {isGuide && <ReviewPopup />}
        {!isLegalPage && isLanding && (
          <LandingStickyCTA
            stickyCTARef={stickyCTARef}
            articleType={article?.type}
            stickyVisual={stickyVisual}
            link={link}
            CTAContent={article.data.visual_cta_content}
            subProducts={hasSubProducts && article.data.sub_products}
            linksList={links.linksList}
          />
        )}
      </div>
    </section>
  )
}

export default SlicesBlock
