import React, { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"

import Button from "@/components/common/main-components/Button"

import usePopup from "@/utils/usePopup"

const ReviewPopup = ({ mobile = false }) => {
  const { progress, getWithExpiry, setWithExpiry } = usePopup()
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (progress >= 50 && getWithExpiry("reviewPopup", true) !== "closed") {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const handleClosePopup = (source) => {
    setWithExpiry("reviewPopup", "closed", source)
    setShowPopup(false)
  }

  return (
    <>
      <div
        className={`review-popup-dimmer ${showPopup ? "" : "hide"} ${
          mobile ? "mobile" : "desktop"
        }`}
        onClick={() => handleClosePopup("close")}
      ></div>
      <div
        className={`review-popup ${showPopup ? "" : "hide"} ${
          mobile ? "mobile" : "desktop"
        }`}
      >
        <div className="review-popup__content">
          <div
            className="review-popup__content--close"
            onClick={() => handleClosePopup("close")}
          >
            <Icon name="close" />
          </div>
          <p className="review-popup__content--title">
            👋&nbsp;&nbsp;On a besoin de vous&nbsp;!
          </p>
          <p className="review-popup__content--description">
            Si vous appréciez notre contenu, un avis sur Google nous aiderait
            énormément !
          </p>
          <p className="review-popup__content--stars">⭐⭐⭐⭐⭐</p>
          <Button
            content="Donner mon avis"
            icon="arrow right"
            link={"https://g.page/r/CSzkvWFwJKA4EB0/review"}
            onClick={() => handleClosePopup("button")}
          />
        </div>
      </div>
    </>
  )
}

export default ReviewPopup
