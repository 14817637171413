import React from "react"

import {
  NEWS,
  CONTRACTS_HOME,
} from "@/components/common/content-components/utils/consts"
import {
  NewsBreadcrumbs,
  NoParentBreadcrumbs,
  OneParentBreadcrumbs,
  TwoParentsBreadcrumbs,
  ThreeParentsBreadcrumbs,
  FourParentsBreadcrumbs,
  ContractsBreadcrumbs,
  LocalGuideBreadcrumbs,
} from "@/components/common/content-components/breadcrumbs/BreadcrumbsTypes"

const ArticleBreadcrumb = ({
  super_parent_category,
  parent_category,
  category,
  subcategory,
  article,
  isLocal = false,
}) => {
  const zeroParent = !subcategory
  const oneParent =
    !super_parent_category && !parent_category && !category && subcategory
  const twoParents =
    !super_parent_category && !parent_category && category && subcategory
  const threeParents =
    !super_parent_category && parent_category && category && subcategory
  const fourParents =
    super_parent_category && parent_category && category && subcategory

  if (article?.type === NEWS) {
    return <NewsBreadcrumbs article={article} />
  }

  if (article?.type === CONTRACTS_HOME) {
    return <ContractsBreadcrumbs />
  }

  if (isLocal) {
    return <LocalGuideBreadcrumbs article={article} />
  }

  if (zeroParent) {
    return <NoParentBreadcrumbs article={article} />
  } else if (oneParent) {
    return <OneParentBreadcrumbs article={article} subcategory={subcategory} />
  } else if (twoParents) {
    return (
      <TwoParentsBreadcrumbs
        article={article}
        subcategory={subcategory}
        category={category}
      />
    )
  } else if (threeParents) {
    return (
      <ThreeParentsBreadcrumbs
        article={article}
        subcategory={subcategory}
        category={category}
        parent_category={parent_category}
      />
    )
  } else if (fourParents) {
    return (
      <FourParentsBreadcrumbs
        article={article}
        subcategory={subcategory}
        category={category}
        parent_category={parent_category}
        super_parent_category={super_parent_category}
      />
    )
  }
}

export default ArticleBreadcrumb
