import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { Grid } from "semantic-ui-react"

import { Media } from "@/lib/Media"
import usePageViewTracker from "@/lib/hooks/usePageViewTracker"

import SiteWrapper from "@/components/layout/SiteWrapper"
import { SearchBarModal } from "@/components/guides"
import { HomeSlices } from "@/components/guides/home"
import Meta from "@/components/layout/Meta"
import BasicBreadcrumbs from "@/components/common/content-components/breadcrumbs/BasicBreadcrumbs"

import CustomImageData from "@/utils/CustomImageData"
import CustomHeader from "@/utils/CustomHeader"
import useLocationHash from "@/utils/useLocationHash"

import "@/assets/styles/documents_homepage.scss"

export const query = graphql`
  {
    allFile {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              jpgOptions: { quality: 100 }
              pngOptions: { quality: 100 }
            )
          }
          relativePath
          extension
          publicURL
        }
      }
    }
    prismicAccueilDuGuide {
      _previewable
      data {
        og_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
          alt
        }
        body {
          ... on PrismicAccueilDuGuideDataBodyEncadre {
            items {
              internal_link {
                link_type
                document {
                  ... on PrismicLandingPage {
                    url
                    uid
                    type
                  }
                  ... on PrismicGuideArticle {
                    url
                    uid
                    type
                  }
                }
              }
              link_title {
                text
              }
            }
            slice_type
            primary {
              section_title {
                text
              }
              anchor
            }
          }
          ... on PrismicAccueilDuGuideDataBodyTitreH2 {
            primary {
              block_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicAccueilDuGuideDataBodyCtaNatif {
            id
            slice_type
            primary {
              native_cta_block {
                isBroken
                document {
                  ... on PrismicNativeCta {
                    id
                    data {
                      cta_bullet_1 {
                        richText
                        text
                      }
                      cta_bullet_2 {
                        richText
                        text
                      }
                      cta_bullet_3 {
                        richText
                        text
                      }
                      cta_text {
                        text
                        richText
                      }
                      cta_title {
                        text
                        richText
                      }
                      cta_visual {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        og: gatsbyImageData(layout: FIXED, width: 1200)
                        alt
                      }
                      cta_internal_link {
                        url
                        uid
                        type
                        document {
                          ... on PrismicGuideArticle {
                            url
                            uid
                            type
                          }
                          ... on PrismicLandingPage {
                            url
                            uid
                            type
                          }
                        }
                      }
                      has_seo_value
                    }
                  }
                }
              }
            }
          }
        }
        meta_description
        meta_title
        canonical_url {
          url
        }
      }
    }
  }
`

const GuideHomePageTemplate = (props) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window?.scrollTo({ top: 0 })
      setIsMobile(window.innerWidth < 768)
    }
  }, [])

  usePageViewTracker("landingpage", "home_guides")

  const { section } = useLocationHash()

  const slices = props.data.prismicAccueilDuGuide.data.body
  const imagesDatas = props.data.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <SiteWrapper noPadding={true} archivePage>
      <Grid className="homepage">
        <Grid.Row style={{ padding: 0 }}>
          <BasicBreadcrumbs content="Guide du bailleur" leftAligned />
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column>
            <Grid style={{ justifyContent: "center" }}>
              <Grid.Row>
                <Grid.Column style={{ padding: 0 }}>
                  <CustomHeader
                    size="h1"
                    header="Le guide de la gestion locative"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ padding: 0 }}>
                  <div className="searchbar-container">
                    <div className="searchbar-background">
                      <div className="searchbar-content">
                        <p className="searchbar-title">
                          Comment pouvons-nous vous aider ?
                        </p>
                        <SearchBarModal guideSearch mobile={isMobile} />
                      </div>
                      <Media
                        greaterThanOrEqual="tablet"
                        className="searchbar-background"
                      >
                        <CustomImageData
                          data={imagesDatas}
                          path="guides/background-search-bar.jpg"
                          alt="Guide gestion locative"
                        />
                      </Media>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <div className="slices">
            {slices && <HomeSlices slices={slices} currentSlug={section} />}
          </div>
        </Grid.Row>
      </Grid>
    </SiteWrapper>
  )
}

export default withPrismicPreview(GuideHomePageTemplate)

export const Head = ({ data }) => {
  const homeguide = data.prismicAccueilDuGuide

  const canonicalUrl = homeguide?.data?.canonical_url?.url

  return (
    <Meta
      title={homeguide.data.meta_title}
      description={homeguide.data.meta_description}
      canonicalUrl={canonicalUrl}
      structuredData={[
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Accueil",
              item: "https://www.bailfacile.fr",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "Guide du propriétaire",
              item: "https://www.bailfacile.fr/guides",
            },
          ],
        },
      ]}
      currentLocation="/"
      contentType="guides"
      ogImage={homeguide?.data?.og_image?.og?.images?.fallback?.src}
    />
  )
}
