/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { linkResolver } from "./linkResolver"

import ArticlePageTemplate from "@/templates/articlepage"
import NewsArticlePageTemplate from "@/templates/newsarticlepage"
import LandingPageTemplate from "@/templates/landingpage"
import GuideHomePageTemplate from "@/templates/accueil_du_guide"
import DocumentsHomePageTemplate from "@/templates/accueil_documents"
import ContractsHomePageTemplate from "@/templates/accueil_contrats"

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: linkResolver,
    componentResolver: {
      guide_article: ArticlePageTemplate,
      news_articles: NewsArticlePageTemplate,
      landing_page: LandingPageTemplate,
      accueil_du_guide: GuideHomePageTemplate,
      accueil_documents: DocumentsHomePageTemplate,
      accueil_contrats: ContractsHomePageTemplate,
    },
  },
]
