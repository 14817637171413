import { useEffect } from "react"
import smoothScrollIntoView from "./smoothScrollIntoView"

export default function useLocationHash() {
  let href
  let section

  if (typeof window !== "undefined") {
    href = window?.location.href
    section = window?.location.hash.replace("#", "")
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const sectionParam = window?.location.hash?.replace("#", "")
      if (sectionParam) {
        setTimeout(() => {
          smoothScrollIntoView(sectionParam)
        }, 500)
      }
    }
  }, [href])

  return { section }
}
