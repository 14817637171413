import React, { useEffect } from "react"
import { PrismicRichText } from "@prismicio/react"
import { Grid } from "semantic-ui-react"
import clsx from "clsx"

import htmlSerializer from "@/utils/htmlSerializer"
import scrollToElementByHash from "@/utils/scrollToElementByHash"

import {
  Image,
  Box,
  NativeCTA,
  Quote,
  DocumentExample,
  VideoEmbed,
} from "@/components/slices"
import Button from "@/components/common/main-components/Button"
import NewsletterSmall from "@/components/common/content-components/NewsletterSmall"

import { useNewsletterContext } from "@/lib/context/NewsletterContext"

// Sort and display the different slice options
export default ({ slices, articles }) => {
  const {
    asyncScriptOnLoad,
    handleChange,
    handleKeyDown,
    handleSubmitNewsletter,
    captchaLoader,
    validatorError,
    loading,
    isSubscribed,
    displayNewsletter,
  } = useNewsletterContext()

  useEffect(() => {
    scrollToElementByHash()
  }, [])

  const res = (slice, index) => {
    switch (slice.slice_type) {
      case "image":
        return (
          <div key={`slice-${index}`} style={{ marginBottom: "30px" }}>
            <Image slice={slice} style={{ margin: "1em 0" }} />
          </div>
        )

      case "citation":
        return (
          <Quote key={`slice-${index}`} slice={slice} articles={articles} />
        )

      case "box":
        return <Box key={`slice-${index}`} slice={slice} articles={articles} />

      case "cta_natif":
        return slice?.primary?.native_cta_block?.isBroken === false ? (
          <NativeCTA key={`slice-${index}`} slice={slice} />
        ) : (
          <></>
        )

      case "body":
        return (
          <div className="article-paragraph">
            <PrismicRichText
              key={`slice-${index}`}
              field={slice.primary.body_text.richText}
              components={(type, element, content, children, index) =>
                htmlSerializer(
                  type,
                  element,
                  content,
                  children,
                  index,
                  articles
                )
              }
            />
          </div>
        )

      case "texte_general":
        return (
          <div className="article-paragraph">
            <PrismicRichText
              key={`slice-${index}`}
              field={slice.primary.body_text.richText}
              components={(type, element, content, children, index) =>
                htmlSerializer(
                  type,
                  element,
                  content,
                  children,
                  index,
                  articles
                )
              }
            />
          </div>
        )

      case "exemple_d_un_document":
        return (
          <DocumentExample slice={slice} key={`slice-${index}`} index={index} />
        )

      case "contenu_html":
        return (
          <div className="article-html">
            <div
              key={`slice-${index}`}
              style={{ padding: 10 }}
              dangerouslySetInnerHTML={{
                __html: slice.primary.html_content.text,
              }}
            />
          </div>
        )

      case "video":
        return <VideoEmbed slice={slice} key={`slice-${index}`} index={index} />

      case "cta_simple":
        return (
          <div className="article-simple-cta">
            <Button
              content={
                slice?.primary?.simple_cta_button?.document?.data
                  ?.simple_cta_text
              }
              link={
                slice?.primary?.simple_cta_button?.document?.data
                  ?.simple_cta_link?.url
              }
              icon="arrow right"
            />
          </div>
        )

      case "newsletter":
        return displayNewsletter ? (
          <NewsletterSmall
            key={`slice-${index}`}
            asyncScriptOnLoad={asyncScriptOnLoad}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            handleSubmitNewsletter={handleSubmitNewsletter}
            captchaLoader={captchaLoader}
            validatorError={validatorError}
            loading={loading}
            isSubscribed={isSubscribed}
          />
        ) : (
          <></>
        )

      default:
        return
    }
  }

  return slices.map((slice, index) => {
    return (
      <Grid.Row
        key={`slice-${index}`}
        className={clsx("article-slice-wrapper", {
          "action-button": slice.slice_type === "call_to_action__button_",
          "": slice.slice_type !== "call_to_action__button_",
        })}
      >
        <Grid.Column width={16} style={{ width: "100%" }}>
          {res(slice, index)}
        </Grid.Column>
      </Grid.Row>
    )
  })
}
