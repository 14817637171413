import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { Menu, Icon, Grid, Sidebar } from "semantic-ui-react"

import { SearchBarModal } from "@/components/guides"
import {
  featuresDataForMobile,
  contractsDataForMobile,
  guideDataForMobile,
  forWhoDataForMobile,
} from "@/components/layout/nav/utils/navDatas"
import LogoImage from "@/components/layout/nav/components/LogoImage"
import Button from "@/components/common/main-components/Button"
import SmartLink from "@/components/common/utils/SmartLink"

import { WEB_APP_URL } from "@/lib/constants"

const navCategories = [
  featuresDataForMobile, // Fonctionnalités
  forWhoDataForMobile, // Pour qui ?
  contractsDataForMobile, // Contrats
  guideDataForMobile, // Conseils
]

const MobileNavMenu = ({
  data,
  isAuthenticated,
  hideMenu,
  isLocal,
  cityAssociated,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [toggleClicked, setToggleClicked] = useState({
    animated: false,
    value: "sidebar",
  })
  const [choice, setChoice] = useState(null)

  const handleToggleSidebar = (e) => {
    setToggleClicked({
      animated: true,
      value: toggleClicked.value === "sidebar" ? "close" : "sidebar",
    })
    const { target } = e
    target.style.transform =
      toggleClicked.value === "sidebar" ? "rotate(180deg)" : "rotate(-180deg)"
    target.style.transition = "transform 0.2s ease-in-out"
    setSidebarOpen(!sidebarOpen)

    setTimeout(() => {
      setToggleClicked({
        animated: false,
        value: toggleClicked.value === "sidebar" ? "close" : "sidebar",
      })
    }, 200)
  }

  const handleToggleCategory = (e, category) => {
    if (choice && choice.id === category) {
      setChoice(null)
    } else {
      const currentCategory = navCategories.filter((cat) => cat.id === category)
      setChoice(currentCategory[0])
    }
  }

  return (
    <Grid.Column className="mobile-nav-main-column">
      <Menu size="small" borderless secondary className="mobile-nav-menu">
        <Menu.Item className="mobile-nav-menu__item">
          <SmartLink
            link={{
              to: "/",
              hidden: true,
              external: false,
            }}
          >
            <LogoImage data={data} />
          </SmartLink>
        </Menu.Item>
        {!hideMenu && (
          <React.Fragment>
            <Menu.Item
              fitted={"horizontally"}
              position="right"
              className="mobile-nav-menu__item"
            >
              <Menu.Item className="search">
                <SearchBarModal mobile />
              </Menu.Item>
              <Menu.Item
                className="sidebar"
                onClick={handleToggleSidebar}
                as="button"
                aria-label="Ouverture du menu principal"
              >
                <Icon
                  size="large"
                  name={toggleClicked.value}
                  className={toggleClicked.animated ? "animated" : "static"}
                />
              </Menu.Item>
            </Menu.Item>
          </React.Fragment>
        )}
      </Menu>

      <Sidebar
        as={Menu}
        animation="overlay"
        direction="bottom"
        visible={sidebarOpen}
        vertical
        className="mobile-nav-sidebar"
      >
        <nav className="sidebar">
          <ul className="sidebar__list">
            {navCategories.map((category) => {
              return (
                <li
                  key={category.id}
                  className={`category ${category.id} ${
                    choice?.id === category.id ? "active" : ""
                  }`}
                  onClick={(e) => handleToggleCategory(e, category.id)}
                >
                  <button className={`category__mobileTitle`}>
                    <span className="mobileTitle">{category.title}</span>
                    <Icon size="small" name="chevron right" />
                  </button>

                  {choice && choice.id === category.id && (
                    <ul className="category__list">
                      {choice.id === "documents" && (
                        <li
                          key={"all-documents"}
                          className="category__list--item"
                        >
                          <Link to={"/documents"}>
                            <Icon
                              name={"long arrow alternate right"}
                              style={{
                                color: "#FFAD4A",
                                marginRight: "10px",
                              }}
                              size="small"
                            />
                            <span style={{ fontWeight: "bold" }}>
                              Voir tous les documents
                            </span>
                          </Link>
                        </li>
                      )}
                      {choice.items.map((item) => {
                        return (
                          <li key={item.link} className="category__list--item">
                            <Link to={item.link}>
                              <Icon
                                name={item.icon}
                                style={{
                                  color: "#FFAD4A",
                                  marginRight: "10px",
                                }}
                                size="small"
                              />
                              <span>{item.text}</span>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
            <li
              key={"pricing"}
              className={`category pricing`}
              onClick={() => navigate("/tarifs")}
            >
              <button className={`category__mobileTitle`}>
                <span className="mobileTitle">Tarifs</span>
                <Icon size="small" name="chevron right" />
              </button>
            </li>
          </ul>

          <div className="sidebar__buttons">
            <ul className="list">
              {!isAuthenticated ? (
                <>
                  <li key={`login-button`} className="list__button">
                    <Button
                      content={"Se connecter"}
                      link={`${WEB_APP_URL}/connexion`}
                      navButton
                      navClass={"secondary"}
                    />
                  </li>
                  <li key={`try-button`} className="list__button">
                    <Button
                      content={
                        isLocal
                          ? "Tester 3 mois pour 1€"
                          : "Essayer gratuitement"
                      }
                      link={
                        isLocal
                          ? `${WEB_APP_URL}/inscription?s=cta-page-local&ville=${cityAssociated}`
                          : `${WEB_APP_URL}/inscription`
                      }
                      navButton
                      navClass={"primary"}
                    />
                  </li>
                </>
              ) : (
                <li key={`gotoapp-button`} className="list__button gotoapp">
                  <Button
                    content="Espace de gestion"
                    icon={"user"}
                    link={`${WEB_APP_URL}/gestion`}
                    navButton
                  />
                </li>
              )}
            </ul>
          </div>
        </nav>
      </Sidebar>
    </Grid.Column>
  )
}

export default MobileNavMenu
