import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  connectHits,
  connectSearchBox,
} from "react-instantsearch-dom"
import { Grid, Input, Icon, Card, Segment } from "semantic-ui-react"

import { Media } from "@/lib/Media"

const algoliaClient = algoliasearch(
  "VJ3ZKUDU30",
  "5407b5eebc10a4d3f36d8419f337b9e8"
)

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

const Search = ({ guideSearch, mobile }) => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_GUIDE_INDEX}
    >
      <ActionSearchBox autoFocus guideSearch={guideSearch} mobile={mobile} />
    </InstantSearch>
  )
}

const SearchBox = ({ currentRefinement, refine, guideSearch, mobile }) => {
  const [focus, setFocus] = useState(false)
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  function getInputSize() {
    if (mobile) {
      if (guideSearch) {
        return "massive"
      } else {
        return "big"
      }
    } else {
      return "massive"
    }
  }

  return (
    <Grid className="search card-with-shadow">
      <Grid.Row centered style={{ padding: "0px" }}>
        <Grid.Column
          computer={guideSearch ? 10 : 16}
          tablet={guideSearch ? 12 : 16}
          mobile={guideSearch ? 14 : 16}
          className="searchBarActions-main-column"
          style={{ padding: mobile && "0px" }}
        >
          <Input
            ref={inputRef}
            className={`searchBarActions ${guideSearch ? "guideSearch" : ""}`}
            style={{ width: "100%", marginBottom: "1px" }}
            icon={<Icon name="search" style={{ color: "#001D51" }} />}
            iconPosition="left"
            size={getInputSize()}
            placeholder={
              mobile
                ? "Ex : Réaliser un état des lieux"
                : "Ex : Comment réaliser un état des lieux ?"
            }
            value={currentRefinement}
            onChange={(event) => {
              if (!focus) {
                setFocus(true)
              }
              refine(event.currentTarget.value)
            }}
            onFocus={() => setFocus(true)}
          />
        </Grid.Column>
      </Grid.Row>

      {focus && <CustomHits setFocus={setFocus} mobile={mobile} />}
    </Grid>
  )
}

const Hits = ({ hits, mobile }) => {
  hits.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })
  const truncatedHits = hits.length > 9 ? hits.splice(0, 9) : hits

  return (
    <Grid.Row
      centered
      className="searchBar scroll-bar-layout"
      style={{
        position: "absolute",
        zIndex: 999,
        paddingTop: "0px",
      }}
    >
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        style={{ padding: mobile && "0px" }}
      >
        {truncatedHits.length > 0 && (
          <Segment className="global-search-bar-segment">
            <Grid className="dimmer-menu" padded="horizontally">
              <Grid.Row>
                <Grid.Column width={16}>
                  <Media greaterThanOrEqual="largeScreen">
                    <Card.Group itemsPerRow={3}>
                      {truncatedHits.map((edge, index) => (
                        <CategoryCard
                          key={"category-guide-" + index}
                          hit={edge}
                        />
                      ))}
                    </Card.Group>
                  </Media>
                  <Media between={["tablet", "largeScreen"]}>
                    <Card.Group itemsPerRow={2}>
                      {truncatedHits.map((edge, index) => (
                        <CategoryCard
                          key={"category-guide-" + index}
                          hit={edge}
                        />
                      ))}
                    </Card.Group>
                  </Media>

                  <Media lessThan="tablet">
                    <Card.Group itemsPerRow={1}>
                      {truncatedHits.map((edge, index) => (
                        <CategoryCard
                          key={"category-guide-" + index}
                          hit={edge}
                        />
                      ))}
                    </Card.Group>
                  </Media>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

const CategoryCard = ({ hit }) => {
  const CHAR_LIMIT = 90
  if (hit?.label === "Documents") {
    return (
      <>
        <Card
          style={{ textAlign: "left" }}
          onClick={(e) => {
            e.preventDefault()
            navigate(`/${hit?.url}`)
          }}
          link
          as="a"
          href={`/${hit?.url}`}
        >
          <Card.Content className="global-search-card-content">
            <div
              style={{
                width: "80px",
                height: "41px",
                float: "left",
                paddingRight: "3px",
                paddingBottom: "22%",
              }}
              className="global-search-image"
            >
              <img
                src={hit?.thumbnail_url}
                style={{
                  width: "inherit",
                  height: "inherit",
                }}
              />
              <div className="globalBar-label">{hit?.label}</div>
            </div>
            <div className="global-search-mobile-view">
              <span className="global-search-bar-category-title">
                {hit?.title}
              </span>

              <Card.Description
                className="global-search-bar-category-description"
                style={{ color: " rgba(0, 0, 0, 0.68)" }}
              >
                {hit?.description && hit?.description.substr(0, CHAR_LIMIT)}
                {hit?.description && hit?.description.length > CHAR_LIMIT
                  ? `...`
                  : ``}
              </Card.Description>
            </div>
          </Card.Content>
        </Card>
      </>
    )
  }
  return (
    <>
      <Card
        style={{ textAlign: "left" }}
        onClick={(e) => {
          e.preventDefault()
          navigate(`/${hit?.url}`)
        }}
        link
        as="a"
        href={`/${hit?.url}`}
      >
        <Card.Content className="global-search-card-content">
          <div
            style={{
              width: "80px",
              height: "41px",
              float: "left",
              paddingRight: "3px",
              paddingBottom: "22%",
            }}
            className="global-search-image"
          >
            <img
              src={hit?.thumbnail_url}
              style={{
                width: "inherit",
                height: "inherit",
              }}
            />
            <div className="globalBar-label">{hit?.label}</div>
          </div>
          <div className="global-search-mobile-view">
            <span className="global-search-bar-category-title">
              {hit?.title}
            </span>

            <Card.Description
              className="global-search-bar-category-description"
              style={{ color: " rgba(0, 0, 0, 0.68)" }}
            >
              {hit?.description && hit?.description.substr(0, CHAR_LIMIT)}
              {hit?.description && hit?.description.length > CHAR_LIMIT
                ? `...`
                : ``}
            </Card.Description>
          </div>
        </Card.Content>
      </Card>
    </>
  )
}

const CustomHits = connectHits(Hits)
const ActionSearchBox = connectSearchBox(SearchBox)

export default Search
