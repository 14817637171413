import React from "react"
import { Sticky } from "semantic-ui-react"

import StickyVisual from "@/components/common/sticky-components/StickyVisual"
import {
  CONTRACTS_HOME,
  LANDING,
} from "@/components/common/content-components/utils/consts"
import {
  SubProductsStickyDropdown,
  useSubproductsDropdown,
} from "@/components/landingpages/hero/SubProductsDropdown"

const LandingStickyCTA = ({
  stickyCTARef,
  articleType,
  stickyVisual = null,
  link = null,
  CTAContent = null,
  subProducts = [],
  linksList = [],
}) => {
  const { showDropdown, setShowDropdown } = useSubproductsDropdown()

  const hasSubProducts = subProducts && subProducts.length > 0

  const isLandingPage =
    articleType === LANDING || articleType === CONTRACTS_HOME

  if (isLandingPage) {
    return (
      <>
        {hasSubProducts ? (
          <span
            onClick={() => setShowDropdown(!showDropdown)}
            style={{ cursor: "pointer" }}
          >
            <Sticky context={stickyCTARef} offset={50}>
              <StickyVisual
                visual={stickyVisual}
                link={link}
                CTAContent={CTAContent}
              />
            </Sticky>
            {showDropdown && (
              <SubProductsStickyDropdown
                subProducts={subProducts}
                linksList={linksList}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
              />
            )}
          </span>
        ) : (
          <Sticky context={stickyCTARef} offset={50}>
            <StickyVisual
              visual={stickyVisual}
              link={link}
              CTAContent={CTAContent}
            />
          </Sticky>
        )}
      </>
    )
  }
}

export default LandingStickyCTA
