import React from "react"
import { Link } from "gatsby"
import { Icon } from "semantic-ui-react"

import linkGenerator from "@/utils/linkGenerator"

const ContentLink = ({
  element,
  content,
  index,
  article,
  style,
  className,
  disableAnchorTag,
  ...rest
}) => {
  let url, target

  target =
    element && element?.data?.target
      ? { target: element.data.target, rel: "noopener" }
      : {}

  if ((article && article === "contrats") || article === "documents") {
    url = `/${article}`
  }

  if (
    element &&
    element?.data?.link_type === "Document" &&
    article &&
    article !== "contrats" &&
    article !== "documents"
  ) {
    url = linkGenerator(article)
  }

  if (style) {
    return element ? (
      element?.data?.link_type === "Document" ? (
        <span>
          <Icon
            name="chevron right"
            style={{
              color: "rgba(0,0,0,.6)",
              lineHeight: "2.9",
            }}
          />

          <Link
            to={url}
            key={index}
            style={{ color: "rgba(0,0,0,.7)", fontSize: "16px" }}
          >
            {content}
          </Link>
        </span>
      ) : disableAnchorTag ? (
        <span
          onClick={() => {
            element?.data?.target === "_blank"
              ? window.open(element?.data?.url)
              : (window.location = element?.data?.url)
          }}
          key={index}
          className={["link", className].join(" ")}
          {...rest}
        >
          {content}
        </span>
      ) : (
        <a
          href={element?.data?.url}
          {...target}
          key={index}
          className={className}
          {...rest}
        >
          {content}
        </a>
      )
    ) : null
  }

  return element ? (
    element?.data?.link_type === "Document" ? (
      <Link
        to={article ? url : `/${element?.data?.uid}`}
        key={index}
        className={className}
        {...rest}
      >
        {content}
      </Link>
    ) : disableAnchorTag ? (
      <span
        onClick={() => {
          element?.data?.target === "_blank"
            ? window.open(element?.data?.url)
            : (window.location = element?.data?.url)
        }}
        key={index}
        className={["link", className].join(" ")}
        {...rest}
      >
        {content}
      </span>
    ) : (
      <a
        href={element?.data?.url}
        {...target}
        key={index}
        className={className}
        {...rest}
      >
        {content}
      </a>
    )
  ) : null
}

export default ContentLink
