import React, { useEffect, useState } from "react"
import { useChat } from "react-live-chat-loader"

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== "undefined" ? window.innerHeight : 0
  )

  const handleResize = () => setWindowHeight(window.innerHeight)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowHeight(window.innerHeight)
      window.addEventListener("resize", handleResize)
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  return windowHeight
}

const styles = {
  wrapper: {
    borderRadius: "55px",
    height: "55px",
    width: "104px",
    bottom: "40px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 7px",
    position: "fixed",
    right: "40px",
    top: "auto",
    borderStyle: "none",
    transition:
      "box-shadow 250ms ease 0s, opacity 0.4s ease 0s, transform 0.2s ease-in-out 0s",
  },
  button: {
    appearance: "none",
    alignItems: "center",
    bottom: "0px",
    display: "block",
    justifyContent: "center",
    position: "relative",
    userSelect: "none",
    zIndex: 999,
    color: "white",
    cursor: "pointer",
    minWidth: "60px",
    WebkitTapHighlightColor: "transparent",
    height: "55px",
    lineHeight: "55px",
    borderRadius: "200px",
    margin: "0px",
    outline: "none",
    padding: "0px",
    borderStyle: "none",
    transition: "background-color 200ms linear 0s, transform 200ms linear 0s",
  },
  icon: {
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    WebkitBoxPack: "center",
    justifyContent: "center",
    pointerEvents: "none",
    position: "absolute",
    textIndent: "-99999px",
    top: "0px",
    width: "60px",
    willChange: "opacity, transform",
    left: "0px",
    right: "0px",
    opacity: "1 !important",
    transition: "opacity 80ms linear 0s, transform 160ms linear 0s",
  },
  text: {
    color: "white",
    display: "block",
    fontSize: "14px",
    fontWeight: "600",
    padding: "0px 20px 0px 54px",
    whiteSpace: "nowrap",
  },
  close: {
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    WebkitBoxPack: "center",
    justifyContent: "center",
    pointerEvents: "none",
    position: "absolute",
    textIndent: "-99999px",
    top: "0px",
    width: "60px",
    willChange: "opacity, transform",
    left: "0px",
    right: "0px",
    opacity: "1 !important",
    transition: "opacity 80ms linear 0s, transform 160ms linear 0s",
  },
}

const getIcon = (icon) => {
  switch (icon) {
    case "message":
      return (
        <svg width="24" height="22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.347 20.871l-.003-.05c0 .017.001.034.003.05zm-.243-4.278a2 2 0 0 1 .513-1.455c1.11-1.226 1.383-2.212 1.383-4.74C22 5.782 18.046 2 13.125 2h-2.25C5.954 2 2 5.78 2 10.399c0 4.675 4.01 8.626 8.875 8.626h2.25c.834 0 1.606-.207 3.212-.798a2 2 0 0 1 1.575.083l2.355 1.161-.163-2.878zM10.875 0h2.25C19.13 0 24 4.656 24 10.399c0 2.6-.25 4.257-1.9 6.08l.243 4.279c.072.845-.807 1.471-1.633 1.162l-3.682-1.816c-1.212.446-2.527.921-3.903.921h-2.25C4.869 21.025 0 16.142 0 10.4 0 4.656 4.869 0 10.875 0z"
            fill="#FFF"
          />
        </svg>
      )
    case "close":
    default:
      return (
        <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.707.293a.999.999 0 0 0-1.414 0L7 5.586 1.707.293A.999.999 0 1 0 .293 1.707L5.586 7 .293 12.293a.999.999 0 1 0 1.414 1.414L7 8.414l5.293 5.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L8.414 7l5.293-5.293a.999.999 0 0 0 0-1.414"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      )
  }
}

const HelpScout = ({
  color = "#976ad4",
  icon = "message",
  zIndex = "1050",
  horizontalPosition = "left",
  iconText = null,
}) => {
  const [state, loadChat] = useChat({ loadWhenIdle: true })
  const windowHeight = useWindowHeight()
  const [positionStyles, setPositionStyles] = useState({
    opacity: 0,
    visibility: "hidden",
  })

  useEffect(() => {
    if (state === "complete") {
      window.Beacon("config", {
        docsEnabled: false,
        messagingEnabled: true,
        mode: "askFirst",
        enableFabAnimation: false,
        hideFABOnMobile: false,
        hideFABLabelOnMobile: false,
      })
      window.Beacon("navigate", "/")
    }
  }, [state])

  useEffect(() => {
    setPositionStyles({
      bottom: windowHeight < 740 ? "10px" : "40px",
      right:
        horizontalPosition === "left"
          ? "auto"
          : windowHeight < 740
          ? "20px"
          : "40px",
      left:
        horizontalPosition === "right"
          ? "auto"
          : windowHeight < 740
          ? "20px"
          : "40px",
    })
  }, [windowHeight, horizontalPosition])

  if (state === "complete") {
    return null
  }

  return (
    <div
      style={{
        ...styles.wrapper,
        ...positionStyles,
        zIndex: Number(zIndex),
      }}
    >
      <button
        onClick={() => loadChat({ open: true })}
        onMouseEnter={() => loadChat({ open: false })}
        style={{
          ...styles.button,
          backgroundColor: color,
        }}
      >
        <span
          style={{
            ...styles.icon,
            transform:
              state === "initial"
                ? "rotate(0deg) scale(1)"
                : "rotate(30deg) scale(0)",
          }}
        >
          {getIcon(icon)}
        </span>
        <span
          style={{
            ...styles.close,
            opacity: state === "initial" ? 0 : 1,
            transform:
              state === "initial"
                ? "rotate(30deg) scale(0)"
                : "rotate(0deg) scale(1)",
          }}
        >
          {getIcon("close")}
        </span>
        {iconText && (
          <span
            style={{
              ...styles.text,
            }}
          >
            {iconText}
          </span>
        )}
      </button>
    </div>
  )
}

export default HelpScout
