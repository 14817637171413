import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import CustomImageData from "@/utils/CustomImageData"

const NewsletterMessage = ({ validatorError, isSubscribed }) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
                webpOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <div className="message">
      {validatorError === true && (
        <p className="message__error">
          <CustomImageData
            data={data}
            path={"marketing/red-cross-mark.png"}
            alt="S'abonner à la newsletter"
          />
          L’adresse e-mail saisie est invalide, merci de réessayer.
        </p>
      )}
      {validatorError === false && isSubscribed && (
        <p className="message__success">
          <CustomImageData
            data={data}
            path={"marketing/green-check-mark.png"}
            alt="S'abonner à la newsletter"
          />
          Inscription confirmée
        </p>
      )}
    </div>
  )
}

export default NewsletterMessage
