import React from "react"

const VideoEmbed = ({ slice }) => {
  const { html, title } = slice?.primary?.video_link

  const getSrc = (srcReceived) => {
    if (srcReceived) {
      // return video id from iframe src in slice.primary.video_link.html
      const srcSplit = srcReceived.split("src=")
      const srcSplit2 = srcSplit[1].split(" ")
      const srcSplit3 = srcSplit2[0].split('"')
      const srcSplit4 = srcSplit3[1].split('"')
      const srcSplit5 = srcSplit4[0].split("?")
      const srcSplit6 = srcSplit5[0].split("/")
      const srcSplit7 = srcSplit6[srcSplit6.length - 1]
      return `https://www.youtube.com/embed/${srcSplit7}`
    }
  }

  return (
    <div className="video-embed">
      {html && title && (
        <iframe
          className="video-embed__iframe"
          height="auto"
          width="auto"
          src={getSrc(html)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          title={title}
        ></iframe>
      )}
    </div>
  )
}

export default VideoEmbed
