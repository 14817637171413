import React from "react"

import CustomImage from "@/utils/customImage"

import { sendDataLayerEvent } from "@/lib/trackers/events"

import BlueBoxes from "./BlueBoxes"

const HeroMainImage = ({ image, alt, mobile = false }) => {
  return (
    <div
      className="main-landing-image"
      onClick={() =>
        sendDataLayerEvent("click", "cta-landingpages", "cta-button")
      }
    >
      <CustomImage
        image={image}
        alt={alt}
        customWidth={500}
        customHeight={500}
      />
      {mobile && <BlueBoxes />}
    </div>
  )
}

export default HeroMainImage
