import { navigate } from "gatsby"

import linkGenerator from "@/utils/linkGenerator"
import smoothScrollIntoView from "@/utils/smoothScrollIntoView"

export function generateAndScroll(
  article,
  slice,
  setCurrentSection,
  isFaq = false,
  setClicked
) {
  const url = linkGenerator(article)
  if (slice) {
    const slug = isFaq ? "faq" : slice?.slug

    navigate(`${url.replace(/\/$/, "")}#${slug}`)
    if (setCurrentSection) {
      setClicked(true)
      setCurrentSection(slug)
    }
    smoothScrollIntoView(slug)

    setTimeout(() => {
      setClicked && setClicked(false)
    }, 1000)
  }
}
