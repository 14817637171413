import React from "react"
import { Breadcrumb } from "semantic-ui-react"

const HomeBreadcrumbSection = ({ content = "Gestion locative en ligne" }) => {
  return (
    <Breadcrumb.Section>
      <a href={`/`}>{content}</a>
    </Breadcrumb.Section>
  )
}

export default HomeBreadcrumbSection
