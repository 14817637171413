import React from "react"

import SlicesBlock from "@/components/common/content-components/blocks/SlicesBlock"
import getArticleParents from "@/components/common/content-components/utils/getArticleParents"

const LandingPageContent = ({ contentData }) => {
  const { article, allContent, isAuthenticated, stickyVisual, CTAlink, type } =
    contentData

  const parents = getArticleParents(article)

  return (
    <>
      <SlicesBlock
        article={article}
        articles={allContent}
        isAuthenticated={isAuthenticated}
        stickyVisual={stickyVisual}
        link={CTAlink}
        type={type}
        parents={parents}
      />
    </>
  )
}

export default LandingPageContent
