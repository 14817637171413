const slugify = require("slugify")

export const sendDataLayerEvent = (action, category, label) => {
  window.dataLayer.push({
    event: "uaevent",
    eventAction: action,
    eventCategory: category,
    eventLabel: slugify(label, {
      replacement: "-",
      lower: true,
    }),
  })
}

export const sendPageViewDataLayerEvent = (
  pageType,
  userId = null,
  product = null
) => {
  window.dataLayer.push({
    event: "uaevent",
    action: "page_view",
    pageType: pageType,
    userId: userId,
    product: product,
  })
}

export const sendPageViews = () => {
  // Helpscout
  if (window && window.Beacon && typeof window.Beacon === "function") {
    window.Beacon("event", {
      type: "page-viewed",
      url: window.location.href,
      title: window.document.title,
    })
  }
}
