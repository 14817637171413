import { useEffect, useState } from "react"

import getScrollHeight from "@/utils/getScrollHeight"

export default function usePopup() {
  const [progress, setProgress] = useState(0)

  const scrollHeight = () => {
    const percent = getScrollHeight()
    setProgress(percent)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHeight)
    return () => window.removeEventListener("scroll", scrollHeight)
  })

  const now = new Date()

  function setWithExpiry(key, value, source) {
    // set to 72 hours
    const ttl = 259200
    let item

    if (source === "close") {
      item = {
        value,
        expiry: now.getTime() + ttl,
      }
    } else if (source === "button") {
      item = {
        value,
      }
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  function getWithExpiry(key, onlyValue = false) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }

    const { value, expiry } = JSON.parse(itemStr)

    if (onlyValue || !expiry) {
      return value
    }

    // compare the expiry time of the item with the current time
    // If the item is expired, delete the item from storage
    if (now.getTime() > expiry) {
      localStorage.removeItem(key)
      return null
    }
    return value
  }

  return { progress, getWithExpiry, setWithExpiry }
}
