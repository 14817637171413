import React from "react"
import { Icon } from "semantic-ui-react"

import Button from "@/components/common/main-components/Button"

const ConfirmationModal = ({ handleModal, email }) => {
  return (
    <div className="download-modal">
      <div className="download-modal__header">
        <Icon name="close" onClick={() => handleModal(false)} />
        <Icon name="check" />
        <p className="download-modal__header--text">Fiche envoyée</p>
      </div>
      <div className="download-modal__content">
        <div className="download-modal__content--header">
          <p className="download-modal__content--header-text confirmation">
            Votre fiche vous attend à l'adresse <span>{email}.</span>
            <br />
            <span>Pensez à vérifier vos spams.</span>
          </p>
        </div>
      </div>
      <div className="download-modal__button">
        <Button
          content="Retourner à l'article"
          icon="arrow right"
          id="newsletter-button"
          onClick={() => handleModal(false)}
        />
      </div>
    </div>
  )
}

export default ConfirmationModal
