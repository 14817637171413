import React from "react"
import { Icon } from "semantic-ui-react"

import getReachedInfo from "@/utils/getReachedInfo"

import "@/assets/styles/main-components.scss"

const TripleSectionTitle = ({ block, index }) => {
  const blockIcon = getReachedInfo(block.icon, "icon")

  return (
    <div className={`block-title ${index}`}>
      <span className={`block-title-icon ${index}`}>
        <Icon name={blockIcon} size="small" />
      </span>
      <p className="block-title-content">{block.title}</p>
    </div>
  )
}

export default TripleSectionTitle
