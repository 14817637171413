import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import CustomImageData from "@/utils/CustomImageData"

const Author = ({ author }) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <div className="article-author">
      <p className="article-author__name">
        <span className="article-author__name--written-by">Rédigé par</span>
        &nbsp;
        {author?.author_linkedin?.url ? (
          <a
            className="article-author__name--with-link"
            href={author.author_linkedin.url}
            target="_blank"
            rel="noreferrer nofollow"
          >
            {author.author_first_name.text} {author.author_name.text}
            &nbsp;
            <CustomImageData
              data={data}
              path="socials/linkedin.svg"
              alt="Linkedin"
              imgStyle={{ width: "16px", height: "16px" }}
            />
          </a>
        ) : (
          <span className="article-author__name--no-link">
            {author.author_first_name.text} {author.author_name.text}
          </span>
        )}
      </p>
      <p className="article-author__description">
        {author.author_description.text}
      </p>
    </div>
  )
}

export default Author
