import React from "react"

import SmartLink from "@/components/common/utils/SmartLink"

import CustomImage from "@/utils/customImage"

import { sendDataLayerEvent } from "@/lib/trackers/events"
import Button from "../main-components/Button"

const StickyVisual = ({ visual, link, CTAContent }) => {
  return (
    <div
      className="stickyVisual"
      onClick={() =>
        sendDataLayerEvent("click", "cta-landingpages", "cta-button")
      }
    >
      {link.link ? (
        <SmartLink link={link.link}>
          <CustomImage image={visual?.gatsbyImageData} alt={visual?.alt} />
          <Button content={CTAContent} />
        </SmartLink>
      ) : (
        <span>
          <CustomImage image={visual?.gatsbyImageData} alt={visual?.alt} />
          <Button content={CTAContent} />
        </span>
      )}
    </div>
  )
}

export default StickyVisual
