import React from "react"
import { PrismicRichText } from "@prismicio/react"
import { Grid } from "semantic-ui-react"

import htmlSerializer from "@/utils/htmlSerializer"

import TripleSectionTitle from "./TripleSectionTitle"

import "@/assets/styles/landingpage.scss"

const MobileTripleSection = ({ tripleSectionData }) => {
  return (
    <Grid className="landingpage-triple-section">
      {tripleSectionData.map((block, i) => {
        return (
          <Grid.Row key={`${i}-${block.title}`} className="block-row">
            <TripleSectionTitle block={block} index={`section${i + 1}`} />
            <div className="block-text">
              <PrismicRichText
                field={block.text}
                components={(type, element, content, children, index) =>
                  htmlSerializer(type, element, content, children, index)
                }
              />
            </div>
          </Grid.Row>
        )
      })}
    </Grid>
  )
}

export default MobileTripleSection
