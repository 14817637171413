const getLinkedArticle = (articles, type, uid, slug) => {
  if (articles) {
    const linkedArticle = articles.filter((item) => {
      if (
        type &&
        (type === "guide_article" ||
          type === "landing_page" ||
          type === "guide_articles_local")
      ) {
        return uid === item?.node?.uid || slug === item?.node?.uid
      }
    })
    return linkedArticle[0]
  }
}

export default getLinkedArticle
