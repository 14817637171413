import React from "react"
import { Link, navigate } from "gatsby"
import clsx from "clsx"

export const HiddenLink = ({ link, children, className, ...rest }) => {
  return (
    <>
      <span
        tabIndex="0"
        role="link"
        className={clsx("smart-link", {
          "": !className,
          [className]: className,
        })}
        onClick={() => {
          if (link.href && link.target) {
            window.open(link.href, "_blank")
          } else if (link.href && !link.target) {
            window.location.assign(link.href, "_blank")
          } else if (link.to) {
            navigate(link.to)
          } else {
            return false
          }
        }}
        {...rest}
      >
        {children}
      </span>
    </>
  )
}

const SmartLink = ({ link, children, className, ariaLabel, ...rest }) => {
  return (
    <>
      {link ? (
        <>
          {link.hidden ? (
            <HiddenLink link={link} className={className} {...rest}>
              {children}
            </HiddenLink>
          ) : (
            <>
              {link.external ? (
                <a
                  href={link.href}
                  target={link.target ? link.target : "_self"}
                  rel="noopener noreferrer"
                  aria-label={ariaLabel}
                  className={clsx("smart-link", {
                    "": !className,
                    [className]: className,
                  })}
                  {...rest}
                >
                  {children}
                </a>
              ) : (
                <Link
                  to={link.to}
                  aria-label={ariaLabel}
                  className={clsx("smart-link", {
                    "": !className,
                    [className]: className,
                  })}
                  {...rest}
                >
                  {children}
                </Link>
              )}
            </>
          )}
        </>
      ) : (
        <> {children} </>
      )}
    </>
  )
}

export default SmartLink
