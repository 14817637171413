import axios from "axios"

const axiosWithoutInterceptors = axios.create()

const setupAxiosHeaders = (axiosInstance) => {
  axiosInstance.defaults.withCredentials = true
}

axios.interceptors.response.use(null, (error) => {
  return Promise.reject(error)
})

setupAxiosHeaders(axios)
setupAxiosHeaders(axiosWithoutInterceptors)

export { axios, axiosWithoutInterceptors }
