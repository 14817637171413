const monthes = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
]

export default function frenchiefyArticleDate(date) {
  let today = new Date(date)
  let year = today.getFullYear()
  let dayNumber = today.getDate()
  let month = monthes[today.getMonth()]

  return `${dayNumber} ${month} ${year}`
}
