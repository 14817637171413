/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = (article) => {
  if (article) {
    if (article?.slug === "accueil-du-guide") {
      return "/guides"
    } else if (article?.slug === "accueil-documents") {
      return "/documents"
    } else if (article?.slug === "accueil-contrats") {
      return "/contrats"
    }

    let path = ""

    if (article.type && article.data && article.uid) {
      if (article?.type === "guide_article") {
        path = `/guides/${article.uid}`
      } else if (article?.type === "guide_articles_local") {
        path = `/gestion-locative/${article.data?.associated_city}/${article.uid}`
      } else if (article?.type === "landing_page") {
        path =
          article?.data?.type === "document"
            ? `/documents/${article.uid}`
            : `/contrats/${article.uid}`
      } else if (article?.type === "landing_page_local") {
        path = `/gestion-locative/${article.uid}`
      } else if (article?.type === "documents") {
        path = `/documents/${article.uid}`
      } else if (article?.type === "contrats") {
        path = `/contrats/${article.uid}`
      } else if (article?.type === "news_articles") {
        path = `/actualites/${article.uid}`
      }
      return path
    }
  }
  return `/`
}
