import React from "react"

const Input = ({
  handleChange,
  handleKeyDown = () => {},
  captchaRef,
  type = "email",
}) => {
  function handleOnFocus(e) {
    const labelholder = e.target.previousSibling
    labelholder.classList.add("toMove")
  }

  function handleOnBlur(e) {
    if (e.target.value === "") {
      const labelholder = e.target.previousSibling
      labelholder.classList.remove("toMove")
    }
  }
  return (
    <div className="custom-input">
      <div className="custom-input__labelholder">Adresse e-mail</div>
      <input
        className="custom-input__input"
        type={type}
        name="email"
        onChange={(e) => handleChange(e, captchaRef)}
        onBlur={(e) => handleOnBlur(e)}
        onFocus={(e) => handleOnFocus(e)}
        onKeyDown={(e) => handleKeyDown(e, captchaRef)}
      />
    </div>
  )
}

export default Input
