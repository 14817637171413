import convertTitleToSlug from "@/utils/convertTitleToSlug"

const getArticleSlices = (article) => {
  const articleSlices = []
  const titles = []
  const faqSections = []
  const sourcesSection = []
  let finalSlices

  if (article.data.body) {
    article.data.body.forEach((slice) => {
      if (slice.slice_type === "titre") {
        titles.push(slice)
      } else if (slice.slice_type === "sources") {
        sourcesSection.push(...slice.items)
      }
    })

    titles.forEach((title, index) => {
      const titleObject = { title, slug: "" }
      if (title?.primary?.lvl2_header?.text) {
        const titleConverted = convertTitleToSlug(
          title.primary.lvl2_header.text,
          index
        )
        titleObject.slug = titleConverted
        articleSlices.push(titleObject)
      }
    })

    finalSlices = article.data.body.reduce((previousValue, currentValue) => {
      if (currentValue !== null && currentValue.slice_type === "titre") {
        previousValue.push({ ...currentValue, slices: [] })
      } else {
        if (previousValue[previousValue.length - 1]) {
          previousValue[previousValue.length - 1].slices.push({
            ...currentValue,
          })
        } else {
          previousValue.push({
            slice_type: currentValue.slice_type,
            slices: [currentValue],
          })
        }
      }

      return previousValue
    }, [])
  }

  if (article.data.body1) {
    article.data.body1.forEach((slice) => {
      if (slice.slice_type === "faq_section") {
        faqSections.push(...slice.items)
      }
    })
  }

  return { articleSlices, finalSlices, faqSections, sourcesSection }
}

export default getArticleSlices
