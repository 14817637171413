const getReachedInfo = (str, type) => {
  let reachedInfo

  if (str && type && type === "icon") {
    reachedInfo = str.split(" / ")[1]
  } else if (str && type && type === "color") {
    const colors = str.split(" - ")[1].split(" / ")
    reachedInfo = { main: colors[0], background: colors[1] }
  }

  return reachedInfo
}

export default getReachedInfo
