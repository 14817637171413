import React from "react"

const NavBlur = ({ setActiveItem }) => {
  return (
    <div
      className="nav-blur"
      onMouseEnter={(e) => {
        const submenuRow = e.target.parentElement.parentElement
        submenuRow.style.opacity = 0
        submenuRow.style.transition = "all 0.3s ease-in-out"
        setTimeout(() => {
          setActiveItem(null)
        }, 300)
      }}
    >
      <div className="blur" />
    </div>
  )
}

export default NavBlur
