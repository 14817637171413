import React from "react"
import CustomImage from "./customImage"
const CustomImageData = ({
  data,
  path,
  type,
  alt,
  imgStyle,
  lazy,
  from = null,
}) =>
  data.map(
    ({ node }, index) =>
      node.relativePath === path && (
        <CustomImage
          key={index}
          image={node}
          alt={alt}
          lazy={lazy}
          type={type}
          imgStyle={imgStyle}
          origin={from}
        />
      )
  )

export default CustomImageData
