import React from "react"
import { Icon } from "semantic-ui-react"

import SmartLink from "@/components/common/utils/SmartLink"

import CustomImageData from "@/utils/CustomImageData"

import { socialMedia } from "@/components/layout/footer/utils/arrays"

const SocialMediaLinks = ({ isHome }) => {
  return socialMedia.map((media, i) => (
    <SmartLink
      key={`${media.name}${i}`}
      link={{
        href: media.link,
        target: "_blank",
        external: true,
        hidden: !isHome,
      }}
      className={isHome ? "footer-icon not-hidden" : "footer-icon hidden"}
      ariaLabel={media.name}
    >
      <Icon name={media.name.toLowerCase()} />
    </SmartLink>
  ))
}

const FooterSocials = ({ isHome, data }) => {
  return (
    <div className="footer__social">
      <div className="footer-icons">
        <SocialMediaLinks isHome={isHome} />
      </div>
      <div className="footer-frenchtech">
        <SmartLink
          link={{
            href: "https://lespepitestech.com/blog/2017/07/12/interview-de-thibaud-fily-fondateur-de-bailfacile",
            external: true,
            hidden: !isHome,
          }}
          className={isHome ? "not-hidden" : "hidden"}
          ariaLabel="Article French Tech"
        >
          <CustomImageData
            data={data}
            path="illustrations/logo-french-tech.svg"
            alt="Logo BailFacile Blanc"
            imgStyle={{
              width: "70",
              height: "135",
            }}
          />
        </SmartLink>
      </div>
    </div>
  )
}

export default FooterSocials
