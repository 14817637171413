import React from "react"

const Tag = ({ content, style }) => {
  return (
    <div className="small-tag" style={style}>
      <span
        style={{
          color: "#FFAD4A",
          fontSize: "10px",
          backgroundColor: "#FFF5EE",
          borderRadius: "2px",
          marginLeft: "5px",
          padding: "2px 10px",
          verticalAlign: "middle",
        }}
      >
        {content}
      </span>
    </div>
  )
}

export default Tag
