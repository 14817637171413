import React from "react"

import CustomImageData from "@/utils/CustomImageData"

const LogoImage = ({ data }) => {
  return (
    <CustomImageData
      data={data}
      path="logo/logo.svg"
      alt="Logo BailFacile"
      lazy={false}
      imgStyle={{ width: "150px", height: "29px" }}
    />
  )
}

export default LogoImage
