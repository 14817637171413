import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Icon } from "semantic-ui-react"

import CustomImageData from "@/utils/CustomImageData"

import "@/assets/styles/main-components.scss"

const Button = ({
  content,
  icon,
  onClick,
  link = null,
  navButton = false,
  navClass = null,
  disabled,
  className = null,
  metadata = null,
  displayIconOnMobile = true,
}) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const buttonClassName = () => {
    let newClassName = `custom-button ${className ? className : ""}`

    if (navButton) {
      newClassName += ` nav-button ${navClass}`
    }

    if (displayIconOnMobile === false) {
      newClassName += " not-displayed"
    }

    return newClassName
  }

  return (
    <a href={link} onClick={onClick}>
      <button
        className={`${buttonClassName()}`}
        disabled={disabled}
        {...metadata}
      >
        {disabled ? "Chargement..." : content}
        {!disabled && !navButton && icon && icon !== "" && (
          <CustomImageData
            data={data}
            path="homepage/cta-arrow.svg"
            alt="BailFacile - Logiciel de gestion locative"
          />
        )}
        {!disabled && navButton && icon && <Icon name={icon} />}
      </button>
    </a>
  )
}

export default Button
