import dateVariableInTitle from "../dateVariableInTitle"
import getPageUrl from "../getPageUrl"

export function extractBreadcrumbs(listItemName, listItemSlug, articleParents) {
  const parents = articleParents.filter((item) => {
    return item !== undefined
  })

  let mainBreadcrumbWording = listItemName
  let mainBreadcrumbSlug = listItemSlug

  if (parents[0]?.type === "landing_page") {
    mainBreadcrumbWording = "Document"
    mainBreadcrumbSlug = "documents"
  }

  let itemListElement = [
    {
      "@type": "ListItem",
      position: 1,
      name: "Gestion locative en ligne",
      item: "https://www.bailfacile.fr",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: mainBreadcrumbWording,
      item: `https://www.bailfacile.fr/${mainBreadcrumbSlug}`,
    },
  ]

  let breadcrumbs = itemListElement && {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: itemListElement,
  }

  return { breadcrumbs, itemListElement }
}

export function extractCategoriesBreadcrumbs(data, articleParents) {
  const list = []

  let breadcrumbCategory = [...articleParents, data]

  let uid = `https://www.bailfacile.fr`
  let bcCount = 2

  for (let i = 0; i < breadcrumbCategory.length; i++) {
    if (breadcrumbCategory[i]?.data) {
      let data = { "@type": "ListItem" }
      data.position = bcCount + 1

      data.name = breadcrumbCategory[i]?.data?.title?.text

      if (breadcrumbCategory[i + 1]?.data !== undefined) {
        if (breadcrumbCategory[i]?.type === "guide_article") {
          data.item = `${uid}/guides/${breadcrumbCategory[i]?.uid}`
        } else if (breadcrumbCategory[i]?.type === "landing_page") {
          let slug = breadcrumbCategory[i]?.url
          if (slug.charAt(slug.length - 1) === "/") {
            slug = slug.slice(0, -1)
          } else {
            slug
          }

          data.item = `${uid}${slug}`
        }
      }

      list.push(data)
      bcCount += 1
    }
  }

  return { articleParents, bcCount, list }
}

export function extractMainData(data, contentType, mainType) {
  const { data: articleData } = data

  let mainData = {
    "@context": "https://schema.org/",
    "@type": mainType,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getPageUrl(data.uid, contentType),
    },
    headline: dateVariableInTitle(
      articleData.meta_title ? articleData.meta_title : articleData.title.text
    ),
    description: articleData.meta_description
      ? articleData.meta_description
      : "",
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "EUR",
      availability: "https://schema.org/InStock",
    },
    author: {
      "@type": "Organization",
      name: "BailFacile",
    },
    publisher: {
      "@type": "Organization",
      name: "BailFacile",
      logo: {
        "@type": "ImageObject",
        url: "https://www.bailfacile.fr/img/logo_small.png",
        width: "150",
        height: "29",
      },
    },
    datePublished: new Date(data?.first_publication_date)
      .toISOString()
      .split("T")[0],
    dateModified: new Date(data?.last_publication_date)
      .toISOString()
      .split("T")[0],
  }

  return { mainData }
}

export function extractVideo(data) {
  const extractVideoFromBody = data?.body?.filter((item) => {
    return item.slice_type === "video"
  })

  let structuredVideo = {}

  if (extractVideoFromBody?.length > 0) {
    structuredVideo = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      name: extractVideoFromBody[0]?.primary?.video_link?.title,
      author: {
        "@type": "Organization",
        name: "BailFacile",
      },
      thumbnailUrl: extractVideoFromBody[0]?.primary?.video_link?.thumbnail_url,
      embedUrl: extractVideoFromBody[0]?.primary?.video_link?.embed_url,
      description: extractVideoFromBody[0]?.primary?.description,
      uploadDate: extractVideoFromBody[0]?.primary?.upload_date,
    }
  }

  return { structuredVideo }
}

export function extractFaq(data) {
  let structuredFaq = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  }

  data?.body1[0]?.items?.map((item) => {
    structuredFaq.mainEntity.push({
      "@type": "Question",
      name: item.question.text,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer.text,
      },
    })
  })

  return { structuredFaq }
}
