import {
  CONTRACTS_HOME,
  GUIDE,
  LANDING,
  LOCAL_GUIDE,
} from "@/components/common/content-components/utils/consts"

export default function linkGenerator(doc, isSibling = false) {
  let article =
    doc && doc.node !== null && doc.node !== undefined && doc.node !== ""
      ? doc.node
      : doc

  if (article) {
    let path = ""
    if (!isSibling && article?.type === GUIDE) {
      path = `/guides/${article.uid}`
    } else if (isSibling && article?.document?.type === GUIDE) {
      path = `/guides/${article.document.uid}`
    } else if (isSibling && article?.document?.type === LANDING) {
      path = article?.document?.url
    } else if (!isSibling && article?.type === LANDING) {
      path =
        article?.data?.type === "document"
          ? `/documents/${article.uid}`
          : `/contrats/${article.uid}`
    } else if (article?.type === "documents") {
      path = `/documents/${article.uid}`
    } else if (article?.type === "contrats") {
      path = `/contrats/${article.uid}`
    } else if (article?.type === "news_articles") {
      path = `/actualites/${article.uid}`
    } else if (
      article?.type === CONTRACTS_HOME ||
      (isSibling && article?.sibling_article?.document?.type === CONTRACTS_HOME)
    ) {
      path = `/contrats`
    } else if (isSibling && article?.type === LOCAL_GUIDE) {
      path = `/gestion-locative/${article?.data?.associated_city}/${article?.uid}`
    }
    return path
  }
  return `/`
}
