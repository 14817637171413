import React from "react"
import { Breadcrumb } from "semantic-ui-react"

import {
  GUIDE,
  LANDING,
} from "@/components/common/content-components/utils/consts"

const InterBreadcrumbSection = ({ content }) => {
  let config
  const title = content?.data?.breadcrumb_title
    ? content?.data?.breadcrumb_title
    : content?.data?.title?.text

  if (content.type === GUIDE) {
    config = {
      href: `/guides/${content.uid}`,
      title,
    }
  } else if (content.type === LANDING) {
    config = {
      href: content.url,
      title,
    }
  }

  return (
    <Breadcrumb.Section>
      <a href={config.href}>{config.title}</a>
    </Breadcrumb.Section>
  )
}

export default InterBreadcrumbSection
