import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import SharingIcons from "@/components/common/content-components/SharingIcons"

import CustomImageData from "@/utils/CustomImageData"

const CompleteSharing = ({ link, emailTitle }) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <div className="article-sharing">
      <div className="article-sharing__header">
        <p className="article-sharing__header--title">Partager</p>
        <CustomImageData
          data={data}
          path="homepage/cta-arrow.svg"
          alt="BailFacile - Logiciel de gestion locative"
        />
      </div>
      <SharingIcons link={link} emailTitle={emailTitle} isComplete />
    </div>
  )
}

export default CompleteSharing
