import React, { useContext, useEffect, useRef, useState } from "react"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Container, Grid, Ref, Sticky, Visibility } from "semantic-ui-react"
import { LiveChatLoaderProvider } from "react-live-chat-loader"
import clsx from "clsx"

import Nav from "./nav/Nav"
import Footer from "./footer/Footer"

import ReadingProgressBar from "@/components/common/ReadingProgressBar"
import SmartLink from "@/components/common/utils/SmartLink"
import LogoImage from "@/components/layout/nav/components/LogoImage"

import { Media } from "@/lib/Media"
import HelpScout from "@/lib/HelpScout"
import { AuthContext } from "@/lib/context/Auth"
import { NewsletterProvider } from "@/lib/context/NewsletterContext"
import useMobileDetect from "@/lib/hooks/useMobileDetect"

import "@/assets/styles/site.scss"
import useAllContentsQuery from "@/utils/functions/allContentsQuery"

const SiteWrapper = ({
  noPadding = false,
  guide = false,
  children,
  cpc = false,
  isHome = false,
  articlePage = false,
  archivePage = false,
  helpscout = true,
  isLocalLP = false,
  isLocalGuide = false,
  cityAssociated = null,
}) => {
  const pageQuery = useAllContentsQuery()

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const { softAuthCheck, isAuthenticated } = useContext(AuthContext)

  const [topPassed, setTopPassed] = useState(false)

  const detectMobile = useMobileDetect()
  const handleViewportUpdate = (e, { calculations }) => {
    setTopPassed(calculations.topPassed)
  }

  const mainRef = useRef()

  useEffect(() => {
    softAuthCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hideMenu = cpc || isLocalLP

  const allArticles = pageQueryData.allPrismicGuideArticle?.edges || []
  const allArticlesLocal =
    pageQueryData.allPrismicGuideArticlesLocal?.edges || []
  const allLandingpages = pageQueryData.allPrismicLandingPage?.edges || []
  const allLandingpagesLocal =
    pageQueryData.allPrismicLandingPageLocal?.edges || []
  const allNewsArticles = pageQueryData.allPrismicNewsArticles?.edges || []
  const contractHomepage = pageQueryData.prismicAccueilContrats || null
  const documentsHomepage = pageQueryData.prismicAccueilDocuments || null
  const guideHomepage = pageQueryData.prismicAccueilGuides || null

  const allContent = [
    ...allArticles,
    ...allArticlesLocal,
    ...allLandingpages,
    ...allLandingpagesLocal,
    ...allNewsArticles,
    contractHomepage,
    documentsHomepage,
    guideHomepage,
  ]

  // Clonage des enfants pour leur passer la prop extraProps
  const clonedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { allContent })
    }
    return child
  })

  return (
    <LiveChatLoaderProvider
      providerKey="1aeefa17-efd7-4581-b315-9f6ecaa04936"
      provider="helpScout"
    >
      <NewsletterProvider>
        <Ref innerRef={mainRef}>
          <Grid className="mainGrid">
            <Grid.Row
              centered
              className={
                articlePage ? "mainWrapperRow articlePage" : "mainWrapperRow"
              }
              stretched
              style={{ paddingBottom: !hideMenu && 0 }}
            >
              {articlePage && <ReadingProgressBar />}

              <Grid.Column
                stretched
                width={16}
                style={{
                  backgroundColor: "white",
                }}
              >
                <div style={{ zIndex: 1100 }}>
                  <Media greaterThanOrEqual="tablet">
                    <Sticky
                      className="sticky-nav-menu"
                      styleElement={{ height: "60px" }}
                      context={mainRef}
                    >
                      <Container
                        fluid
                        className="nav-container"
                        textAlign={"center"}
                      >
                        <Nav
                          topPassed={topPassed}
                          hideMenu={hideMenu}
                          isAuthenticated={isAuthenticated}
                          isHome={isHome}
                          isLocal={isLocalGuide || isLocalLP}
                          cityAssociated={cityAssociated}
                        />
                      </Container>
                    </Sticky>
                  </Media>

                  <Media lessThan="tablet">
                    <div className="logo-print">
                      <SmartLink
                        link={{
                          to: "/",
                          hidden: true,
                          external: false,
                        }}
                      >
                        <LogoImage data={data} />
                      </SmartLink>
                    </div>
                    <Sticky
                      context={mainRef}
                      className="sticky-nav-menu"
                      styleElement={{
                        height: "10px",
                        marginBottom: null,
                      }}
                    >
                      <Nav
                        hideMenu={hideMenu}
                        isAuthenticated={isAuthenticated}
                        isHome={isHome}
                        isLocal={isLocalGuide || isLocalLP}
                        cityAssociated={cityAssociated}
                      />
                    </Sticky>
                  </Media>
                </div>
                <Grid
                  className={clsx({
                    siteMainContainer: !noPadding,
                    siteMainContainerNoPadding: noPadding,
                    siteMainContainerForArticle:
                      (noPadding && articlePage) || (noPadding && archivePage),
                    siteHomeContainer: isHome,
                  })}
                  as="main"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid.Row
                    centered
                    style={{ maxWidth: !articlePage && !isHome && "1440px" }}
                    className="main-grid-row"
                  >
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={16}
                      largeScreen={16}
                      widescreen={16}
                      className={clsx({
                        homepageColumn: isHome,
                      })}
                    >
                      <Visibility onUpdate={handleViewportUpdate} offset={77}>
                        <div
                          className={clsx({
                            siteGuideCoreContainer: guide,
                            siteCoreContainer: !guide,
                          })}
                        >
                          {clonedChildren}
                          {!detectMobile.isMobile() && helpscout && (
                            <HelpScout
                              horizontalPosition="right"
                              zIndex="1150"
                              color="#FFAD4A"
                              icon="message"
                              iconText="Aide"
                            />
                          )}
                        </div>
                      </Visibility>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Footer
              cpc={cpc}
              isHome={isHome}
              isLocalLP={isLocalLP}
              allLocalLandingPages={allLandingpagesLocal}
            />
          </Grid>
        </Ref>
      </NewsletterProvider>
    </LiveChatLoaderProvider>
  )
}

export default SiteWrapper
