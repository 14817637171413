import React, { useContext } from "react"

import ArticleHero from "@/components/common/content-components/blocks/ArticleHero"
import SlicesBlock from "@/components/common/content-components/blocks/SlicesBlock"
import AnnexesBlock from "@/components/common/content-components/blocks/AnnexesBlock"
import {
  GUIDE,
  LOCAL_GUIDE,
} from "@/components/common/content-components/utils/consts"
import useSummaryStates from "@/components/common/utils/useSummaryStates"

import { AuthContext } from "@/lib/context/Auth"

import "@/assets/styles/articlepage.scss"
import "@/assets/styles/guide.scss"
import "@/assets/styles/marketing-components_mobile.scss"
import "@/assets/styles/marketing-components_tablet.scss"
import "@/assets/styles/marketing-components_desktop.scss"

const GuideArticleBody = ({
  super_parent_category,
  parent_category,
  category,
  subcategory,
  article,
  siblingLocalGuideArticles = [],
  allContent,
}) => {
  const { setCurrentSection } = useSummaryStates()

  const { isAuthenticated } = useContext(AuthContext)

  const displayAnnexesBlock =
    (article?.data?.sibling_articles?.length >= 1 &&
      article?.data?.sibling_articles[0]?.sibling_article !== null &&
      article?.data?.sibling_articles[0]?.sibling_article?.document !== null) ||
    (article?.data?.children_articles?.length >= 1 &&
      article?.data?.children_articles[0]?.child_article !== null &&
      article?.data?.children_articles[0]?.child_article?.document !== null) ||
    siblingLocalGuideArticles.length > 0

  const isLocal = article?.type === LOCAL_GUIDE

  return (
    <>
      <ArticleHero
        super_parent_category={super_parent_category}
        parent_category={parent_category}
        category={category}
        subcategory={subcategory}
        article={article}
        setCurrentSection={setCurrentSection}
        type={GUIDE}
        isLocal={isLocal}
      />
      <SlicesBlock
        article={article}
        articles={allContent}
        isAuthenticated={isAuthenticated}
        type={isLocal ? LOCAL_GUIDE : GUIDE}
      />
      {displayAnnexesBlock && (
        <AnnexesBlock
          type={isLocal ? LOCAL_GUIDE : GUIDE}
          customData={article}
          isLocal={isLocal}
          siblingLocalGuideArticles={siblingLocalGuideArticles}
        />
      )}
    </>
  )
}

export default GuideArticleBody
