import React from "react"
import { Icon } from "semantic-ui-react"

import CustomImage from "@/utils/customImage"

// import { advises } from "@/components/layout/nav/utils/advises"
import SmartLink from "@/components/common/utils/SmartLink"
// import TrustpilotInline from "@/components/trustpilot/TrustpilotInline"

import { WEB_APP_URL } from "@/lib/constants"

// randomize advises and return the first result found
// const randomAdvise = () => {
//   const random = Math.floor(Math.random() * advises.length)
//   return advises[random]
// }

// replace + symbol by + in superscript in string using unicode
const replacePlus = (string) => {
  return string.replace(/\+/g, "\u207A")
}

const MainLink = ({ mainLink }) => {
  return (
    <a href={mainLink.link} className="mainlink">
      <div className="mainlink__arrow">
        <Icon name="long arrow alternate right" size="large" />
      </div>
      <div className="mainlink__text">
        <p className="text">{mainLink.text}</p>
        <div className="underline" />
      </div>
    </a>
  )
}

const NavSubMenu = ({ data, isAuthenticated, isHome }) => {
  return (
    <div className="nav-submenu">
      <div className="nav-submenu__title">
        <p className="title">{data.title}</p>
      </div>
      <div className="nav-submenu__sections">
        <div className="nav-submenu__sections--left">
          <div className="section__left--subtext">
            <p className="subtext">{replacePlus(data.subtext)}</p>
          </div>
          <div className="section__left--mainlink">
            {isAuthenticated && data?.id === "features" ? (
              <MainLink
                mainLink={{
                  text: "Aller dans mon espace",
                  link: `${WEB_APP_URL}/gestion`,
                }}
              />
            ) : (
              <MainLink mainLink={data?.mainLink} />
            )}
          </div>
        </div>
        <div className="nav-submenu__sections--right">
          {data?.id === "features" && (
            <>
              {data.columns.map((column, index) => (
                <div className="section__right--column features" key={index}>
                  {column.lines.map((line, index) => (
                    <div
                      className="section__right--column-line features"
                      key={index}
                    >
                      <div className="section__right--column-title features">
                        {line.title !== "" ? (
                          <p className="title">{line.title.toUpperCase()}</p>
                        ) : (
                          <p className="title">&nbsp;</p>
                        )}
                      </div>
                      <div className="section__right--column-items features">
                        {line.items.map((item, index) => (
                          <div className="item" key={index}>
                            <span className="icon-bg">
                              <Icon name={item.icon} size="small" />
                            </span>
                            <a href={item.link}>{item.text}</a>
                            <Icon name="chevron right" size="small" />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
          {/* {data?.id === "for-who" && (
            <>
              {data.columns.map((column, index) => (
                <div className="section__right--column for-who" key={index}>
                  {column.lines.map((line, index) => (
                    <div
                      className="section__right--column-line for-who"
                      key={index}
                    >
                      <div className="section__right--column-title for-who">
                        {line.title !== "" ? (
                          <p className="title">{line.title.toUpperCase()}</p>
                        ) : (
                          <p className="title">&nbsp;</p>
                        )}
                      </div>
                      <div className="section__right--column-items for-who">
                        {line.items.map((item, index) => (
                          <div className="item" key={index}>
                            <span className="icon-bg">
                              <Icon name={item.icon} size="small" />
                            </span>
                            <a href={item.link}>{item.text}</a>
                            <Icon name="chevron right" size="small" />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="section__right--column features">
                <div className="nav-advise">
                  <p className="nav-advise__text">
                    &laquo; {randomAdvise().text} &raquo;
                  </p>
                  <CustomImage
                    image="https://images-static.trustpilot.com/api/stars/5/star.svg"
                    alt="Etoiles Trustpilot"
                    type="trustpilot"
                    imgStyle={{ height: "24px" }}
                  />
                </div>
                <TrustpilotInline navSubmenu />
              </div>
            </>
          )} */}
          {data?.id !== "features" &&
            data.columns.map((column, index) => (
              <div className="section__right--column" key={index}>
                <div className="section__right--column-title">
                  {column.title !== "" ? (
                    <p className="title">{column.title.toUpperCase()}</p>
                  ) : (
                    <p className="title">&nbsp;</p>
                  )}
                </div>
                <div className="section__right--column-items">
                  {column.items.map((item, index) => (
                    <div className="item" key={index}>
                      <span className="icon-bg">
                        <Icon name={item.icon} size="small" />
                      </span>
                      {data.id === "guide" ? (
                        <SmartLink
                          link={{
                            to: item.link,
                            external: false,
                            hidden: !isHome,
                          }}
                        >
                          {item.text}
                        </SmartLink>
                      ) : (
                        <a href={item.link}>{item.text}</a>
                      )}
                      <Icon name="chevron right" size="small" />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      {data?.id === "guide" && (
        <div className="nav-submenu__articles">
          <hr />
          <div className="nav-submenu__articles--title">
            <p className="title">NOS DERNIERS CONTENUS</p>
          </div>
          <div className="nav-submenu__articles--items">
            {data.lastFourArticles.map((article) => {
              return (
                <div key={article?.node?.data?.title?.text} className="item">
                  <a href={`/guides/${article?.node?.uid}`}>
                    <CustomImage
                      image={article?.node?.data?.main_image?.gatsbyImageData}
                      alt={article?.node?.data?.main_image?.alt}
                    />
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default NavSubMenu
