import React from "react"

import Button from "@/components/common/main-components/Button"

const NewsletterButton = ({ loading, captchaLoader }) => {
  return (
    <Button
      content="Je m'abonne"
      icon="arrow right"
      id="newsletter-button"
      disabled={loading || captchaLoader === false}
    />
  )
}

export default NewsletterButton
