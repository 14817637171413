export default function dateVariableInTitle(title) {
  let monthList = [
    "Janv.",
    "Fév.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ]

  let date = new Date()
  let currentMonth = monthList[date.getMonth()]
  let currentYear = date.getFullYear()
  let newTitle = ""

  if (
    !title.includes("$dynamic_date_mm_yyyy") &&
    !title.includes("$dynamic_date_yyyy")
  ) {
    newTitle = title
  } else if (title.includes("$dynamic_date_mm_yyyy")) {
    let metaTitleDate = currentMonth + " " + currentYear
    newTitle = title.replace("$dynamic_date_mm_yyyy", metaTitleDate)
  } else if (title.includes("$dynamic_date_yyyy")) {
    let metaTitleDate = currentYear
    newTitle = title.replace("$dynamic_date_yyyy", metaTitleDate)
  }

  return newTitle
}
