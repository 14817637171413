import React from "react"

import addInsecableSpace from "@/components/common/utils/addInsecableSpace"

const SummaryTitle = ({
  slice,
  article,
  handleOnClick,
  setCurrentSection = null,
  setClicked,
}) => {
  const summaryTitle = slice?.title?.primary?.title_summary
    ? addInsecableSpace(slice.title.primary.title_summary)
    : slice?.title?.primary?.lvl2_header?.text &&
      addInsecableSpace(slice.title.primary.lvl2_header.text)

  return (
    <li
      key={slice?.slug}
      className="article-summary__list--item"
      name={summaryTitle}
      onClick={() => {
        handleOnClick(article, slice, setCurrentSection, false, setClicked)
      }}
    >
      {summaryTitle}
    </li>
  )
}

export default SummaryTitle
