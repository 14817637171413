import React from "react"
import { PrismicRichText } from "@prismicio/react"

import htmlSerializer from "@/utils/htmlSerializer"

const Quote = ({ slice, articles }) => {
  let isQuote = true
  return (
    <>
      <style global="true" jsx="true">{`
        .quote {
          color: #001d51;
          border-left: 3px solid #ffad4a;
          padding: 5px 1.3em 10px 1.3em;
          margin: 2.1em 0;
        }

        .quote__text {
          font-style: italic;
          padding-bottom: 1em;
        }

        .quote__author--name,
        .quote__author--status {
          font-weight: bold;
          font-style: italic;
        }

        .quote__author--dot {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ffad4a;
          margin: 0 10px;
          vertical-align: middle;
        }
      `}</style>
      <div className="quote">
        <div className="quote__text">
          <PrismicRichText
            field={slice.primary.quote.richText}
            components={(type, element, content, children, index) =>
              htmlSerializer(
                type,
                element,
                content,
                children,
                index,
                articles,
                isQuote
              )
            }
          />
        </div>
        {(slice.primary.quote_author.text ||
          slice.primary.quote_author_status.text) && (
          <div className="quote__author">
            {slice.primary.quote_author.text && (
              <span className="quote__author--name">
                {slice.primary.quote_author.text}
              </span>
            )}

            {slice.primary.quote_author.text &&
              slice.primary.quote_author_status.text && (
                <span className="quote__author--dot"></span>
              )}

            {slice.primary.quote_author_status.text && (
              <span className="quote__author--status">
                {slice.primary.quote_author_status.text}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Quote
