import getLinkedArticle from "./getLinkedArticle"
import linkGenerator from "./linkGenerator"

const hiddenLinksManager = (content, element, articles) => {
  if (element && element.data) {
    const { type: elementType } = element
    const {
      type: elementDataType,
      link_type: elementDataLinkType,
      slug: elementDataSlug,
      uid: elementDataUid,
      url: elementDataUrl,
      target: elementDataTarget,
    } = element.data

    const linkedArticle = getLinkedArticle(
      articles,
      elementDataType,
      elementDataUid,
      elementDataSlug
    )

    let linkDestinationTo = null
    let linkDestinationHref = null
    let linkTarget = null
    let linkIsExternal = false

    if (elementType === "hyperlink") {
      if (elementDataLinkType === "Web") {
        linkDestinationHref = elementDataUrl
        linkTarget = elementDataTarget
        linkIsExternal = true
      } else if (elementDataLinkType === "Document") {
        linkDestinationTo = linkGenerator(linkedArticle)
      }
    }

    content = content.replace("**hidden**", "")

    return {
      content,
      uid: elementDataUid,
      to: linkDestinationTo,
      href: linkDestinationHref,
      target: linkTarget,
      external: linkIsExternal,
      hidden: true,
    }
  }
}

export default hiddenLinksManager
