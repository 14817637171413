const contracts = [
  { title: "Bail de location meublée", slug: "/contrats/bail-meuble" },
  { title: "Bail de location vide", slug: "/contrats/bail-vide" },
  { title: "Bail mobilité", slug: "/contrats/bail-mobilite" },
  { title: "Bail étudiant", slug: "/contrats/bail-etudiant" },
  { title: "Bail de colocation", slug: "/contrats/bail-colocation" },
  {
    title: "Bail de stationnement",
    slug: "/contrats/bail-parking-garage-box",
  },
]

const documents = [
  { title: "Quittance de loyer", slug: "/documents/quittance-loyer" },
  { title: "État des lieux d'entrée", slug: "/documents/etat-lieux-entree" },
  { title: "État des lieux de sortie", slug: "/documents/etat-lieux-sortie" },
  { title: "Caution solidaire", slug: "/documents/caution-solidaire" },
  { title: "Avenant au bail", slug: "/documents/avenant" },
  {
    title: "Régularisation des charges",
    slug: "/documents/regularisation-annuelle-charges",
  },
  { title: "Avis d'échéance de loyer", slug: "/documents/avis-echeance-loyer" },
  {
    title: "Augmentation du loyer",
    slug: "/documents/avis-revision-annuelle-loyer",
  },
  { title: "Lettre pour impayés", slug: "/documents/loyer-impaye" },
]

const features = [
  {
    title: "Baux & documents",
    slug: "/fonctionnalites/baux-documents-location",
  },
  { title: "État des lieux", slug: "/fonctionnalites/etats-des-lieux" },
  { title: "Automatisations", slug: "/fonctionnalites/automatisation" },
  {
    title: "Signature électronique",
    slug: "/fonctionnalites/signature-electronique",
  },
  { title: "Espace locataire", slug: "/fonctionnalites/espace-locataire" },
  { title: "Suivi des finances", slug: "/fonctionnalites/finances" },
  { title: "Accompagnement", slug: "/fonctionnalites/accompagnement" },
]

const ressources = [
  { title: "Guide du bailleur", slug: "/guides" },
  { title: "Actualité du locatif", slug: "/actualites" },
  { title: "Gestion locative par ville", slug: "/gestion-locative/france" },
]

const about = [
  { title: "Tarifs", slug: "/tarifs" },
  { title: "Sécurité des données", slug: "/fonctionnalites/securite" },
  { title: "Centre d'aide bailleur", slug: "https://aide.bailfacile.fr" },
  {
    title: "Centre d'aide locataire",
    slug: "https://aide-locataire.bailfacile.fr",
  },
  { title: "Pourquoi BailFacile ?", slug: "/mission" },
  { title: "Espace Presse", slug: "/ils-parlent-de-nous" },
  { title: "Nous contacter", slug: "mailto:contact@bailfacile.fr" },
]

export const legalPages = [
  { title: "Mentions légales", slug: "/mentions-legales" },
  { title: "Conditions générales", slug: "/conditions-generales" },
  {
    title: "Politique de confidentialité",
    slug: "/politique-confidentialite",
  },
]

export const cpcItems = [
  { title: "Contact", slug: "mailto:contact@baifacile.fr" },
  { title: "Sécurité des données", slug: "/fonctionnalites/securite" },
  { title: "Mentions légales", slug: "/mentions-legales" },
  { title: "Conditions générales", slug: "/conditions-generales" },
  {
    title: "Politique de confidentialité",
    slug: "/politique-confidentialite",
  },
]

export const sections = [
  { items: contracts, title: "Contrats de location", className: "contrats" },
  { items: documents, title: "Documents locatifs", className: "documents" },
  {
    "part-one": {
      items: features,
      title: "Fonctionnalités",
      className: "features",
    },
    "part-two": {
      items: ressources,
      title: "Ressources",
      className: "ressources",
    },
  },
  { items: about, title: "À propos", className: "about" },
]

export const socialMedia = [
  { name: "Youtube", link: "https://www.youtube.com/@BailFacile" },
  { name: "Facebook", link: "https://www.facebook.com/BailFacile" },
  { name: "Twitter", link: "https://twitter.com/bailfacile" },
  { name: "Pinterest", link: "https://www.pinterest.fr/BailFacileImmobilier" },
  { name: "LinkedIn", link: "https://www.linkedin.com/company/bailfacile" },
]
