import React from "react"
import { PrismicRichText } from "@prismicio/react"

import htmlSerializer from "@/utils/htmlSerializer"
import DownloadButton from "./download/DownloadButton"

const Abstract = ({ abstract, articles, enableArticleDownload = false }) => {
  return (
    <div className="abstract">
      <h3 className="abstract__title">Ce qu'il faut retenir</h3>
      {abstract.map(({ abstract_item }, i) => (
        <div className="abstract__item" key={i}>
          <span className="abstract__item--bullet">•</span>
          <span className="abstract__item--text">
            <PrismicRichText
              field={abstract_item.richText}
              components={(type, element, content, children, index) =>
                htmlSerializer(
                  type,
                  element,
                  content,
                  children,
                  index,
                  articles
                )
              }
            />
          </span>
        </div>
      ))}
      {enableArticleDownload && <DownloadButton />}
    </div>
  )
}

export default Abstract
