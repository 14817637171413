import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Grid } from "semantic-ui-react"

import FooterSections from "@/components/layout/footer/FooterSections"
import FooterLegals from "@/components/layout/footer/FooterLegals"
import FooterSocials from "@/components/layout/footer/FooterSocials"
import FooterLogo from "@/components/layout/footer/FooterLogo"
import FooterSupport from "@/components/layout/footer/FooterSupport"
import FooterTrustPilot from "@/components/layout/footer/FooterTrustPilot"
import FooterCPCSection from "@/components/layout/footer/FooterCPCSection"
import SmartLink from "@/components/common/utils/SmartLink"

import "@/assets/styles/footer.scss"
import "@/assets/styles/local-footer.scss"

const Footer = ({
  cpc = false,
  isHome = false,
  isLocalLP,
  allLocalLandingPages = null,
}) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
      prismicAccueilDuGuide {
        data {
          body {
            ... on PrismicAccueilDuGuideDataBodyTitreH2 {
              primary {
                block_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicAccueilDuGuideDataBodyEncadre {
              items {
                internal_link {
                  slug
                }
                link_title {
                  text
                }
              }
              primary {
                anchor
                display_in_nav
                section_title {
                  text
                }
                title_in_nav
                owning_section
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const [questionOpened, setQuestionOpened] = useState(false)

  const handleOnClick = () => {
    setQuestionOpened(!questionOpened)
  }

  const guideCategories = pageQuery.prismicAccueilDuGuide.data.body

  // sort allLocalLandingPages by alphabetical order
  allLocalLandingPages?.sort((a, b) => (a.node.uid < b.node.uid ? -1 : 1))

  const capitalizeFirstLetter = (string) => {
    return string
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  return (
    <Grid.Row className="footer-main-row">
      {isLocalLP ? (
        <Grid.Column className="footer-main-column">
          <div className="local-footer">
            <div className="local-footer__left">
              <FooterLogo data={data} isHome={isHome} />
              <FooterSupport />
              <FooterTrustPilot />
            </div>
            <div className="local-footer__right">
              <div className="local-footer__right--list">
                <div className="header" onClick={() => handleOnClick()}>
                  <p className="header__text">
                    Votre gestion locative ville par ville
                  </p>
                  <div className={`round ${questionOpened ? "active" : ""}`}>
                    <span className="bar one" />
                    <span className="bar two" />
                  </div>
                </div>
                <div className="list">
                  {questionOpened &&
                    allLocalLandingPages?.map((page, index) => (
                      <SmartLink
                        key={index}
                        link={{ to: `/gestion-locative/${page.node.uid}` }}
                      >
                        <p className="item">
                          Gestion locative{" "}
                          {capitalizeFirstLetter(page.node.uid)}
                        </p>
                      </SmartLink>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="legals">
            <FooterLegals isHome={isHome} />
          </div>
        </Grid.Column>
      ) : (
        <Grid.Column className="footer-main-column">
          {cpc ? (
            <div className="footer">
              <div className="cpc">
                <FooterLogo data={data} isHome={isHome} />
                <FooterSupport />
                <FooterTrustPilot />
                <FooterCPCSection isHome={isHome} />
              </div>
            </div>
          ) : (
            <div className="footer">
              <div className="footer__columns">
                <div className="footer__top">
                  <FooterLogo data={data} isHome={isHome} />
                  <FooterSupport />
                  <FooterTrustPilot />
                </div>
                <FooterSections
                  guideCategories={guideCategories}
                  isHome={isHome}
                />
              </div>
              <div className="footer__bottom">
                <FooterLegals isHome={isHome} />
                <FooterSocials data={data} isHome={isHome} />
              </div>
            </div>
          )}
        </Grid.Column>
      )}
    </Grid.Row>
  )
}

export default Footer
