import { graphql, useStaticQuery } from "gatsby"

export default function useAllContentsQuery() {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
                webpOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
      allPrismicGuideArticle {
        edges {
          node {
            uid
            type
          }
        }
      }
      allPrismicLandingPage {
        edges {
          node {
            data {
              type
            }
            uid
            type
          }
        }
      }
      allPrismicLandingPageLocal {
        edges {
          node {
            uid
            type
          }
        }
      }
      allPrismicGuideArticlesLocal {
        edges {
          node {
            uid
            type
            data {
              associated_city
            }
          }
        }
      }
      prismicAccueilContrats {
        type
      }
      prismicAccueilDocuments {
        type
      }
      prismicAccueilDuGuide {
        type
      }
      prismicAccueilSeoLocal {
        type
      }
    }
  `)

  return pageQuery
}
