import React, { createContext, useEffect, useState } from "react"
import { API_URL } from "../constants"
import { axios } from "../Http"

export const AuthContext = createContext()

const AuthContextProvider = (props) => {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loggedinUser, setLoggedinUser] = useState(null)

  useEffect(() => {
    if (isAuthenticated) {
      if (window && window.Beacon && typeof window.Beacon === "function") {
        window.Beacon("identify", {
          name:
            loggedinUser.user.firstname || loggedinUser.user.lastname
              ? loggedinUser.user.firstname + " " + loggedinUser.user.lastname
              : null,
          email: loggedinUser.user.email,
          signature: loggedinUser.currentStatus.beaconKey,
        })
      }
    }
  }, [isAuthenticated, loggedinUser])

  const softAuthCheck = () => {
    return new Promise((resolve) => {
      axios
        .get(API_URL + "/users/status?soft=true")
        .then((res) => {
          if (res.data) {
            setLoggedinUser(res.data)
            setIsAuthenticated(res.data.loggedIn)
            setLoading(false)
          }

          return resolve()
        })
        .catch(() => {
          setLoading(false)
          return resolve()
        })
    })
  }

  const defaultContext = {
    isAuthenticated,
    loggedinUser,
    loading,
    softAuthCheck,
  }

  return (
    <AuthContext.Provider value={defaultContext}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
