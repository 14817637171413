import React from "react"

import SmartLink from "@/components/common/utils/SmartLink"

import CustomImageData from "@/utils/CustomImageData"

const FooterLogo = ({ data, isHome }) => {
  return (
    <div className="footer__logo">
      <SmartLink
        link={{
          to: "/",
          external: false,
          hidden: true,
        }}
        className={isHome ? "not-hidden" : "hidden"}
        ariaLabel="Aller à la page d'accueil"
      >
        <CustomImageData
          data={data}
          path="logo/logo.svg"
          alt="Logo BailFacile Blanc"
          imgStyle={{
            width: "250px",
            height: "48px",
          }}
        />
      </SmartLink>
    </div>
  )
}

export default FooterLogo
