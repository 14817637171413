import React from "react"

import SliceDisplay from "./SliceDisplay"

import convertTitleToSlug from "@/utils/convertTitleToSlug"

const FinalSlicesMap = ({ articles, article, finalSlices, handleUpdate }) => {
  return finalSlices.map((slice, index) => {
    let eleID
    if (
      slice.primary &&
      slice.primary.lvl2_header &&
      slice.primary.lvl2_header.text
    ) {
      if (finalSlices[0].slice_type !== "titre") {
        eleID = convertTitleToSlug(slice.primary.lvl2_header.text, index - 1)
      } else {
        eleID = convertTitleToSlug(slice.primary.lvl2_header.text, index)
      }
    } else {
      eleID = undefined
    }

    return (
      <SliceDisplay
        key={index}
        slice={slice}
        index={index}
        eleID={eleID}
        handleUpdate={handleUpdate}
        articles={articles}
        article={article}
      />
    )
  })
}

export default FinalSlicesMap
