import React from "react"

import AnnexesBlock from "@/components/common/content-components/blocks/AnnexesBlock"
import Hero from "@/components/landingpages/hero/Hero"
import TripleSection from "@/components/landingpages/triple_section/TripleSection"
import LandingPageContent from "@/components/landingpages/content/LandingPageContent"

import "@/assets/styles/landingpage.scss"
import "@/assets/styles/guide.scss"

const LandingPageBody = ({
  article,
  allContent,
  isAuthenticated,
  CTAlink,
  type,
}) => {
  const heroData = {
    title: article?.data?.title,
    marketing_promise: article?.data?.marketing_promise?.richText,
    mobile_marketing_promise: article?.data?.mobile_marketing_promise?.richText,
    main_image: article?.data?.main_image,
    type: article?.data?.type,
    hero_cta_content: article?.data?.hero_cta_content,
    sub_products: article?.data?.sub_products,
    CTAlink,
  }

  const tripleSectionData = [
    {
      title: article?.data?.block_1_title?.text,
      text: article?.data?.block_1_text?.richText,
      icon: article?.data?.block_1_icon,
      color: article?.data?.block_1_color,
    },
    {
      title: article?.data?.block_2_title?.text,
      text: article?.data?.block_2_text?.richText,
      icon: article?.data?.block_2_icon,
      color: article?.data?.block_2_color,
    },
    {
      title: article?.data?.block_3_title?.text,
      text: article?.data?.block_3_text?.richText,
      icon: article?.data?.block_3_icon,
      color: article?.data?.block_3_color,
    },
  ]

  const contentData = {
    article,
    allContent,
    isAuthenticated,
    stickyVisual: article?.data?.main_image,
    CTAlink,
    type,
  }

  const displayAnnexesBlock =
    (article?.data?.sibling_articles?.length >= 1 &&
      article?.data?.sibling_articles[0]?.sibling_article !== null &&
      article?.data?.sibling_articles[0]?.sibling_article?.document !== null) ||
    (article?.data?.children_articles?.length >= 1 &&
      article?.data?.children_articles[0]?.child_article !== null &&
      article?.data?.children_articles[0]?.child_article?.document !== null)

  return (
    <div className="landingpage">
      <Hero heroData={heroData} isAuthenticated={isAuthenticated} />
      <TripleSection tripleSectionData={tripleSectionData} />
      <LandingPageContent contentData={contentData} />
      {displayAnnexesBlock && <AnnexesBlock type={type} customData={article} />}
    </div>
  )
}

export default LandingPageBody
