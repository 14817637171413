import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Icon } from "semantic-ui-react"
import ReCAPTCHA from "react-google-recaptcha"

import Button from "@/components/common/main-components/Button"
import Input from "@/components/common/content-components/newsletter/Input"

import { RECAPTCHA_SITE_KEY_V2 } from "@/lib/constants"

import CustomImageData from "@/utils/CustomImageData"

const DownloadModal = ({
  captchaDownloadRef,
  captchaLoader,
  asyncScriptOnLoad,
  displayNewsletter,
  isSubscribed,
  loading,
  isChecked,
  currentUrl,
  validatorError,
  slugNotFound,
  handleModal,
  handleCheck,
  handleChange,
  handleDownloadFile,
}) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
                webpOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  return (
    <div className="download-modal">
      <div className="download-modal__header">
        <Icon name="close" onClick={() => handleModal(false)} />
        <Icon name="check" />
        <p className="download-modal__header--text">
          Téléchargez votre fiche PDF
        </p>
      </div>
      <div className="download-modal__content">
        <div className="download-modal__content--header">
          <p className="download-modal__content--header-text">
            Indiquez l'
            <span>adresse e-mail où vous souhaitez recevoir votre fiche</span>.
          </p>
        </div>
        <div className="download-modal__content--input">
          <Input handleChange={handleChange} captchaRef={captchaDownloadRef} />
          <ReCAPTCHA
            ref={captchaDownloadRef}
            sitekey={RECAPTCHA_SITE_KEY_V2}
            size="invisible"
            asyncScriptOnLoad={() => asyncScriptOnLoad()}
          />
        </div>
        {validatorError !== null && (
          <div className="message">
            {validatorError === true && (
              <p className="message__error">
                <CustomImageData
                  data={data}
                  path={"marketing/red-cross-mark.png"}
                  alt="Croix rouge"
                />
                L’adresse e-mail saisie est invalide, merci de réessayer.
              </p>
            )}
          </div>
        )}
        {slugNotFound === true && (
          <div className="message">
            <p className="message__error">
              <CustomImageData
                data={data}
                path={"marketing/red-cross-mark.png"}
                alt="Croix rouge"
              />
              Votre demande n'a pas pu aboutir, veuillez recharger la page et
              réessayer.
            </p>
          </div>
        )}
        {displayNewsletter && !isSubscribed && (
          <div
            className="download-modal__content--checkbox"
            onClick={() => handleCheck()}
          >
            <span className="checkbox-custom"></span>
            <span
              className={isChecked ? `checkmark` : `checkmark hidden`}
            ></span>
            <p className="newsletter-check">
              Je m'abonne à l'actu du bailleur (1&nbsp;résumé/mois)
            </p>
          </div>
        )}
      </div>
      <div className="download-modal__button">
        <Button
          content="Recevoir ma fiche"
          icon="arrow right"
          id="newsletter-button"
          disabled={loading || captchaLoader === false}
          onClick={(e) =>
            handleDownloadFile(e, isChecked, currentUrl, captchaDownloadRef)
          }
        />
        <div className="google">
          <p className="google-text">
            Ce site est protégé par reCAPTCHA et la{" "}
            <Link to="https://policies.google.com/privacy">
              Politique de confidentialité
            </Link>{" "}
            ainsi que les{" "}
            <Link to="https://policies.google.com/terms">
              Conditions d’utilisation
            </Link>{" "}
            de Google s’appliquent.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DownloadModal
