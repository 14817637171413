import React from "react"
import { Link } from "gatsby"
import { Grid, List } from "semantic-ui-react"

import Tag from "@/components/common/Tag"

const HomeGuideSection = ({ content, currentSlug }) => {
  const isSectionActive = currentSlug === content.primary.anchor

  const handleMouseHover = (e) => {
    e.target.classList.toggle("hovered")
    e.target.parentElement.parentElement.classList.toggle("hovered")
  }

  return (
    <Grid.Row
      className={
        isSectionActive ? "home-guide-section active" : "home-guide-section"
      }
    >
      <h3>{content.primary.section_title.text}</h3>
      {content.items && (
        <List bulleted className="custom-home-list">
          {content.items.map((item, index) => {
            return (
              <List.Item
                key={`item-${index}`}
                className="custom-home-list-item"
              >
                {item?.internal_link?.link_type !== "Any" &&
                item?.internal_link?.document ? (
                  <Link
                    className="list-item-custom"
                    onMouseEnter={(e) => {
                      handleMouseHover(e)
                    }}
                    onMouseLeave={(e) => {
                      handleMouseHover(e)
                    }}
                    to={item.internal_link.document.url}
                  >
                    {item.link_title.text}
                  </Link>
                ) : (
                  <span className="list-item-custom">
                    <span className="item-title">{item.link_title.text}</span>
                    <Tag content="Bientôt" style={{ marginLeft: "5px" }} />
                  </span>
                )}
              </List.Item>
            )
          })}
        </List>
      )}
    </Grid.Row>
  )
}

export default HomeGuideSection
