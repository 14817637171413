import React from "react"

const StyledOrangeDot = () => {
  return (
    <style global="true" jsx="true">
      {`
        .orangeDot {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ffad4a;
          margin: 0 10px;
          vertical-align: middle;
        }
      `}
    </style>
  )
}

const OrangeDot = () => {
  return (
    <>
      <StyledOrangeDot />
      <span className="orangeDot"></span>
    </>
  )
}

export default OrangeDot
