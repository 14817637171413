import React from "react"
import { navigate } from "gatsby"
import { Icon, Menu } from "semantic-ui-react"

import { SearchBarModal } from "@/components/guides"
import {
  featuresData,
  forWhoData,
  contractsData,
  guideData,
} from "@/components/layout/nav/utils/navDatas"
import LogoImage from "@/components/layout/nav/components/LogoImage"
import Button from "@/components/common/main-components/Button"

import { WEB_APP_URL } from "@/lib/constants"
import SmartLink from "@/components/common/utils/SmartLink"

const NavMenu = ({
  data,
  activeItem,
  setActiveItem,
  lastFourArticles,
  isAuthenticated,
  hideMenu,
  isLocal,
  cityAssociated,
}) => {
  const guideDataImproved = { ...guideData, lastFourArticles: lastFourArticles }

  const menuItems = [
    { data: featuresData, id: "features", title: "Fonctionnalités" },
    { data: forWhoData, id: "for-who", title: "Pour qui" },
    { data: contractsData, id: "contracts", title: "Contrats" },
    { data: guideDataImproved, id: "guide", title: "Conseils" },
    { data: null, id: "pricing", title: "Tarifs" },
  ]

  return (
    <Menu
      className={`nav-menu ${hideMenu && "hideMenu"}`}
      size="massive"
      secondary
      borderless
    >
      <span className={`nav-menu__items--left ${hideMenu && "hideMenu"}`}>
        <Menu.Item
          className="nav-menu__item"
          key="bailfacile-logo"
          onMouseEnter={() => setActiveItem(null)}
        >
          <SmartLink
            link={{
              to: "/",
              hidden: true,
              external: false,
            }}
          >
            <span>
              <LogoImage data={data} />
            </span>
          </SmartLink>
        </Menu.Item>
        {!hideMenu &&
          menuItems.map((item) => (
            <React.Fragment key={`fragment-${item.id}`}>
              {item.id === "pricing" ? (
                <Menu.Item
                  key="pricing"
                  className="nav-menu__item"
                  as="div"
                  onClick={() => navigate("/tarifs")}
                  onMouseEnter={() => setActiveItem(null)}
                >
                  <button className="nav-menu__item--title">
                    <span className="text">{item.title}</span>
                  </button>
                </Menu.Item>
              ) : (
                <Menu.Item
                  key={item.id}
                  className="nav-menu__item"
                  onMouseEnter={() => setActiveItem(item.data)}
                >
                  <button
                    className={`nav-menu__item--title ${
                      activeItem?.id === item.id ? "active" : ""
                    }`}
                  >
                    <span className="text">{item.title}</span>{" "}
                    <Icon size="tiny" name="chevron down" />
                  </button>
                </Menu.Item>
              )}
            </React.Fragment>
          ))}
      </span>
      <span className={`nav-menu__items--right ${hideMenu && "hideMenu"}`}>
        {!hideMenu && (
          <Menu.Item
            key="searchbar"
            className="nav-menu__item"
            onMouseEnter={() => setActiveItem(null)}
          >
            <SearchBarModal />
          </Menu.Item>
        )}
        {!isAuthenticated ? (
          <>
            <Menu.Item
              key={`button-login`}
              className="nav-menu__item button"
              onMouseEnter={() => setActiveItem(null)}
            >
              <Button
                content={"Se connecter"}
                link={`${WEB_APP_URL}/connexion`}
                navButton
                navClass={"secondary"}
              />
            </Menu.Item>
            <Menu.Item
              key={`button-try`}
              className="nav-menu__item button"
              onMouseEnter={() => setActiveItem(null)}
            >
              <Button
                content={
                  isLocal ? "Tester 3 mois pour 1€" : "Essayer gratuitement"
                }
                link={
                  isLocal
                    ? `${WEB_APP_URL}/inscription?s=cta-page-local&ville=${cityAssociated}`
                    : `${WEB_APP_URL}/inscription`
                }
                navButton
                navClass={"primary"}
              />
            </Menu.Item>
          </>
        ) : (
          <Menu.Item
            key={"button-gotoapp"}
            className="nav-menu__item button"
            onMouseEnter={() => setActiveItem(null)}
          >
            <Button
              content="Espace de gestion"
              icon={"user"}
              link={`${WEB_APP_URL}/gestion`}
              navButton
            />
          </Menu.Item>
        )}
      </span>
    </Menu>
  )
}

export default NavMenu
