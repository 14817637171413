import React from "react"
import { Grid, Visibility } from "semantic-ui-react"

import Button from "@/components/common/main-components/Button"
import HeadTitle from "@/components/common/main-components/HeadTitle"
import addInsecableSpace from "@/components/common/utils/addInsecableSpace"
import ArticleDates from "@/components/common/content-components/ArticleDates"
import SharingIcons from "@/components/common/content-components/SharingIcons"
import ArticleBreadcrumb from "@/components/common/content-components/ArticleBreadcrumb"
import {
  CONTRACTS_HOME,
  DOCUMENTS_HOME,
  LANDING,
} from "@/components/common/content-components/utils/consts"

import CustomImage from "@/utils/customImage"
import linkGenerator from "@/utils/linkGenerator"

import { WEB_APP_URL } from "@/lib/constants"

import "@/assets/styles/trustpilot.scss"

const ArticleHero = ({
  super_parent_category = null,
  parent_category = null,
  category = null,
  subcategory = null,
  article,
  setCurrentSection,
  type,
  isLocal = false,
}) => {
  let CTAlink = null

  if (article && article.data && article.data.cta_summary) {
    const { link_cta_summary } = article.data
    if (link_cta_summary?.type === LANDING && link_cta_summary.document) {
      CTAlink = {
        href: link_cta_summary.document?.url,
        to: link_cta_summary.document?.url,
        target: "_blank",
      }
    } else if (link_cta_summary?.type === CONTRACTS_HOME) {
      CTAlink = {
        href: "/contrats",
        to: "/contrats",
        target: "_blank",
      }
    } else if (link_cta_summary?.type === DOCUMENTS_HOME) {
      CTAlink = {
        href: "/documents",
        to: "/documents",
        target: "_blank",
      }
    } else if (!link_cta_summary?.type) {
      CTAlink = {
        href: link_cta_summary?.url,
        to: link_cta_summary?.url,
        target: "_blank",
      }
    } else if (link_cta_summary?.type === "native_cta") {
      CTAlink = null
    }
  }

  let imageClassName

  if (
    (article?.data?.main_image?.gatsbyImageData.width === 1060 &&
      article?.data?.main_image?.gatsbyImageData.height === 600) ||
    (article?.data?.main_image?.gatsbyImageData.width === 1280 &&
      article?.data?.main_image?.gatsbyImageData.height === 720)
  ) {
    imageClassName = `hero__image old ${type.toLowerCase()}` // ratio 9:5
  } else if (
    article?.data?.main_image?.gatsbyImageData.width === 650 &&
    article?.data?.main_image?.gatsbyImageData.height === 430
  ) {
    imageClassName = `hero__image new ${type.toLowerCase()}` // ratio 9:6
  }

  let textCTASummary
  let displayIconOnMobile = true

  if (CTAlink && article?.data?.text_cta_summary) {
    textCTASummary = article?.data?.text_cta_summary?.replace("**hidden**", "")

    if (textCTASummary.length > 25) {
      displayIconOnMobile = false
    }
  }

  return (
    <section className={`marketing-section hero ${type}`}>
      <Grid.Row className="row-no-padding">
        <Grid.Column className="hero-main-column">
          <div className={`hero__block marketing-block ${type}`}>
            <div className={`hero__content ${type}`}>
              <div className="hero__content--breadcrumbs">
                <ArticleBreadcrumb
                  super_parent_category={super_parent_category}
                  parent_category={parent_category}
                  category={category}
                  subcategory={subcategory}
                  article={article}
                  isLocal={isLocal}
                />
              </div>
              <div className="hero__content--header marketing-header">
                <Visibility
                  style={{ width: "100%", height: "100%" }}
                  id={`article-header-visibility`}
                  onUpdate={(e, data) => {
                    if (data.calculations.passing) {
                      const url = linkGenerator(article)
                      history.replaceState(
                        null,
                        "",
                        `${url.replace(/\/$/, "")}`
                      )
                      setCurrentSection("")
                    }
                  }}
                  offset={77}
                  once
                >
                  <HeadTitle tag="h1">
                    {addInsecableSpace(article.data.title.text)}
                  </HeadTitle>
                </Visibility>
              </div>
              <div className="hero__content--dates">
                <ArticleDates article={article} />
              </div>
              <div className="hero__content--share">
                <SharingIcons
                  link={linkGenerator(article)}
                  emailTitle={article.data.title.text}
                />
              </div>
              {CTAlink && (
                <div className={`hero__content--footer ${type.toLowerCase()}`}>
                  <Button
                    content={textCTASummary}
                    link={
                      isLocal
                        ? `${WEB_APP_URL}/inscription?s=cta-page-local&ville=${article?.data?.associated_city}`
                        : CTAlink.href
                    }
                    icon="arrow right"
                    displayIconOnMobile={displayIconOnMobile}
                  />
                </div>
              )}
            </div>
            <div className={imageClassName}>
              <CustomImage
                image={article.data.main_image.gatsbyImageData}
                alt={article.data.main_image.alt}
              />
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </section>
  )
}

export default ArticleHero
