import React from "react"

import { HomeGuideSection } from "@/components/guides/home"
import { NativeCTA } from "@/components/slices"
import { Media } from "@/lib/Media"

export default ({ slices, currentSlug }) => {
  return slices.map((slice, index) => {
    const res = (slice) => {
      switch (slice.slice_type) {
        case "titre_h2":
          return (
            <h2 style={{ width: "100%" }}>{slice.primary.block_title.text}</h2>
          )
        case "encadre":
          return <HomeGuideSection content={slice} currentSlug={currentSlug} />
        case "cta_natif":
          return slice?.primary?.native_cta_block?.isBroken === false ? (
            <NativeCTA slice={slice} />
          ) : null // Add a colon after the conditional expression
        default:
          return null
      }
    }

    return (
      <>
        {slice.slice_type === "cta_natif" && (
          <Media lessThan="tablet">
            <div
              key={`slice-${index}`}
              className={`home-slice-block article-slices-block ${slice.slice_type}`}
              id={
                slice.slice_type === "encadre"
                  ? slice.primary.anchor
                  : `slice-${index}`
              }
            >
              {res(slice, index)}
            </div>
          </Media>
        )}

        {slice.slice_type !== "cta_natif" && (
          <div
            key={`slice-${index}`}
            className={`home-slice-block article-slices-block ${slice.slice_type}`}
            id={
              slice.slice_type === "encadre"
                ? slice.primary.anchor
                : `slice-${index}`
            }
          >
            {res(slice, index)}
          </div>
        )}
      </>
    )
  })
}
