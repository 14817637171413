import React, { useEffect, useRef, useState } from "react"

import { useNewsletterContext } from "@/lib/context/NewsletterContext"

import DownloadModal from "./DownloadModal"
import ConfirmationModal from "./ConfirmationModal"

const DownloadFileModal = ({ justSentEmail, handleModal }) => {
  const [isChecked, setIsChecked] = useState(true)
  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    let current = window.location.pathname
    current = current.split("/")
    setCurrentUrl(current[2])

    if (!displayNewsletter) {
      setIsChecked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheck = () => {
    setIsChecked(!isChecked)
  }

  const {
    loading,
    captchaLoader,
    asyncScriptOnLoad,
    displayNewsletter,
    validatorError,
    slugNotFound,
    isSubscribed,
    credentials,
    handleChange,
    handleKeyDown,
    handleDownloadFile,
  } = useNewsletterContext()

  const captchaDownloadRef = useRef(null)

  return (
    <>
      <div
        className="download-modal-dimmer"
        onClick={() => handleModal(false)}
      ></div>
      {justSentEmail ? (
        <ConfirmationModal
          handleModal={handleModal}
          email={credentials.email}
        />
      ) : (
        <DownloadModal
          captchaDownloadRef={captchaDownloadRef}
          captchaLoader={captchaLoader}
          asyncScriptOnLoad={asyncScriptOnLoad}
          displayNewsletter={displayNewsletter}
          isSubscribed={isSubscribed}
          loading={loading}
          isChecked={isChecked}
          currentUrl={currentUrl}
          validatorError={validatorError}
          slugNotFound={slugNotFound}
          handleModal={handleModal}
          handleCheck={handleCheck}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          handleDownloadFile={handleDownloadFile}
        />
      )}
    </>
  )
}

export default DownloadFileModal
