import React, { useContext } from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import SiteWrapper from "@/components/layout/SiteWrapper"
import Meta from "@/components/layout/Meta"
import LandingPageBody from "@/components/landingpages/LandingPageBody"
import getDocumentsCTALink from "@/components/landingpages/utils/getDocumentsCTALink"
import { LANDING } from "@/components/common/content-components/utils/consts"

import useIsCPC from "@/utils/useIsCPC"
import getStructuredData from "@/utils/getStructuredData"

import { AuthContext } from "@/lib/context/Auth"
import usePageViewTracker from "@/lib/hooks/usePageViewTracker"

export const query = graphql`
  query LandingPageQuery($uid: String) {
    prismicLandingPage(uid: { eq: $uid }) {
      _previewable
      uid
      type
      data {
        title {
          text
          richText
        }
        marketing_promise {
          text
          richText
        }
        mobile_marketing_promise {
          text
          richText
        }
        type
        hero_cta_content
        visual_cta_content
        main_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
          alt
        }
        og_image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          og: gatsbyImageData(layout: FIXED, width: 1200)
          alt
        }
        block_1_title {
          richText
          text
        }
        block_1_text {
          richText
          text
        }
        block_1_icon
        block_1_color
        block_2_title {
          richText
          text
        }
        block_2_text {
          richText
          text
        }
        block_2_icon
        block_2_color
        block_3_title {
          richText
          text
        }
        block_3_text {
          richText
          text
        }
        block_3_icon
        block_3_color
        action_category
        body {
          ... on PrismicLandingPageDataBodyTitre {
            slice_type
            primary {
              lvl2_header {
                richText
                text
              }
              display_title
              title_summary
            }
          }
          ... on PrismicLandingPageDataBodyTexteGeneral {
            slice_type
            primary {
              body_text {
                richText
                text
              }
            }
          }
          ... on PrismicLandingPageDataBodyBox {
            slice_type
            primary {
              box_type
              box_title
              box_text {
                richText
                text
              }
            }
          }
          ... on PrismicLandingPageDataBodyCitation {
            slice_type
            primary {
              quote {
                richText
                text
              }
              quote_author {
                richText
                text
              }
              quote_author_status {
                richText
                text
              }
            }
          }
          ... on PrismicLandingPageDataBodyImage {
            slice_type
            primary {
              image {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                og: gatsbyImageData(layout: FIXED, width: 1200)
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBodyCtaNatif {
            slice_type
            primary {
              native_cta_block {
                isBroken
                document {
                  ... on PrismicNativeCta {
                    data {
                      cta_title {
                        richText
                        text
                      }
                      cta_text {
                        richText
                        text
                      }
                      cta_bullet_1 {
                        richText
                        text
                      }
                      cta_bullet_2 {
                        richText
                        text
                      }
                      cta_bullet_3 {
                        richText
                        text
                      }
                      cta_internal_link {
                        url
                        uid
                        type
                        document {
                          ... on PrismicGuideArticle {
                            url
                            uid
                            type
                          }
                          ... on PrismicLandingPage {
                            url
                            uid
                            type
                          }
                        }
                      }
                      has_seo_value
                      cta_visual {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        og: gatsbyImageData(layout: FIXED, width: 1200)
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageDataBodyContenuHtml {
            slice_type
            primary {
              html_content {
                richText
                text
                html
              }
            }
          }
          ... on PrismicLandingPageDataBodyExempleDUnDocument {
            slice_type
            primary {
              document_example {
                richText
                text
                html
              }
            }
          }
          ... on PrismicLandingPageDataBodyVideo {
            slice_type
            primary {
              video_link {
                author_name
                author_url
                html
                thumbnail_url
                thumbnail_width
                title
                embed_url
              }
              description
              upload_date
            }
          }
        }
        sub_products {
          subproduct_slug
          subproduct_title {
            richText
            text
          }
          subproduct_subtitle {
            richText
            text
          }
          subproduct_type
          subproduct_action_category
          subproduct_icon
        }
        body1 {
          ... on PrismicLandingPageDataBody1FaqSection {
            slice_type
            items {
              question {
                text
                richText
              }
              answer {
                richText
                text
              }
            }
          }
        }
        breadcrumb_title
        parent_article {
          uid
          type
          document {
            ... on PrismicGuideArticle {
              uid
              type
              data {
                breadcrumb_title
                parent_article {
                  uid
                  type
                  document {
                    ... on PrismicGuideArticle {
                      uid
                      type
                      data {
                        breadcrumb_title
                        title {
                          text
                          richText
                        }
                      }
                    }
                    ... on PrismicLandingPage {
                      uid
                      url
                      type
                      data {
                        type
                        breadcrumb_title
                        title {
                          text
                          richText
                        }
                      }
                    }
                  }
                }
                title {
                  text
                  richText
                }
              }
            }
            ... on PrismicLandingPage {
              uid
              url
              type
              data {
                type
                breadcrumb_title
                parent_article {
                  uid
                  type
                  document {
                    ... on PrismicGuideArticle {
                      uid
                      type
                      data {
                        breadcrumb_title
                        title {
                          text
                          richText
                        }
                      }
                    }
                    ... on PrismicLandingPage {
                      uid
                      url
                      type
                      data {
                        type
                        breadcrumb_title
                        title {
                          text
                          richText
                        }
                      }
                    }
                  }
                }
                title {
                  text
                  richText
                }
              }
            }
          }
        }
        title_as_sibling_article {
          text
          richText
        }
        sibling_articles {
          sibling_article {
            isBroken
            document {
              ... on PrismicGuideArticle {
                id
                data {
                  title_as_sibling_article {
                    richText
                    text
                    html
                  }
                  title {
                    text
                  }
                  breadcrumb_title
                }
                url
                type
                uid
              }
              ... on PrismicLandingPage {
                id
                url
                type
                uid
                data {
                  title {
                    text
                  }
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
              ... on PrismicAccueilContrats {
                type
                data {
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
            }
          }
        }
        children_articles {
          child_article {
            isBroken
            document {
              ... on PrismicGuideArticle {
                id
                data {
                  title_as_sibling_article {
                    richText
                    text
                    html
                  }
                  title {
                    text
                  }
                  breadcrumb_title
                }
                url
                type
                uid
              }
              ... on PrismicLandingPage {
                id
                url
                type
                uid
                data {
                  title {
                    text
                  }
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
              ... on PrismicAccueilContrats {
                url
                data {
                  title_as_sibling_article {
                    text
                    richText
                  }
                }
              }
            }
          }
        }
        canonical_url {
          url
        }
        meta_description
        meta_title
      }
      first_publication_date(locale: "")
      last_publication_date(locale: "")
    }
    trustpilotGeneral {
      data {
        trustScore
        stars
        string
        numberOfReviews
        url
        image
        logo
      }
    }
  }
`

const LandingPageTemplate = (props) => {
  const landingpage = props.data.prismicLandingPage

  const { isAuthenticated } = useContext(AuthContext)
  const isCPC = useIsCPC()

  const CTAlink = getDocumentsCTALink(landingpage, isAuthenticated)

  usePageViewTracker("landingpage", landingpage.uid)

  return (
    <SiteWrapper cpc={isCPC} noPadding={true} articlePage>
      <LandingPageBody
        article={landingpage}
        isAuthenticated={isAuthenticated}
        CTAlink={CTAlink}
        type={LANDING}
      />
    </SiteWrapper>
  )
}

export default withPrismicPreview(LandingPageTemplate)

export const Head = ({ data }) => {
  const landingpage = data?.prismicLandingPage
  const type = `landingpage-${landingpage.data.type}`
  const { structuredData } = getStructuredData(landingpage, type)

  const canonicalUrl = landingpage.data.canonical_url?.url

  return (
    <Meta
      canonicalUrl={canonicalUrl}
      title={
        landingpage.data.meta_title
          ? landingpage.data.meta_title
          : landingpage.data.title.text
      }
      description={
        landingpage.data.meta_description
          ? landingpage.data.meta_description
          : landingpage.data.meta_title.text
      }
      structuredData={structuredData}
      ogArticle={true}
      ogImage={landingpage?.data?.og_image?.og?.images?.fallback?.src}
      currentLocation={landingpage.uid}
      contentType={type}
    />
  )
}
