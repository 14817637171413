import React from "react"

import { legalPages } from "@/components/layout/footer/utils/arrays"
import { FooterSection } from "@/components/layout/footer/FooterSections"

const FooterLegals = ({ isHome }) => {
  const section = {
    items: legalPages,
    className: "legalPages",
  }

  return (
    <div className="footer__legals">
      <FooterSection
        section={section}
        title={null}
        className={"legalPages"}
        withDot
        isHome={isHome}
      />
    </div>
  )
}

export default FooterLegals
