import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import Button from "@/components/common/main-components/Button"

import { sendDataLayerEvent } from "@/lib/trackers/events"
import { WEB_APP_URL } from "@/lib/constants"

import CustomImageData from "@/utils/CustomImageData"

import "@/assets/styles/articlepage.scss"
import TrustpilotInline from "@/components/trustpilot/TrustpilotInline"
import { LOCAL_GUIDE } from "./utils/consts"

const StaticCTA = ({ type, city = null }) => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                jpgOptions: { quality: 100 }
                pngOptions: { quality: 100 }
                webpOptions: { quality: 100 }
              )
            }
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `)

  const pageQueryData = useMergePrismicPreviewData(pageQuery)

  const data = pageQueryData.allFile.edges.filter(({ node }) => {
    return node.relativePath.includes(".cache") !== true
  })

  const isLocal = type === LOCAL_GUIDE

  return (
    <div className="article-static-cta">
      <div className="article-static-cta__content">
        <p className="article-static-cta__content--title">
          Gérez vos locations en totale autonomie avec{" "}
          <span className="bf-orange">BailFacile</span>
        </p>
        <Button
          content={
            isLocal ? "Tester 3 mois pour 1€" : "Je fais un essai gratuit"
          }
          icon="arrow right"
          onClick={() =>
            sendDataLayerEvent(
              "click",
              "cta-pagesmarketing",
              "essayer-gratuitement"
            )
          }
          link={
            isLocal
              ? `${WEB_APP_URL}/inscription?s=cta-page-local&ville=${city}`
              : `${WEB_APP_URL}/inscription`
          }
        />
        <div className="article-static-cta__image">
          <CustomImageData
            data={data}
            path={"homepage/gestion-locative-en-ligne-bailfacile.webp"}
            alt="BailFacile - Logiciel de gestion locative"
            lazy={false}
          />
        </div>
      </div>
      <TrustpilotInline />
    </div>
  )
}

export default StaticCTA
