import React from "react"

const HeadingThree = ({ content }) => {
  return (
    <h3>
      <span className="horizontalBar"></span>
      {content}
    </h3>
  )
}

export default HeadingThree
