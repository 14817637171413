import React from "react"
import clsx from "clsx"
import { Message } from "semantic-ui-react"
import { PrismicRichText } from "@prismicio/react"

import htmlSerializer from "@/utils/htmlSerializer"
import addInsecableSpace from "../common/utils/addInsecableSpace"

const Box = ({ slice, articles }) => {
  const { box_type, box_title, box_text } = slice?.primary
  return (
    <div className="article-text info-box">
      <Message
        className={clsx("card", "info-box-card", {
          general: box_type === "general yellow" || !box_type,
          juridique: box_type === "juridique blue",
          finances: box_type === "finances cyan",
          warning: box_type === "warning red",
        })}
      >
        <Message.Content>
          <Message.Header className="box-header">
            {addInsecableSpace(box_title)}
          </Message.Header>
          <PrismicRichText
            field={box_text.richText}
            components={(type, element, content, children, index) =>
              htmlSerializer(type, element, content, children, index, articles)
            }
          />
        </Message.Content>
      </Message>
    </div>
  )
}

export default Box
