import React, { useState } from "react"
import { Button, Icon, Modal } from "semantic-ui-react"

import GlobalSearchBar from "../search/GlobalSearchBar"

const SearchBarModal = ({ guideSearch = false, mobile = false }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className="search-bar-container">
      <Modal
        className="my-modal"
        centered={false}
        dimmer="blurring"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={{
          ...(guideSearch ? (
            <div
              className="search-bar"
              style={{
                backgroundColor: "white",
              }}
            >
              <GlobalSearchBar guideSearch={guideSearch} mobile={mobile} />
            </div>
          ) : (
            <div>
              <Button
                className="search-icon-padding"
                style={{
                  backgroundColor: "white",
                }}
                aria-label={`Rechercher dans le site`}
              >
                <Icon
                  size="large"
                  name="search"
                  style={{
                    color: "#001D51",
                  }}
                />
              </Button>
            </div>
          )),
        }}
      >
        <Modal.Content style={{ padding: "0px" }}>
          <GlobalSearchBar mobile={mobile} />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default SearchBarModal
