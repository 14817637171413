import React from "react"
import { Link } from "gatsby"
import { PrismicRichText } from "@prismicio/react"

import addInsecableSpace from "@/components/common/utils/addInsecableSpace"
import {
  CONTRACTS_HOME,
  GUIDE,
  LANDING,
  LOCAL_GUIDE,
  NEWS,
} from "@/components/common/content-components/utils/consts"

import linkGenerator from "@/utils/linkGenerator"

const GuideContent = ({ content }) => {
  return content.map((sib, i) => {
    const hasSiblingTitle =
      !!sib?.document?.data?.title_as_sibling_article &&
      sib?.document?.data?.title_as_sibling_article !== ""

    return (
      sib &&
      sib?.document !== null && (
        <div key={i} className="article-annexes__list--item">
          <Link to={linkGenerator(sib, true)} rel="dofollow">
            {hasSiblingTitle ? (
              <PrismicRichText
                field={sib?.document?.data?.title_as_sibling_article?.richText}
              />
            ) : (
              <p>{addInsecableSpace(sib?.document?.data?.title?.text)}</p>
            )}
          </Link>
        </div>
      )
    )
  })
}

const LocalGuideContent = ({ content }) => {
  return content.map((article) => {
    return (
      <div key={article?.node?.uid} className="article-annexes__list--item">
        <Link to={linkGenerator(article, true)} rel="dofollow">
          {addInsecableSpace(article?.node?.data?.title?.text)}
        </Link>
      </div>
    )
  })
}

const NewsContent = ({ content }) =>
  content.map((article, index) => {
    return (
      index < 3 && (
        <div
          key={`news-sibling-${index}`}
          className="article-annexes__list--item"
        >
          <Link to={linkGenerator(article?.node, false)} rel="dofollow">
            {article?.node?.data?.title_as_sibling_article?.text !== "" ? (
              <PrismicRichText
                field={article?.node?.data?.title_as_sibling_article?.richText}
              />
            ) : (
              addInsecableSpace(article?.node?.data?.title?.text)
            )}
          </Link>
        </div>
      )
    )
  })

const SiblingsCards = ({ content, type }) => {
  let title = "Pour aller plus loin"

  if (type === GUIDE) {
    title = "Sur le même thème"
  } else if (type === NEWS) {
    title = "Vos dernières actualités"
  } else if (
    type === LANDING ||
    type === CONTRACTS_HOME ||
    type === LOCAL_GUIDE
  ) {
    title
  }

  return (
    <>
      <p className="article-annexes__title">{title}</p>
      <div className="article-annexes__list">
        {(type === GUIDE || type === LANDING || type === CONTRACTS_HOME) && (
          <GuideContent content={content} />
        )}
        {type === NEWS && <NewsContent content={content} />}
        {type === LOCAL_GUIDE && <LocalGuideContent content={content} />}
      </div>
    </>
  )
}

export default SiblingsCards
