import React from "react"

const FooterSupport = () => {
  return (
    <div className="footer__support">
      <p className="footer__support--text">
        Logiciel N°1 en France pour gérer votre location simplement et en ligne.{" "}
        <br />
        Service client 7j/7.
      </p>
    </div>
  )
}

export default FooterSupport
