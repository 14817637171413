import React from "react"
import { Breadcrumb } from "semantic-ui-react"

import Divider from "@/components/common/content-components/breadcrumbs/Divider"
import MainBreadcrumb from "@/components/common/content-components/breadcrumbs/MainBreadcrumb"
import HomeBreadcrumbSection from "@/components/common/content-components/breadcrumbs/HomeBreadcrumbSection"
import HigherParentBreadcrumbSection from "@/components/common/content-components/breadcrumbs/HigherParentBreadcrumbSection"
import InterBreadcrumbSection from "@/components/common/content-components/breadcrumbs/InterBreadcrumbSection"
import ActiveBreadcrumbSection from "@/components/common/content-components/breadcrumbs/ActiveBreadcrumbSection"
import { CONTRACTS_HOME, DOCUMENTS_HOME } from "../utils/consts"

function capitalizeFirstLetter(string) {
  return string
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("-")
}

export const NewsBreadcrumbs = ({ article }) => {
  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <Breadcrumb.Section>
        <a href={`/actualites`}>Actualités</a>
      </Breadcrumb.Section>
      <Divider />
      <ActiveBreadcrumbSection article={article} />
    </MainBreadcrumb>
  )
}

export const ContractsBreadcrumbs = () => {
  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <Breadcrumb.Section active className="home-contract-breadcrumb">
        Contrat de location
      </Breadcrumb.Section>
    </MainBreadcrumb>
  )
}

export const LocalLandingPageBreadcrumbs = ({ city }) => {
  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection content={"BailFacile"} />
      <Divider />
      <Breadcrumb.Section>
        <a href={`/gestion-locative/france`}>Gestion locative</a>
      </Breadcrumb.Section>
      <Divider />
      <Breadcrumb.Section active>
        <p>Gestion locative {capitalizeFirstLetter(city)}</p>
      </Breadcrumb.Section>
    </MainBreadcrumb>
  )
}

export const LocalHomepageBreadcrumbs = () => {
  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection content={"BailFacile"} />
      <Divider />
      <Breadcrumb.Section active>
        <p>Gestion locative France</p>
      </Breadcrumb.Section>
    </MainBreadcrumb>
  )
}

export const LocalGuideBreadcrumbs = ({ article }) => {
  const citySlug = article?.data?.associated_city
  const city = capitalizeFirstLetter(article?.data?.associated_city)

  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection content={"BailFacile"} />
      <Divider />
      <Breadcrumb.Section>
        <a href={`/gestion-locative/france`}>Gestion locative</a>
      </Breadcrumb.Section>
      <Divider />
      <Breadcrumb.Section>
        <a href={`/gestion-locative/${citySlug}`}>Gestion locative {city}</a>
      </Breadcrumb.Section>
      <Divider />
      <ActiveBreadcrumbSection article={article} />
    </MainBreadcrumb>
  )
}

export const NoParentBreadcrumbs = ({ article }) => {
  const articleType = article?.type

  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <HigherParentBreadcrumbSection type={articleType} content={article} />
      <Divider />
      <ActiveBreadcrumbSection article={article} />
    </MainBreadcrumb>
  )
}

export const OneParentBreadcrumbs = ({ article, subcategory }) => {
  const articleType = article?.type

  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <HigherParentBreadcrumbSection
        type={articleType}
        content={article}
        firstParent={subcategory}
      />
      <Divider />
      {subcategory === CONTRACTS_HOME || subcategory === DOCUMENTS_HOME ? (
        <ActiveBreadcrumbSection article={article} />
      ) : (
        <>
          <InterBreadcrumbSection content={subcategory} />
          <Divider />
          <ActiveBreadcrumbSection article={article} />
        </>
      )}
    </MainBreadcrumb>
  )
}

export const TwoParentsBreadcrumbs = ({ article, subcategory, category }) => {
  const articleType = article?.type

  const standardSubcategory =
    subcategory !== CONTRACTS_HOME && subcategory !== DOCUMENTS_HOME
      ? subcategory
      : null
  const standardCategory =
    category !== CONTRACTS_HOME && category !== DOCUMENTS_HOME ? category : null

  const parents = [standardCategory, standardSubcategory]

  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <HigherParentBreadcrumbSection
        type={articleType}
        content={article}
        firstParent={category}
      />
      <Divider />
      {parents.map((par, i) => {
        return (
          par !== null && (
            <React.Fragment key={i}>
              <InterBreadcrumbSection content={par} />
              <Divider />
            </React.Fragment>
          )
        )
      })}
      <ActiveBreadcrumbSection article={article} />
    </MainBreadcrumb>
  )
}

export const ThreeParentsBreadcrumbs = ({
  article,
  subcategory,
  category,
  parent_category,
}) => {
  const articleType = article?.type

  const standardSubcategory =
    subcategory !== CONTRACTS_HOME && subcategory !== DOCUMENTS_HOME
      ? subcategory
      : null
  const standardCategory =
    category !== CONTRACTS_HOME && category !== DOCUMENTS_HOME ? category : null
  const standardParentCategory =
    parent_category !== CONTRACTS_HOME && parent_category !== DOCUMENTS_HOME
      ? parent_category
      : null

  const parents = [
    standardParentCategory,
    standardCategory,
    standardSubcategory,
  ]

  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <HigherParentBreadcrumbSection
        type={articleType}
        content={article}
        firstParent={parent_category}
      />
      <Divider />
      {parents.map((par) => {
        return (
          <>
            <InterBreadcrumbSection content={par} />
            <Divider />
          </>
        )
      })}
      <ActiveBreadcrumbSection article={article} />
    </MainBreadcrumb>
  )
}

export const FourParentsBreadcrumbs = ({
  article,
  subcategory,
  category,
  parent_category,
  super_parent_category,
}) => {
  const articleType = article?.type

  const standardSubcategory =
    subcategory !== CONTRACTS_HOME && subcategory !== DOCUMENTS_HOME
      ? subcategory
      : null
  const standardCategory =
    category !== CONTRACTS_HOME && category !== DOCUMENTS_HOME ? category : null
  const standardParentCategory =
    parent_category !== CONTRACTS_HOME && parent_category !== DOCUMENTS_HOME
      ? parent_category
      : null
  const standardSuperParentCategory =
    super_parent_category !== CONTRACTS_HOME &&
    super_parent_category !== DOCUMENTS_HOME
      ? super_parent_category
      : null

  const parents = [
    standardSuperParentCategory,
    standardParentCategory,
    standardCategory,
    standardSubcategory,
  ]

  return (
    <MainBreadcrumb>
      <HomeBreadcrumbSection />
      <Divider />
      <HigherParentBreadcrumbSection
        type={articleType}
        content={article}
        firstParent={super_parent_category}
      />
      <Divider />
      {parents.map((par) => {
        return (
          <>
            <InterBreadcrumbSection content={par} />
            <Divider />
          </>
        )
      })}
      <ActiveBreadcrumbSection article={article} />
    </MainBreadcrumb>
  )
}
