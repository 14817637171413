import { WEB_APP_URL } from "@/lib/constants"

export const featuresData = {
  id: "features",
  title: "Votre location en pilote automatique",
  mobileTitle: "Fonctionnalités",
  subtext:
    "100% digital et pensé pour les bailleurs indépendants. Gagnez un temps phénoménal dans votre gestion.",
  mainLink: {
    text: "Essayer gratuitement",
    link: `${WEB_APP_URL}/inscription`,
  },
  columns: [
    {
      lines: [
        {
          title: "À chaque étape",
          items: [
            {
              icon: "key",
              text: "Début de location",
              link: "/fonctionnalites/debut-location",
            },
            {
              icon: "copy",
              text: "Gestion courante",
              link: "/fonctionnalites/gestion-courante",
            },
            {
              icon: "handshake",
              text: "Fin de location",
              link: "/fonctionnalites/fin-location",
            },
          ],
        },
      ],
    },
    {
      lines: [
        {
          title: "Fonctionnalités",
          items: [
            {
              icon: "copy",
              text: "Baux et documents",
              link: "/fonctionnalites/baux-documents-location",
            },
            {
              icon: "sign in alternate",
              text: "État des lieux",
              link: "/fonctionnalites/etats-des-lieux",
            },
            {
              icon: "lightning",
              text: "Automatisations",
              link: "/fonctionnalites/automatisation",
            },
            {
              icon: "pencil",
              text: "Signature électronique",
              link: "/fonctionnalites/signature-electronique",
            },
          ],
        },
      ],
    },
    {
      lines: [
        {
          title: "",
          items: [
            {
              icon: "user",
              text: "Espace locataire",
              link: "/fonctionnalites/espace-locataire",
            },
            {
              icon: "chart pie",
              text: "Suivi des finances",
              link: "/fonctionnalites/finances",
            },
            {
              icon: "handshake",
              text: "Accompagnement",
              link: "/fonctionnalites/accompagnement",
            },
            {
              icon: "lock",
              text: "Sécurité des données",
              link: "/fonctionnalites/securite",
            },
          ],
        },
      ],
    },
  ],
}

export const featuresDataForMobile = {
  id: "features",
  title: "Fontionnalités",
  items: [
    {
      icon: "key",
      text: "Début de location",
      link: "/fonctionnalites/debut-location",
    },
    {
      icon: "copy",
      text: "Gestion courante",
      link: "/fonctionnalites/gestion-courante",
    },
    {
      icon: "handshake",
      text: "Fin de location",
      link: "/fonctionnalites/fin-location",
    },
    {
      icon: "sign in alternate",
      text: "État des lieux",
      link: "/fonctionnalites/etats-des-lieux",
    },
    {
      icon: "copy",
      text: "Baux et documents",
      link: "/fonctionnalites/baux-documents-location",
    },
    {
      icon: "pencil",
      text: "Signature électronique",
      link: "/fonctionnalites/signature-electronique",
    },
    {
      icon: "lightning",
      text: "Automatisations",
      link: "/fonctionnalites/automatisation",
    },
    {
      icon: "handshake",
      text: "Accompagnement",
      link: "/fonctionnalites/accompagnement",
    },
  ],
}

export const forWhoData = {
  id: "for-who",
  title: "Pour qui ?",
  mobileTitle: "Pour qui",
  subtext: "Pour tous les types de bailleurs et tous les types de locations.",
  mainLink: {
    text: "Essayer gratuitement",
    link: `${WEB_APP_URL}/inscription`,
  },
  columns: [
    {
      title: "Mon profil",
      items: [
        {
          icon: "key",
          text: "Première location",
          link: "/bailleur/premiere-location",
        },
        {
          icon: "block layout",
          text: "Gestion multibiens",
          link: "/bailleur/multibiens",
        },
        {
          icon: "th",
          text: "Rentier",
          link: "/bailleur/rentier-immobilier",
        },
        {
          icon: "handshake",
          text: "Gestionnaires en SCI",
          link: "/bailleur/sci-location",
        },
        {
          icon: "users",
          text: "Colocation à la chambre",
          link: "/bailleur/colocation",
        },
        {
          icon: "briefcase",
          text: "Pro de l'immobilier",
          link: "/bailleur/professionnel-immobilier",
        },
      ],
    },
    {
      title: "Ma location",
      items: [
        {
          icon: "box",
          text: "Meublée",
          link: "/location/location-meublee",
        },
        {
          icon: "key",
          text: "Non-meublée",
          link: "/location/location-non-meublee",
        },
        {
          icon: "users",
          text: "Colocation",
          link: "/location/colocation",
        },
        {
          icon: "calendar alternate",
          text: "Bail mobilité",
          link: "/location/bail-mobilite",
        },
        {
          icon: "student",
          text: "Bail Étudiant",
          link: "/location/location-etudiante",
        },
      ],
    },
    {
      title: "Vous hésitez ?",
      items: [
        {
          icon: "pencil alternate",
          text: "BailFacile ou Gérer seul",
          link: "/bailfacile-vs-gestion-traditionnelle",
        },
        {
          icon: "file alternate",
          text: "BailFacile ou Mandat de gestion",
          link: "/bailfacile-vs-agence-gestion",
        },
      ],
    },
  ],
}

export const forWhoDataForMobile = {
  id: "for-who",
  title: "Pour qui ?",
  items: [
    {
      icon: "key",
      text: "Première location",
      link: "/bailleur/premiere-location",
    },
    {
      icon: "block layout",
      text: "Gestion multibiens",
      link: "/bailleur/multibiens",
    },
    {
      icon: "th",
      text: "Rentier",
      link: "/bailleur/rentier-immobilier",
    },
    {
      icon: "handshake",
      text: "Gestionnaires en SCI",
      link: "/bailleur/sci-location",
    },
    {
      icon: "users",
      text: "Colocation à la chambre",
      link: "/bailleur/colocation",
    },
    {
      icon: "briefcase",
      text: "Pro de l'immobilier",
      link: "/bailleur/professionnel-immobilier",
    },
    {
      icon: "pencil alternate",
      text: "BailFacile ou Gérer seul",
      link: "/bailfacile-vs-gestion-traditionnelle",
    },
    {
      icon: "file alternate",
      text: "BailFacile ou Mandat de gestion",
      link: "/bailfacile-vs-agence-gestion",
    },
  ],
}

export const contractsData = {
  id: "contracts",
  title: "Baux de location",
  mobileTitle: "Contrats",
  subtext:
    "Le contrat de location qu'il vous faut édité en 5 minutes, sans se tromper, facile à signer et partager.",
  mainLink: {
    text: "Quel bail choisir ?",
    link: `/contrats`,
  },
  columns: [
    {
      title: "Baux meublés",
      items: [
        {
          icon: "box",
          text: "Bail meublé",
          link: "/contrats/bail-meuble",
        },
        {
          icon: "calendar alternate",
          text: "Bail mobilité",
          link: "/contrats/bail-mobilite",
        },
        {
          icon: "student",
          text: "Bail étudiant",
          link: "/contrats/bail-etudiant",
        },
        {
          icon: "group",
          text: "Bail de colocation",
          link: "/contrats/bail-colocation",
        },
      ],
    },
    {
      title: "Baux non meublés",
      items: [
        {
          icon: "key",
          text: "Bail non meublé",
          link: "/contrats/bail-vide",
        },
        {
          icon: "group",
          text: "Bail de colocation",
          link: "/contrats/bail-colocation",
        },
      ],
    },
    {
      title: "Stationnement",
      items: [
        {
          icon: "car",
          text: "Bail parking, garage ou box",
          link: "/contrats/bail-parking-garage-box",
        },
      ],
    },
  ],
}

export const contractsDataForMobile = {
  id: "contracts",
  title: "Contrats",
  items: [
    {
      icon: "box",
      text: "Bail meublé",
      link: "/contrats/bail-meuble",
    },
    {
      icon: "calendar alternate",
      text: "Bail mobilité",
      link: "/contrats/bail-mobilite",
    },
    {
      icon: "student",
      text: "Bail étudiant",
      link: "/contrats/bail-etudiant",
    },
    {
      icon: "group",
      text: "Bail de colocation",
      link: "/contrats/bail-colocation",
    },
    {
      icon: "key",
      text: "Bail non meublé",
      link: "/contrats/bail-vide",
    },
    {
      icon: "car",
      text: "Bail de stationnement",
      link: "/contrats/bail-parking-garage-box",
    },
  ],
}

export const guideData = {
  id: "guide",
  title: "Guide du bailleur",
  mobileTitle: "Conseils",
  subtext:
    "Lisez des conseils de pro pour une gestion locative facile et sans erreur.",
  mainLink: {
    text: "Suivre l'actualité locative",
    link: `/actualites`,
  },
  columns: [
    {
      title: "Début de location",
      items: [
        {
          icon: "file alternate",
          text: "Choisir le bon contrat",
          link: "/guides#contrat-location",
        },
        {
          icon: "eye",
          text: "Mettre son bien en location",
          link: "/guides#mise-en-location",
        },
        {
          icon: "key",
          text: "Démarrer une location",
          link: "/guides#debut-location",
        },
      ],
    },
    {
      title: "Gestion courante",
      items: [
        {
          icon: "copy",
          text: "Réussir sa gestion courante",
          link: "/guides#gestion-locative",
        },
        {
          icon: "warning sign",
          text: "Gérer un incident",
          link: "/guides#gestion-conflit-locataire",
        },
      ],
    },
    {
      title: "Fin de location",
      items: [
        {
          icon: "handshake",
          text: "Mettre fin à une location",
          link: "/guides#fin-bail-location",
        },
      ],
    },
  ],
}

export const guideDataForMobile = {
  id: "guide",
  title: "Conseils",
  items: [
    {
      icon: "file alternate",
      text: "Choisir le bon contrat",
      link: "/guides#contrat-location",
    },
    {
      icon: "eye",
      text: "Mettre son bien en location",
      link: "/guides#mise-en-location",
    },
    {
      icon: "key",
      text: "Démarrer une location",
      link: "/guides#debut-location",
    },
    {
      icon: "copy",
      text: "Réussir sa gestion courante",
      link: "/guides#gestion-locative",
    },
    {
      icon: "warning sign",
      text: "Gérer un incident",
      link: "/guides#gestion-conflit-locataire",
    },
    {
      icon: "handshake",
      text: "Mettre fin à une location",
      link: "/guides#fin-bail-location",
    },
  ],
}
