import React from "react"

import frenchiefyArticleDate from "@/components/common/utils/frenchiefyArticleDate"

const ArticleDates = ({ article }) => {
  const { first_publication_date, last_publication_date } = article
  const firstPublication = frenchiefyArticleDate(first_publication_date)
  const lastPublication = frenchiefyArticleDate(last_publication_date)

  return (
    <div className="articleDates">
      {firstPublication === lastPublication ? (
        <p className="firstPublication">Publié le {firstPublication}</p>
      ) : (
        <p className="modifiedPublication">
          Publié le {firstPublication}
          <span className="orangeDot"></span>
          Mis à jour le {lastPublication}
        </p>
      )}
    </div>
  )
}

export default ArticleDates
